/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #869ab8;
  --bs-gray-dark: #384c74;
  --bs-primary: #142840;
  --bs-secondary: #506690;
  --bs-success: #42ba96;
  --bs-info: #0495a9;
  --bs-warning: #fad776;
  --bs-danger: #df4759;
  --bs-light: #f9fbfd;
  --bs-dark: #1b2a4e;
  --bs-primary-desat: #6c8aec;
  --bs-black: #161c2d;
  --bs-white: #fff;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0))
}

*, :after, :before {
  box-sizing: border-box
}

body {
  -webkit-tap-highlight-color: rgba(22, 28, 45, 0);
  background-color: #fff;
  color: #161c2d;
  font-family: HKGroteskPro, serif;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  -webkit-text-size-adjust: 100%
}

hr {
  background-color: currentColor;
  border: 0;
  color: #f1f4f8;
  margin: 1rem 0;
  opacity: 1
}

hr:not([size]) {
  height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1;
  margin-bottom: .5rem;
  margin-top: 0
}

.h1, h1 {
  font-size: 2.3125rem
}

.h2, h2 {
  font-size: 1.9375rem
}

.h3, h3 {
  font-size: 1.3125rem
}

.h4, h4 {
  font-size: 1.1875rem
}

.h5, h5 {
  font-size: 1.0625rem
}

.h6, h6 {
  font-size: .75rem
}

p {
  margin-bottom: 1rem;
  margin-top: 0
}

abbr[data-bs-original-title], abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem
}

ol, ul {
  padding-left: 2rem
}

dl, ol, ul {
  margin-bottom: 1rem;
  margin-top: 0
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 600
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b, strong {
  font-weight: 700
}

.small, small {
  font-size: 88.2%
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em
}

sub, sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #142840;
  text-decoration: none
}

a:hover {
  color: #102033;
  text-decoration: underline
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none
}

code, kbd, pre, samp {
  direction: ltr;
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  unicode-bidi: bidi-override
}

pre {
  display: block;
  font-size: .8125rem;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto
}

pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal
}

code {
  word-wrap: break-word;
  color: #d63384;
  font-size: .8125rem
}

a > code {
  color: inherit
}

kbd {
  background-color: #1b2a4e;
  border-radius: .25rem;
  color: #fff;
  font-size: .8125rem;
  padding: .2rem .4rem
}

kbd kbd {
  font-size: 1em;
  font-weight: 600;
  padding: 0
}

figure {
  margin: 0 0 1rem
}

img, svg {
  vertical-align: middle
}

table {
  border-collapse: collapse;
  caption-side: bottom
}

caption {
  color: #869ab8;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  text-align: left
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0
}

button, select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

select:disabled {
  opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
  display: none
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer
}

::-moz-focus-inner {
  border-style: none;
  padding: 0
}

textarea {
  resize: vertical
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0
}

legend {
  float: left;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
  width: 100%
}

legend + * {
  clear: left
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0
}

::-webkit-inner-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
  padding: 0
}

::file-selector-button {
  font: inherit
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

output {
  display: inline-block
}

iframe {
  border: 0
}

summary {
  cursor: pointer;
  display: list-item
}

progress {
  vertical-align: baseline
}

[hidden] {
  display: none !important
}

.lead {
  font-size: 1.3125rem;
  font-weight: 400
}

.display-1 {
  font-size: 4.375rem;
  font-weight: 400
}

.display-2 {
  font-size: 3.8125rem;
  font-weight: 400
}

.display-3 {
  font-size: 3rem;
  font-weight: 400
}

.display-4 {
  font-size: 2.6875rem;
  font-weight: 400
}

.list-inline, .list-unstyled {
  list-style: none;
  padding-left: 0
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: 88.2%;
  text-transform: uppercase
}

.blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem
}

.blockquote > :last-child {
  margin-bottom: 0
}

.blockquote-footer {
  color: #869ab8;
  font-size: .75rem;
  margin-bottom: 1rem;
  margin-top: -1rem
}

.blockquote-footer:before {
  content: "\2014\00A0"
}

.img-fluid, .img-thumbnail {
  height: auto;
  max-width: 100%
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #d9e2ef;
  border-radius: .375rem;
  box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
  padding: .25rem
}

.figure {
  display: inline-block
}

.figure-img {
  line-height: 1;
  margin-bottom: .5rem
}

.figure-caption {
  color: #869ab8;
  font-size: 88.2%
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--bs-gutter-x, 1.25rem);
  padding-right: var(--bs-gutter-x, 1.25rem);
  width: 100%
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1040px
  }
}

.row {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) / -2);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-top: calc(var(--bs-gutter-y) * -1)
}

.row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) / 2);
  padding-right: calc(var(--bs-gutter-x) / 2);
  width: 100%
}

.col {
  flex: 1 0 0%
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

.offset-1 {
  margin-left: 8.33333%
}

.offset-2 {
  margin-left: 16.66667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.33333%
}

.offset-5 {
  margin-left: 41.66667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.33333%
}

.offset-8 {
  margin-left: 66.66667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.33333%
}

.offset-11 {
  margin-left: 91.66667%
}

.g-0, .gx-0 {
  --bs-gutter-x: 0
}

.g-0, .gy-0 {
  --bs-gutter-y: 0
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem
}

.g-3, .gx-3 {
  --bs-gutter-x: .75rem
}

.g-3, .gy-3 {
  --bs-gutter-y: .75rem
}

.g-4, .gx-4 {
  --bs-gutter-x: 1rem
}

.g-4, .gy-4 {
  --bs-gutter-y: 1rem
}

.g-5, .gx-5 {
  --bs-gutter-x: 1.5rem
}

.g-5, .gy-5 {
  --bs-gutter-y: 1.5rem
}

.g-6, .gx-6 {
  --bs-gutter-x: 2rem
}

.g-6, .gy-6 {
  --bs-gutter-y: 2rem
}

.g-7, .gx-7 {
  --bs-gutter-x: 2.5rem
}

.g-7, .gy-7 {
  --bs-gutter-y: 2.5rem
}

.g-8, .gx-8 {
  --bs-gutter-x: 3rem
}

.g-8, .gy-8 {
  --bs-gutter-y: 3rem
}

.g-9, .gx-9 {
  --bs-gutter-x: 4rem
}

.g-9, .gy-9 {
  --bs-gutter-y: 4rem
}

.g-10, .gx-10 {
  --bs-gutter-x: 5rem
}

.g-10, .gy-10 {
  --bs-gutter-y: 5rem
}

.g-11, .gx-11 {
  --bs-gutter-x: 6rem
}

.g-11, .gy-11 {
  --bs-gutter-y: 6rem
}

.g-12, .gx-12 {
  --bs-gutter-x: 8rem
}

.g-12, .gy-12 {
  --bs-gutter-y: 8rem
}

.g-13, .gx-13 {
  --bs-gutter-x: 10rem
}

.g-13, .gy-13 {
  --bs-gutter-y: 10rem
}

.g-14, .gx-14 {
  --bs-gutter-x: 12rem
}

.g-14, .gy-14 {
  --bs-gutter-y: 12rem
}

.g-15, .gx-15 {
  --bs-gutter-x: 16rem
}

.g-15, .gy-15 {
  --bs-gutter-y: 16rem
}

.g-16, .gx-16 {
  --bs-gutter-x: 25rem
}

.g-16, .gy-16 {
  --bs-gutter-y: 25rem
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-sm-0 {
    margin-left: 0
  }
  .offset-sm-1 {
    margin-left: 8.33333%
  }
  .offset-sm-2 {
    margin-left: 16.66667%
  }
  .offset-sm-3 {
    margin-left: 25%
  }
  .offset-sm-4 {
    margin-left: 33.33333%
  }
  .offset-sm-5 {
    margin-left: 41.66667%
  }
  .offset-sm-6 {
    margin-left: 50%
  }
  .offset-sm-7 {
    margin-left: 58.33333%
  }
  .offset-sm-8 {
    margin-left: 66.66667%
  }
  .offset-sm-9 {
    margin-left: 75%
  }
  .offset-sm-10 {
    margin-left: 83.33333%
  }
  .offset-sm-11 {
    margin-left: 91.66667%
  }
  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0
  }
  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0
  }
  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem
  }
  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem
  }
  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem
  }
  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem
  }
  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: .75rem
  }
  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: .75rem
  }
  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1rem
  }
  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1rem
  }
  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 1.5rem
  }
  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 1.5rem
  }
  .g-sm-6, .gx-sm-6 {
    --bs-gutter-x: 2rem
  }
  .g-sm-6, .gy-sm-6 {
    --bs-gutter-y: 2rem
  }
  .g-sm-7, .gx-sm-7 {
    --bs-gutter-x: 2.5rem
  }
  .g-sm-7, .gy-sm-7 {
    --bs-gutter-y: 2.5rem
  }
  .g-sm-8, .gx-sm-8 {
    --bs-gutter-x: 3rem
  }
  .g-sm-8, .gy-sm-8 {
    --bs-gutter-y: 3rem
  }
  .g-sm-9, .gx-sm-9 {
    --bs-gutter-x: 4rem
  }
  .g-sm-9, .gy-sm-9 {
    --bs-gutter-y: 4rem
  }
  .g-sm-10, .gx-sm-10 {
    --bs-gutter-x: 5rem
  }
  .g-sm-10, .gy-sm-10 {
    --bs-gutter-y: 5rem
  }
  .g-sm-11, .gx-sm-11 {
    --bs-gutter-x: 6rem
  }
  .g-sm-11, .gy-sm-11 {
    --bs-gutter-y: 6rem
  }
  .g-sm-12, .gx-sm-12 {
    --bs-gutter-x: 8rem
  }
  .g-sm-12, .gy-sm-12 {
    --bs-gutter-y: 8rem
  }
  .g-sm-13, .gx-sm-13 {
    --bs-gutter-x: 10rem
  }
  .g-sm-13, .gy-sm-13 {
    --bs-gutter-y: 10rem
  }
  .g-sm-14, .gx-sm-14 {
    --bs-gutter-x: 12rem
  }
  .g-sm-14, .gy-sm-14 {
    --bs-gutter-y: 12rem
  }
  .g-sm-15, .gx-sm-15 {
    --bs-gutter-x: 16rem
  }
  .g-sm-15, .gy-sm-15 {
    --bs-gutter-y: 16rem
  }
  .g-sm-16, .gx-sm-16 {
    --bs-gutter-x: 25rem
  }
  .g-sm-16, .gy-sm-16 {
    --bs-gutter-y: 25rem
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-md-0 {
    margin-left: 0
  }
  .offset-md-1 {
    margin-left: 8.33333%
  }
  .offset-md-2 {
    margin-left: 16.66667%
  }
  .offset-md-3 {
    margin-left: 25%
  }
  .offset-md-4 {
    margin-left: 33.33333%
  }
  .offset-md-5 {
    margin-left: 41.66667%
  }
  .offset-md-6 {
    margin-left: 50%
  }
  .offset-md-7 {
    margin-left: 58.33333%
  }
  .offset-md-8 {
    margin-left: 66.66667%
  }
  .offset-md-9 {
    margin-left: 75%
  }
  .offset-md-10 {
    margin-left: 83.33333%
  }
  .offset-md-11 {
    margin-left: 91.66667%
  }
  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0
  }
  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0
  }
  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem
  }
  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem
  }
  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem
  }
  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem
  }
  .g-md-3, .gx-md-3 {
    --bs-gutter-x: .75rem
  }
  .g-md-3, .gy-md-3 {
    --bs-gutter-y: .75rem
  }
  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1rem
  }
  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1rem
  }
  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 1.5rem
  }
  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 1.5rem
  }
  .g-md-6, .gx-md-6 {
    --bs-gutter-x: 2rem
  }
  .g-md-6, .gy-md-6 {
    --bs-gutter-y: 2rem
  }
  .g-md-7, .gx-md-7 {
    --bs-gutter-x: 2.5rem
  }
  .g-md-7, .gy-md-7 {
    --bs-gutter-y: 2.5rem
  }
  .g-md-8, .gx-md-8 {
    --bs-gutter-x: 3rem
  }
  .g-md-8, .gy-md-8 {
    --bs-gutter-y: 3rem
  }
  .g-md-9, .gx-md-9 {
    --bs-gutter-x: 4rem
  }
  .g-md-9, .gy-md-9 {
    --bs-gutter-y: 4rem
  }
  .g-md-10, .gx-md-10 {
    --bs-gutter-x: 5rem
  }
  .g-md-10, .gy-md-10 {
    --bs-gutter-y: 5rem
  }
  .g-md-11, .gx-md-11 {
    --bs-gutter-x: 6rem
  }
  .g-md-11, .gy-md-11 {
    --bs-gutter-y: 6rem
  }
  .g-md-12, .gx-md-12 {
    --bs-gutter-x: 8rem
  }
  .g-md-12, .gy-md-12 {
    --bs-gutter-y: 8rem
  }
  .g-md-13, .gx-md-13 {
    --bs-gutter-x: 10rem
  }
  .g-md-13, .gy-md-13 {
    --bs-gutter-y: 10rem
  }
  .g-md-14, .gx-md-14 {
    --bs-gutter-x: 12rem
  }
  .g-md-14, .gy-md-14 {
    --bs-gutter-y: 12rem
  }
  .g-md-15, .gx-md-15 {
    --bs-gutter-x: 16rem
  }
  .g-md-15, .gy-md-15 {
    --bs-gutter-y: 16rem
  }
  .g-md-16, .gx-md-16 {
    --bs-gutter-x: 25rem
  }
  .g-md-16, .gy-md-16 {
    --bs-gutter-y: 25rem
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-lg-0 {
    margin-left: 0
  }
  .offset-lg-1 {
    margin-left: 8.33333%
  }
  .offset-lg-2 {
    margin-left: 16.66667%
  }
  .offset-lg-3 {
    margin-left: 25%
  }
  .offset-lg-4 {
    margin-left: 33.33333%
  }
  .offset-lg-5 {
    margin-left: 41.66667%
  }
  .offset-lg-6 {
    margin-left: 50%
  }
  .offset-lg-7 {
    margin-left: 58.33333%
  }
  .offset-lg-8 {
    margin-left: 66.66667%
  }
  .offset-lg-9 {
    margin-left: 75%
  }
  .offset-lg-10 {
    margin-left: 83.33333%
  }
  .offset-lg-11 {
    margin-left: 91.66667%
  }
  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0
  }
  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0
  }
  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem
  }
  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem
  }
  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem
  }
  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem
  }
  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: .75rem
  }
  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: .75rem
  }
  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1rem
  }
  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1rem
  }
  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 1.5rem
  }
  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 1.5rem
  }
  .g-lg-6, .gx-lg-6 {
    --bs-gutter-x: 2rem
  }
  .g-lg-6, .gy-lg-6 {
    --bs-gutter-y: 2rem
  }
  .g-lg-7, .gx-lg-7 {
    --bs-gutter-x: 2.5rem
  }
  .g-lg-7, .gy-lg-7 {
    --bs-gutter-y: 2.5rem
  }
  .g-lg-8, .gx-lg-8 {
    --bs-gutter-x: 3rem
  }
  .g-lg-8, .gy-lg-8 {
    --bs-gutter-y: 3rem
  }
  .g-lg-9, .gx-lg-9 {
    --bs-gutter-x: 4rem
  }
  .g-lg-9, .gy-lg-9 {
    --bs-gutter-y: 4rem
  }
  .g-lg-10, .gx-lg-10 {
    --bs-gutter-x: 5rem
  }
  .g-lg-10, .gy-lg-10 {
    --bs-gutter-y: 5rem
  }
  .g-lg-11, .gx-lg-11 {
    --bs-gutter-x: 6rem
  }
  .g-lg-11, .gy-lg-11 {
    --bs-gutter-y: 6rem
  }
  .g-lg-12, .gx-lg-12 {
    --bs-gutter-x: 8rem
  }
  .g-lg-12, .gy-lg-12 {
    --bs-gutter-y: 8rem
  }
  .g-lg-13, .gx-lg-13 {
    --bs-gutter-x: 10rem
  }
  .g-lg-13, .gy-lg-13 {
    --bs-gutter-y: 10rem
  }
  .g-lg-14, .gx-lg-14 {
    --bs-gutter-x: 12rem
  }
  .g-lg-14, .gy-lg-14 {
    --bs-gutter-y: 12rem
  }
  .g-lg-15, .gx-lg-15 {
    --bs-gutter-x: 16rem
  }
  .g-lg-15, .gy-lg-15 {
    --bs-gutter-y: 16rem
  }
  .g-lg-16, .gx-lg-16 {
    --bs-gutter-x: 25rem
  }
  .g-lg-16, .gy-lg-16 {
    --bs-gutter-y: 25rem
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-xl-0 {
    margin-left: 0
  }
  .offset-xl-1 {
    margin-left: 8.33333%
  }
  .offset-xl-2 {
    margin-left: 16.66667%
  }
  .offset-xl-3 {
    margin-left: 25%
  }
  .offset-xl-4 {
    margin-left: 33.33333%
  }
  .offset-xl-5 {
    margin-left: 41.66667%
  }
  .offset-xl-6 {
    margin-left: 50%
  }
  .offset-xl-7 {
    margin-left: 58.33333%
  }
  .offset-xl-8 {
    margin-left: 66.66667%
  }
  .offset-xl-9 {
    margin-left: 75%
  }
  .offset-xl-10 {
    margin-left: 83.33333%
  }
  .offset-xl-11 {
    margin-left: 91.66667%
  }
  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0
  }
  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0
  }
  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem
  }
  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem
  }
  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem
  }
  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem
  }
  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: .75rem
  }
  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: .75rem
  }
  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1rem
  }
  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1rem
  }
  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 1.5rem
  }
  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 1.5rem
  }
  .g-xl-6, .gx-xl-6 {
    --bs-gutter-x: 2rem
  }
  .g-xl-6, .gy-xl-6 {
    --bs-gutter-y: 2rem
  }
  .g-xl-7, .gx-xl-7 {
    --bs-gutter-x: 2.5rem
  }
  .g-xl-7, .gy-xl-7 {
    --bs-gutter-y: 2.5rem
  }
  .g-xl-8, .gx-xl-8 {
    --bs-gutter-x: 3rem
  }
  .g-xl-8, .gy-xl-8 {
    --bs-gutter-y: 3rem
  }
  .g-xl-9, .gx-xl-9 {
    --bs-gutter-x: 4rem
  }
  .g-xl-9, .gy-xl-9 {
    --bs-gutter-y: 4rem
  }
  .g-xl-10, .gx-xl-10 {
    --bs-gutter-x: 5rem
  }
  .g-xl-10, .gy-xl-10 {
    --bs-gutter-y: 5rem
  }
  .g-xl-11, .gx-xl-11 {
    --bs-gutter-x: 6rem
  }
  .g-xl-11, .gy-xl-11 {
    --bs-gutter-y: 6rem
  }
  .g-xl-12, .gx-xl-12 {
    --bs-gutter-x: 8rem
  }
  .g-xl-12, .gy-xl-12 {
    --bs-gutter-y: 8rem
  }
  .g-xl-13, .gx-xl-13 {
    --bs-gutter-x: 10rem
  }
  .g-xl-13, .gy-xl-13 {
    --bs-gutter-y: 10rem
  }
  .g-xl-14, .gx-xl-14 {
    --bs-gutter-x: 12rem
  }
  .g-xl-14, .gy-xl-14 {
    --bs-gutter-y: 12rem
  }
  .g-xl-15, .gx-xl-15 {
    --bs-gutter-x: 16rem
  }
  .g-xl-15, .gy-xl-15 {
    --bs-gutter-y: 16rem
  }
  .g-xl-16, .gx-xl-16 {
    --bs-gutter-x: 25rem
  }
  .g-xl-16, .gy-xl-16 {
    --bs-gutter-y: 25rem
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-xxl-0 {
    margin-left: 0
  }
  .offset-xxl-1 {
    margin-left: 8.33333%
  }
  .offset-xxl-2 {
    margin-left: 16.66667%
  }
  .offset-xxl-3 {
    margin-left: 25%
  }
  .offset-xxl-4 {
    margin-left: 33.33333%
  }
  .offset-xxl-5 {
    margin-left: 41.66667%
  }
  .offset-xxl-6 {
    margin-left: 50%
  }
  .offset-xxl-7 {
    margin-left: 58.33333%
  }
  .offset-xxl-8 {
    margin-left: 66.66667%
  }
  .offset-xxl-9 {
    margin-left: 75%
  }
  .offset-xxl-10 {
    margin-left: 83.33333%
  }
  .offset-xxl-11 {
    margin-left: 91.66667%
  }
  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0
  }
  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0
  }
  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem
  }
  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem
  }
  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem
  }
  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem
  }
  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: .75rem
  }
  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: .75rem
  }
  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1rem
  }
  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1rem
  }
  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 1.5rem
  }
  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 1.5rem
  }
  .g-xxl-6, .gx-xxl-6 {
    --bs-gutter-x: 2rem
  }
  .g-xxl-6, .gy-xxl-6 {
    --bs-gutter-y: 2rem
  }
  .g-xxl-7, .gx-xxl-7 {
    --bs-gutter-x: 2.5rem
  }
  .g-xxl-7, .gy-xxl-7 {
    --bs-gutter-y: 2.5rem
  }
  .g-xxl-8, .gx-xxl-8 {
    --bs-gutter-x: 3rem
  }
  .g-xxl-8, .gy-xxl-8 {
    --bs-gutter-y: 3rem
  }
  .g-xxl-9, .gx-xxl-9 {
    --bs-gutter-x: 4rem
  }
  .g-xxl-9, .gy-xxl-9 {
    --bs-gutter-y: 4rem
  }
  .g-xxl-10, .gx-xxl-10 {
    --bs-gutter-x: 5rem
  }
  .g-xxl-10, .gy-xxl-10 {
    --bs-gutter-y: 5rem
  }
  .g-xxl-11, .gx-xxl-11 {
    --bs-gutter-x: 6rem
  }
  .g-xxl-11, .gy-xxl-11 {
    --bs-gutter-y: 6rem
  }
  .g-xxl-12, .gx-xxl-12 {
    --bs-gutter-x: 8rem
  }
  .g-xxl-12, .gy-xxl-12 {
    --bs-gutter-y: 8rem
  }
  .g-xxl-13, .gx-xxl-13 {
    --bs-gutter-x: 10rem
  }
  .g-xxl-13, .gy-xxl-13 {
    --bs-gutter-y: 10rem
  }
  .g-xxl-14, .gx-xxl-14 {
    --bs-gutter-x: 12rem
  }
  .g-xxl-14, .gy-xxl-14 {
    --bs-gutter-y: 12rem
  }
  .g-xxl-15, .gx-xxl-15 {
    --bs-gutter-x: 16rem
  }
  .g-xxl-15, .gy-xxl-15 {
    --bs-gutter-y: 16rem
  }
  .g-xxl-16, .gx-xxl-16 {
    --bs-gutter-x: 25rem
  }
  .g-xxl-16, .gy-xxl-16 {
    --bs-gutter-y: 25rem
  }
}

.table {
  --bs-table-bg: #fff;
  --bs-table-accent-bg: #fff;
  --bs-table-striped-color: #161c2d;
  --bs-table-striped-bg: #f9fbfd;
  --bs-table-active-color: #161c2d;
  --bs-table-active-bg: #f9fbfd;
  --bs-table-hover-color: #161c2d;
  --bs-table-hover-bg: #f9fbfd;
  border-color: #f1f4f8;
  color: #161c2d;
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: 1.5rem 2rem
}

.table > tbody {
  vertical-align: inherit
}

.table > thead {
  vertical-align: bottom
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor
}

.caption-top {
  caption-side: top
}

.table-sm > :not(caption) > * > * {
  padding: .25rem
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color)
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color)
}

.table-primary {
  --bs-table-bg: #e8eaec;
  --bs-table-striped-bg: #dee0e4;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #d4d7dc;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #d9dce0;
  --bs-table-hover-color: #1b2a4e;
  border-color: #d4d7dc;
  color: #1b2a4e
}

.table-secondary {
  --bs-table-bg: #eef0f4;
  --bs-table-striped-bg: #e3e6ec;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #d9dce3;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #dee1e8;
  --bs-table-hover-color: #1b2a4e;
  border-color: #d9dce3;
  color: #1b2a4e
}

.table-success {
  --bs-table-bg: #ecf8f5;
  --bs-table-striped-bg: #e2eeed;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #d7e3e4;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #dce9e8;
  --bs-table-hover-color: #1b2a4e;
  border-color: #d7e3e4;
  color: #1b2a4e
}

.table-info {
  --bs-table-bg: #e6f4f6;
  --bs-table-striped-bg: #dceaee;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #d2e0e5;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #d7e5e9;
  --bs-table-hover-color: #1b2a4e;
  border-color: #d2e0e5;
  color: #1b2a4e
}

.table-warning {
  --bs-table-bg: #fffbf1;
  --bs-table-striped-bg: #f4f1e9;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #e8e6e1;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #eeebe5;
  --bs-table-hover-color: #1b2a4e;
  border-color: #e8e6e1;
  color: #1b2a4e
}

.table-danger {
  --bs-table-bg: #fcedee;
  --bs-table-striped-bg: #f1e3e6;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #e6dade;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #ebdee2;
  --bs-table-hover-color: #1b2a4e;
  border-color: #e6dade;
  color: #1b2a4e
}

.table-light {
  --bs-table-bg: #f9fbfd;
  --bs-table-striped-bg: #eef1f4;
  --bs-table-striped-color: #1b2a4e;
  --bs-table-active-bg: #e3e6ec;
  --bs-table-active-color: #1b2a4e;
  --bs-table-hover-bg: #e8ebf0;
  --bs-table-hover-color: #1b2a4e;
  border-color: #e3e6ec;
  color: #1b2a4e
}

.table-dark {
  --bs-table-bg: #384c74;
  --bs-table-striped-bg: #42557b;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #4c5e82;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #47597e;
  --bs-table-hover-color: #fff;
  border-color: #4c5e82;
  color: #fff
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto
  }
}

.form-label {
  margin-bottom: .5rem
}

.col-form-label {
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 0;
  padding-bottom: calc(.8125rem + 1px);
  padding-top: calc(.8125rem + 1px)
}

.col-form-label-lg {
  font-size: 1.0625rem;
  padding-bottom: calc(1.122rem + 1px);
  padding-top: calc(1.122rem + 1px)
}

.col-form-label-sm {
  font-size: 1.0625rem;
  padding-bottom: calc(.5625rem + 1px);
  padding-top: calc(.5625rem + 1px)
}

.form-text {
  color: #869ab8;
  font-size: 88.2%;
  margin-top: .25rem
}

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #f1f4f8;
  border-radius: .375rem;
  box-shadow: none;
  color: #161c2d;
  display: block;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  padding: .8125rem 1.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none
  }
}

.form-control[type=file] {
  overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control:focus {
  background-color: #fff;
  border-color: #142840;
  box-shadow: none, none;
  color: #161c2d;
  outline: 0
}

.form-control::-webkit-date-and-time-value {
  height: 1.6em
}

.form-control::-moz-placeholder {
  color: #869ab8;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #869ab8;
  opacity: 1
}

.form-control::placeholder {
  color: #869ab8;
  opacity: 1
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f1f4f8;
  opacity: 1
}

.form-control::file-selector-button {
  -webkit-margin-end: 1.25rem;
  background-color: #f9fbfd;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #161c2d;
  margin: -.8125rem -1.25rem;
  margin-inline-end: 1.25rem;
  padding: .8125rem 1.25rem;
  pointer-events: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #edeef0
}

.form-control::-webkit-file-upload-button {
  -webkit-margin-end: 1.25rem;
  background-color: #f9fbfd;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #161c2d;
  margin: -.8125rem -1.25rem;
  margin-inline-end: 1.25rem;
  padding: .8125rem 1.25rem;
  pointer-events: none;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #edeef0
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #161c2d;
  display: block;
  line-height: 1.6;
  margin-bottom: 0;
  padding: .8125rem 0;
  width: 100%
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0
}

.form-control-sm {
  border-radius: .375rem;
  font-size: 1.0625rem;
  min-height: calc(1.6em + 1.125rem + 2px);
  padding: .5625rem 1rem
}

.form-control-sm::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -.5625rem -1rem;
  margin-inline-end: 1rem;
  padding: .5625rem 1rem
}

.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -.5625rem -1rem;
  margin-inline-end: 1rem;
  padding: .5625rem 1rem
}

.form-control-lg {
  border-radius: .375rem;
  font-size: 1.0625rem;
  min-height: calc(1.6em + 2.244rem + 2px);
  padding: 1.122rem 1.5rem
}

.form-control-lg::file-selector-button {
  -webkit-margin-end: 1.5rem;
  margin: -1.122rem -1.5rem;
  margin-inline-end: 1.5rem;
  padding: 1.122rem 1.5rem
}

.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1.5rem;
  margin: -1.122rem -1.5rem;
  margin-inline-end: 1.5rem;
  padding: 1.122rem 1.5rem
}

textarea.form-control {
  min-height: calc(1.6em + 1.625rem + 2px)
}

textarea.form-control-sm {
  min-height: calc(1.6em + 1.125rem + 2px)
}

textarea.form-control-lg {
  min-height: calc(1.6em + 2.244rem + 2px)
}

.form-control-color {
  height: auto;
  max-width: 3rem;
  padding: .8125rem
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  height: 1.6em
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
  height: 1.6em
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 3.25 3.25L7.5 1' stroke='%23C6D3E6' stroke-width='1.083' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: right 1.25rem center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  border: 1px solid #f1f4f8;
  border-radius: .375rem;
  box-shadow: none;
  color: #161c2d;
  display: block;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  padding: .8125rem 1.25rem;
  width: 100%
}

.form-select:focus {
  border-color: #142840;
  box-shadow: none, none;
  outline: 0
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1.25rem
}

.form-select:disabled {
  background-color: #f1f4f8
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #161c2d
}

.form-select-sm {
  font-size: 1.0625rem;
  padding-bottom: .5625rem;
  padding-left: 1rem;
  padding-top: .5625rem
}

.form-select-lg {
  font-size: 1.0625rem;
  padding-bottom: 1.122rem;
  padding-left: 1.5rem;
  padding-top: 1.122rem
}

.form-check {
  display: block;
  margin-bottom: .125rem;
  min-height: 1.7rem;
  padding-left: 1.5rem
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem
}

.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-color: #d9e2ef;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: transparent;
  height: 1em;
  margin-top: .3em;
  vertical-align: top;
  width: 1em
}

.form-check-input[type=checkbox] {
  border-radius: .375rem
}

.form-check-input[type=radio] {
  border-radius: 50%
}

.form-check-input:active {
  filter: none
}

.form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
  outline: 0
}

.form-check-input:checked {
  background-color: #142840;
  border-color: #142840
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E")
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #142840;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #142840
}

.form-check-input:disabled {
  filter: none;
  opacity: .5;
  pointer-events: none
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: .5
}

.form-check-label {
  cursor: pointer
}

.form-switch {
  padding-left: 3rem
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2.5rem;
  margin-left: -3rem;
  transition: background-position .15s ease-in-out;
  width: 2.5rem
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none
  }
}

.form-switch .form-check-input:checked, .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.form-switch .form-check-input:checked {
  background-position: 100%
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  filter: none;
  opacity: .65;
  pointer-events: none
}

.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1rem;
  padding: 0;
  width: 100%
}

.form-range:focus {
  outline: 0
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none
}

.form-range::-moz-focus-outer {
  border: 0
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #142840;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 .1rem .25rem rgba(22, 28, 45, .1);
  height: 1rem;
  margin-top: -.25rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 1rem
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b9bfc6
}

.form-range::-webkit-slider-runnable-track {
  background-color: #d9e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
  color: transparent;
  cursor: pointer;
  height: .5rem;
  width: 100%
}

.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #142840;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 .1rem .25rem rgba(22, 28, 45, .1);
  height: 1rem;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 1rem
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b9bfc6
}

.form-range::-moz-range-track {
  background-color: #d9e2ef;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
  color: transparent;
  cursor: pointer;
  height: .5rem;
  width: 100%
}

.form-range:disabled {
  pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #abbcd5
}

.form-range:disabled::-moz-range-thumb {
  background-color: #abbcd5
}

.form-floating {
  position: relative
}

.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 1.25rem
}

.form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  padding: 1rem 1.25rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none
  }
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent
}

.form-floating > .form-control::placeholder {
  color: transparent
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-bottom: .625rem;
  padding-top: 1.625rem
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-bottom: .625rem;
  padding-top: 1.625rem
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: .625rem;
  padding-top: 1.625rem
}

.form-floating > .form-control:-webkit-autofill {
  padding-bottom: .625rem;
  padding-top: 1.625rem
}

.form-floating > .form-select {
  padding-bottom: .625rem;
  padding-top: 1.625rem
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  transform: translateY(-.5rem)
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 1;
  transform: translateY(-.5rem)
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: 1;
  transform: translateY(-.5rem)
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 1;
  transform: translateY(-.5rem)
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%
}

.input-group > .form-control, .input-group > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3
}

.input-group .btn {
  position: relative;
  z-index: 2
}

.input-group .btn:focus {
  z-index: 3
}

.input-group-text {
  align-items: center;
  background-color: #fff;
  border: 1px solid #f1f4f8;
  border-radius: .375rem;
  color: #869ab8;
  display: flex;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  padding: .8125rem 1.25rem;
  text-align: center;
  white-space: nowrap
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  border-radius: .375rem;
  font-size: 1.0625rem;
  padding: 1.122rem 1.5rem
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  border-radius: .375rem;
  font-size: 1.0625rem;
  padding: .5625rem 1rem
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 2.5rem
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px
}

.valid-feedback {
  color: #42ba96;
  display: none;
  font-size: 88.2%;
  margin-top: .25rem;
  width: 100%
}

.valid-tooltip {
  background-color: rgba(66, 186, 150, .9);
  border-radius: .375rem;
  color: #fff;
  display: none;
  font-size: .9375rem;
  margin-top: .1rem;
  max-width: 100%;
  padding: .25rem .5rem;
  position: absolute;
  top: 100%;
  z-index: 5
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #42ba96
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #42ba96
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #42ba96
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #42ba96
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, .25)
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #42ba96
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 1
}

.input-group .form-control.is-valid:focus, .input-group .form-select.is-valid:focus, .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .form-select:valid:focus {
  z-index: 3
}

.invalid-feedback {
  color: #df4759;
  display: none;
  font-size: 88.2%;
  margin-top: .25rem;
  width: 100%
}

.invalid-tooltip {
  background-color: rgba(223, 71, 89, .9);
  border-radius: .375rem;
  color: #fff;
  display: none;
  font-size: .9375rem;
  margin-top: .1rem;
  max-width: 100%;
  padding: .25rem .5rem;
  position: absolute;
  top: 100%;
  z-index: 5
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #df4759
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #df4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #df4759
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #df4759;
  box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #df4759
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #df4759
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, .25)
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #df4759
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
  z-index: 2
}

.input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus, .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .form-select:invalid:focus {
  z-index: 3
}

.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .375rem;
  color: #161c2d;
  cursor: pointer;
  display: inline-block;
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.6;
  padding: .8125rem 1.25rem;
  text-align: center;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #161c2d;
  text-decoration: none
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: none;
  outline: 0
}

.btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn:active {
  box-shadow: none
}

.btn-check:active + .btn:focus, .btn-check:checked + .btn:focus, .btn.active:focus, .btn:active:focus {
  box-shadow: none, none
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  box-shadow: none;
  opacity: .65;
  pointer-events: none
}

.btn-primary {
  background-color: #142840;
  border-color: #142840;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover {
  background-color: #112236;
  border-color: #102033;
  color: #fff
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  box-shadow: none, 0 0 0 0 rgba(55, 72, 93, .5)
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  background-color: #102033;
  border-color: #0f1e30;
  color: #fff
}

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(55, 72, 93, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.btn-secondary {
  background-color: #506690;
  border-color: #506690;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-secondary:hover {
  background-color: #44577a;
  border-color: #405273;
  color: #fff
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  box-shadow: none, 0 0 0 0 rgba(106, 125, 161, .5)
}

.btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  background-color: #405273;
  border-color: #3c4d6c;
  color: #fff
}

.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(106, 125, 161, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #506690;
  border-color: #506690;
  color: #fff
}

.btn-success {
  background-color: #42ba96;
  border-color: #42ba96;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-success, .btn-success:focus, .btn-success:hover {
  background-color: #389e80;
  border-color: #359578;
  color: #fff
}

.btn-check:focus + .btn-success, .btn-success:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 196, 166, .5)
}

.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
  background-color: #359578;
  border-color: #328c71;
  color: #fff
}

.btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(94, 196, 166, .5)
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff
}

.btn-info {
  background-color: #0495a9;
  border-color: #0495a9;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-info, .btn-info:focus, .btn-info:hover {
  background-color: #037f90;
  border-color: #037787;
  color: #fff
}

.btn-check:focus + .btn-info, .btn-info:focus {
  box-shadow: none, 0 0 0 0 rgba(42, 165, 182, .5)
}

.btn-check:active + .btn-info, .btn-check:checked + .btn-info, .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
  background-color: #037787;
  border-color: #03707f;
  color: #fff
}

.btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(42, 165, 182, .5)
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #0495a9;
  border-color: #0495a9;
  color: #fff
}

.btn-warning {
  background-color: #fad776;
  border-color: #fad776;
  box-shadow: none;
  color: #1b2a4e
}

.btn-check:focus + .btn-warning, .btn-warning:focus, .btn-warning:hover {
  background-color: #fbdd8b;
  border-color: #fbdb84;
  color: #1b2a4e
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  box-shadow: none, 0 0 0 0 rgba(217, 189, 112, .5)
}

.btn-check:active + .btn-warning, .btn-check:checked + .btn-warning, .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
  background-color: #fbdf91;
  border-color: #fbdb84;
  color: #1b2a4e
}

.btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(217, 189, 112, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #fad776;
  border-color: #fad776;
  color: #1b2a4e
}

.btn-danger {
  background-color: #df4759;
  border-color: #df4759;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-danger, .btn-danger:focus, .btn-danger:hover {
  background-color: #be3c4c;
  border-color: #b23947;
  color: #fff
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  box-shadow: none, 0 0 0 0 rgba(228, 99, 114, .5)
}

.btn-check:active + .btn-danger, .btn-check:checked + .btn-danger, .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
  background-color: #b23947;
  border-color: #a73543;
  color: #fff
}

.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(228, 99, 114, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #df4759;
  border-color: #df4759;
  color: #fff
}

.btn-light {
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  box-shadow: none;
  color: #1b2a4e
}

.btn-check:focus + .btn-light, .btn-light:focus, .btn-light:hover {
  background-color: #fafcfd;
  border-color: #fafbfd;
  color: #1b2a4e
}

.btn-check:focus + .btn-light, .btn-light:focus {
  box-shadow: none, 0 0 0 0 rgba(216, 220, 227, .5)
}

.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
  background-color: #fafcfd;
  border-color: #fafbfd;
  color: #1b2a4e
}

.btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(216, 220, 227, .5)
}

.btn-light.disabled, .btn-light:disabled {
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  color: #1b2a4e
}

.btn-dark {
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  box-shadow: none;
  color: #fff
}

.btn-check:focus + .btn-dark, .btn-dark:focus, .btn-dark:hover {
  background-color: #172442;
  border-color: #16223e;
  color: #fff
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  box-shadow: none, 0 0 0 0 rgba(61, 74, 105, .5)
}

.btn-check:active + .btn-dark, .btn-check:checked + .btn-dark, .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
  background-color: #16223e;
  border-color: #14203b;
  color: #fff
}

.btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(61, 74, 105, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  color: #fff
}

.btn-primary-desat {
  background-color: #6c8aec;
  border-color: #6c8aec;
  box-shadow: none;
  color: #fff
}

.btn-primary-desat:hover {
  background-color: #5c75c9;
  border-color: #566ebd;
  color: #fff
}

.btn-check:focus + .btn-primary-desat, .btn-primary-desat:focus {
  background-color: #5c75c9;
  border-color: #566ebd;
  box-shadow: none, 0 0 0 0 rgba(130, 156, 239, .5);
  color: #fff
}

.btn-check:active + .btn-primary-desat, .btn-check:checked + .btn-primary-desat, .btn-primary-desat.active, .btn-primary-desat:active, .show > .btn-primary-desat.dropdown-toggle {
  background-color: #566ebd;
  border-color: #5168b1;
  color: #fff
}

.btn-check:active + .btn-primary-desat:focus, .btn-check:checked + .btn-primary-desat:focus, .btn-primary-desat.active:focus, .btn-primary-desat:active:focus, .show > .btn-primary-desat.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(130, 156, 239, .5)
}

.btn-primary-desat.disabled, .btn-primary-desat:disabled {
  background-color: #6c8aec;
  border-color: #6c8aec;
  color: #fff
}

.btn-black {
  background-color: #161c2d;
  border-color: #161c2d;
  box-shadow: none;
  color: #fff
}

.btn-black:focus, .btn-black:hover, .btn-check:focus + .btn-black {
  background-color: #131826;
  border-color: #121624;
  color: #fff
}

.btn-black:focus, .btn-check:focus + .btn-black {
  box-shadow: none, 0 0 0 0 rgba(57, 62, 77, .5)
}

.btn-black.active, .btn-black:active, .btn-check:active + .btn-black, .btn-check:checked + .btn-black, .show > .btn-black.dropdown-toggle {
  background-color: #121624;
  border-color: #111522;
  color: #fff
}

.btn-black.active:focus, .btn-black:active:focus, .btn-check:active + .btn-black:focus, .btn-check:checked + .btn-black:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(57, 62, 77, .5)
}

.btn-black.disabled, .btn-black:disabled {
  background-color: #161c2d;
  border-color: #161c2d;
  color: #fff
}

.btn-white {
  border-color: #fff;
  box-shadow: none;
  color: #1b2a4e
}

.btn-check:focus + .btn-white, .btn-white:focus, .btn-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:focus + .btn-white, .btn-white:focus {
  box-shadow: none, 0 0 0 0 rgba(221, 223, 228, .5)
}

.btn-check:active + .btn-white, .btn-check:checked + .btn-white, .btn-white.active, .btn-white:active, .show > .btn-white.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:active + .btn-white:focus, .btn-check:checked + .btn-white:focus, .btn-white.active:focus, .btn-white:active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(221, 223, 228, .5)
}

.btn-white.disabled, .btn-white:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-outline-primary {
  border-color: #142840;
  color: #142840
}

.btn-outline-primary:hover {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(20, 40, 64, .5)
}

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: none, 0 0 0 0 rgba(20, 40, 64, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  background-color: transparent;
  color: #142840
}

.btn-outline-secondary {
  border-color: #506690;
  color: #506690
}

.btn-outline-secondary:hover {
  background-color: #506690;
  border-color: #506690;
  color: #fff
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(80, 102, 144, .5)
}

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  background-color: #506690;
  border-color: #506690;
  color: #fff
}

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: none, 0 0 0 0 rgba(80, 102, 144, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  background-color: transparent;
  color: #506690
}

.btn-outline-success {
  border-color: #42ba96;
  color: #42ba96
}

.btn-outline-success:hover {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, .5)
}

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff
}

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  box-shadow: none, 0 0 0 0 rgba(66, 186, 150, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  background-color: transparent;
  color: #42ba96
}

.btn-outline-info {
  border-color: #0495a9;
  color: #0495a9
}

.btn-outline-info:hover {
  background-color: #0495a9;
  border-color: #0495a9;
  color: #fff
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(4, 149, 169, .5)
}

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  background-color: #0495a9;
  border-color: #0495a9;
  color: #fff
}

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  box-shadow: none, 0 0 0 0 rgba(4, 149, 169, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  background-color: transparent;
  color: #0495a9
}

.btn-outline-warning {
  border-color: #fad776;
  color: #fad776
}

.btn-outline-warning:hover {
  background-color: #fad776;
  border-color: #fad776;
  color: #1b2a4e
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(250, 215, 118, .5)
}

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  background-color: #fad776;
  border-color: #fad776;
  color: #1b2a4e
}

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  box-shadow: none, 0 0 0 0 rgba(250, 215, 118, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background-color: transparent;
  color: #fad776
}

.btn-outline-danger {
  border-color: #df4759;
  color: #df4759
}

.btn-outline-danger:hover {
  background-color: #df4759;
  border-color: #df4759;
  color: #fff
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(223, 71, 89, .5)
}

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  background-color: #df4759;
  border-color: #df4759;
  color: #fff
}

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: none, 0 0 0 0 rgba(223, 71, 89, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  background-color: transparent;
  color: #df4759
}

.btn-outline-light {
  border-color: #f9fbfd;
  color: #f9fbfd
}

.btn-outline-light:hover {
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  color: #1b2a4e
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(249, 251, 253, .5)
}

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  color: #1b2a4e
}

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  box-shadow: none, 0 0 0 0 rgba(249, 251, 253, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  background-color: transparent;
  color: #f9fbfd
}

.btn-outline-dark {
  border-color: #1b2a4e;
  color: #1b2a4e
}

.btn-outline-dark:hover {
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  color: #fff
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(27, 42, 78, .5)
}

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  color: #fff
}

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  box-shadow: none, 0 0 0 0 rgba(27, 42, 78, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  background-color: transparent;
  color: #1b2a4e
}

.btn-outline-primary-desat {
  border-color: #6c8aec;
  color: #6c8aec
}

.btn-outline-primary-desat:hover {
  background-color: #6c8aec;
  border-color: #6c8aec;
  color: #fff
}

.btn-check:focus + .btn-outline-primary-desat, .btn-outline-primary-desat:focus {
  box-shadow: 0 0 0 0 rgba(108, 138, 236, .5)
}

.btn-check:active + .btn-outline-primary-desat, .btn-check:checked + .btn-outline-primary-desat, .btn-outline-primary-desat.active, .btn-outline-primary-desat.dropdown-toggle.show, .btn-outline-primary-desat:active {
  background-color: #6c8aec;
  border-color: #6c8aec;
  color: #fff
}

.btn-check:active + .btn-outline-primary-desat:focus, .btn-check:checked + .btn-outline-primary-desat:focus, .btn-outline-primary-desat.active:focus, .btn-outline-primary-desat.dropdown-toggle.show:focus, .btn-outline-primary-desat:active:focus {
  box-shadow: none, 0 0 0 0 rgba(108, 138, 236, .5)
}

.btn-outline-primary-desat.disabled, .btn-outline-primary-desat:disabled {
  background-color: transparent;
  color: #6c8aec
}

.btn-outline-black {
  border-color: #161c2d;
  color: #161c2d
}

.btn-outline-black:hover {
  background-color: #161c2d;
  border-color: #161c2d;
  color: #fff
}

.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 0 0 rgba(22, 28, 45, .5)
}

.btn-check:active + .btn-outline-black, .btn-check:checked + .btn-outline-black, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show, .btn-outline-black:active {
  background-color: #161c2d;
  border-color: #161c2d;
  color: #fff
}

.btn-check:active + .btn-outline-black:focus, .btn-check:checked + .btn-outline-black:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus, .btn-outline-black:active:focus {
  box-shadow: none, 0 0 0 0 rgba(22, 28, 45, .5)
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  background-color: transparent;
  color: #161c2d
}

.btn-outline-white {
  border-color: #fff;
  color: #fff
}

.btn-outline-white:hover {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0 hsla(0, 0%, 100%, .5)
}

.btn-check:active + .btn-outline-white, .btn-check:checked + .btn-outline-white, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show, .btn-outline-white:active {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:active + .btn-outline-white:focus, .btn-check:checked + .btn-outline-white:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus, .btn-outline-white:active:focus {
  box-shadow: none, 0 0 0 0 hsla(0, 0%, 100%, .5)
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  background-color: transparent;
  color: #fff
}

.btn-link {
  color: #142840;
  font-weight: 400;
  text-decoration: none
}

.btn-link:hover {
  color: #102033
}

.btn-link:focus, .btn-link:hover {
  text-decoration: underline
}

.btn-link.disabled, .btn-link:disabled {
  color: #869ab8
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .375rem;
  font-size: 1.0625rem;
  padding: 1.122rem 1.5rem
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .375rem;
  font-size: 1.0625rem;
  padding: .5625rem 1rem
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none
  }
}

.dropdown, .dropend, .dropstart, .dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle:after {
  border-bottom: 0;
  border-left: .3em solid transparent;
  border-right: .3em solid transparent;
  border-top: .3em solid;
  content: "";
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em
}

.dropdown-toggle:empty:after {
  margin-left: 0
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border: 0 solid rgba(22, 28, 45, .15);
  border-radius: .375rem;
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1);
  color: #161c2d;
  display: none;
  font-size: .9375rem;
  list-style: none;
  margin: 0;
  min-width: 14rem;
  padding: 1.625rem 1.75rem;
  position: absolute;
  text-align: left;
  z-index: 1000
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0;
  top: 100%
}

.dropdown-menu-start {
  --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto
}

.dropdown-menu-end {
  --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto
  }
  .dropdown-menu-sm-end {
    --bs-position: end
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start
  }
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto
  }
  .dropdown-menu-md-end {
    --bs-position: end
  }
  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto
  }
  .dropdown-menu-lg-end {
    --bs-position: end
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto
  }
  .dropdown-menu-xl-end {
    --bs-position: end
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto
  }
  .dropdown-menu-xxl-end {
    --bs-position: end
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: 0;
  margin-top: 0;
  top: auto
}

.dropup .dropdown-toggle:after {
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
  border-right: .3em solid transparent;
  border-top: 0;
  content: "";
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: 0;
  margin-top: 0;
  right: auto;
  top: 0
}

.dropend .dropdown-toggle:after {
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
  border-right: 0;
  border-top: .3em solid transparent;
  content: "";
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropend .dropdown-toggle:after {
  vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: 0;
  margin-top: 0;
  right: 100%;
  top: 0
}

.dropstart .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: .255em;
  vertical-align: .255em
}

.dropstart .dropdown-toggle:before {
  border-bottom: .3em solid transparent;
  border-right: .3em solid;
  border-top: .3em solid transparent;
  content: "";
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0
}

.dropdown-divider {
  border-top: 1px solid rgba(22, 28, 45, .15);
  height: 0;
  margin: .5rem 0;
  overflow: hidden
}

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #506690;
  display: block;
  font-weight: 400;
  padding: 0 1.75rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  background-color: none;
  color: #142840;
  text-decoration: none
}

.dropdown-item.disabled, .dropdown-item:disabled {
  background-color: transparent;
  color: #abbcd5;
  pointer-events: none
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  color: #142840;
  display: block;
  font-size: .9375rem;
  margin-bottom: 0;
  padding: 1.625rem 1.75rem;
  white-space: nowrap
}

.dropdown-item-text {
  color: #506690;
  display: block;
  padding: 0 1.75rem
}

.dropdown-menu-dark {
  background-color: #384c74;
  border-color: rgba(22, 28, 45, .15);
  color: #d9e2ef
}

.dropdown-menu-dark .dropdown-item {
  color: #d9e2ef
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
  background-color: hsla(0, 0%, 100%, .15);
  color: #fff
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  background-color: none;
  color: #142840
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #abbcd5
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(22, 28, 45, .15)
}

.dropdown-menu-dark .dropdown-item-text {
  color: #d9e2ef
}

.dropdown-menu-dark .dropdown-header {
  color: #abbcd5
}

.btn-group, .btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: 1 1 auto;
  position: relative
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem
}

.dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem
}

.btn-group.show .dropdown-toggle, .btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0
}

.nav-link {
  color: #142840;
  display: block;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none
  }
}

.nav-link:focus, .nav-link:hover {
  color: #102033;
  text-decoration: none
}

.nav-link.disabled {
  color: #869ab8;
  cursor: default;
  pointer-events: none
}

.nav-tabs {
  border-bottom: 1px solid #d9e2ef
}

.nav-tabs .nav-link {
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  margin-bottom: -1px
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #f1f4f8 #f1f4f8 #d9e2ef;
  isolation: isolate
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #869ab8
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: #d9e2ef #d9e2ef #fff;
  color: #506690
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .375rem
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #142840;
  color: #fff
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%
}

.tab-content > .tab-pane {
  display: none
}

.tab-content > .active {
  display: block
}

.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.25rem 0;
  position: relative
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between
}

.navbar-brand {
  font-size: 1.5rem;
  margin-right: 0;
  padding-bottom: .15rem;
  padding-top: .15rem;
  white-space: nowrap
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0
}

.navbar-nav .dropdown-menu {
  position: static
}

.navbar-text {
  padding-bottom: .5rem;
  padding-top: .5rem
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1
}

.navbar-toggler {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .375rem;
  font-size: 1.1875rem;
  line-height: 1;
  padding: .25rem .75rem;
  transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none
  }
}

.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
  text-decoration: none
}

.navbar-toggler-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }
  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }
  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }
  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }
  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start
}

.navbar-expand .navbar-nav {
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #142840
}

.navbar-light .navbar-nav .nav-link {
  color: #506690
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #142840
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(22, 28, 45, .3)
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #142840
}

.navbar-light .navbar-toggler {
  border-color: transparent;
  color: #506690
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23506690' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
  color: #506690
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #142840
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
  color: #fff
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text, .navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff
}

.card {
  word-wrap: break-word;
  background-clip: border-box;
  background-color: #fff;
  border: 0 solid #f1f4f8;
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  min-width: 0
}

.card > hr {
  margin-left: 0;
  margin-right: 0
}

.card > .list-group {
  border-bottom: inherit;
  border-top: inherit
}

.card > .list-group:first-child {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  border-top-width: 0
}

.card > .list-group:last-child {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
  border-bottom-width: 0
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0
}

.card-body {
  flex: 1 1 auto;
  padding: 2rem
}

.card-title {
  margin-bottom: 1.5rem
}

.card-subtitle {
  margin-top: -.75rem
}

.card-subtitle, .card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link + .card-link {
  margin-left: 2rem
}

.card-header {
  background-color: rgba(22, 28, 45, .03);
  border-bottom: 0 solid #f1f4f8;
  margin-bottom: 0;
  padding: 1.5rem 2rem
}

.card-header:first-child {
  border-radius: .375rem .375rem 0 0
}

.card-footer {
  background-color: rgba(22, 28, 45, .03);
  border-top: 0 solid #f1f4f8;
  padding: 1.5rem 2rem
}

.card-footer:last-child {
  border-radius: 0 0 .375rem .375rem
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -1.5rem
}

.card-header-pills, .card-header-tabs {
  margin-left: -1rem;
  margin-right: -1rem
}

.card-img-overlay {
  border-radius: .375rem;
  bottom: 0;
  left: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%
}

.card-img, .card-img-top {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.card-group > .card {
  margin-bottom: 1.25rem
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
  }
  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }
  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
  }
  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }
  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.accordion-button {
  overflow-anchor: none;
  align-items: center;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  color: #161c2d;
  display: flex;
  font-size: 1.0625rem;
  padding: 1.5rem 2rem;
  position: relative;
  text-align: left;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  width: 100%
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none
  }
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: inset 0 -1px 0 #f1f4f8;
  color: #161c2d
}

.accordion-button:not(.collapsed):after {
  transform: rotate(-180deg)
}

.accordion-button:after, .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23869AB8'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}

.accordion-button:after {
  background-repeat: no-repeat;
  background-size: .85rem;
  content: "";
  flex-shrink: 0;
  height: .85rem;
  margin-left: auto;
  transition: transform .2s ease-in-out;
  width: .85rem
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none
  }
}

.accordion-button:hover {
  z-index: 2
}

.accordion-button:focus {
  border-color: #142840;
  box-shadow: none;
  outline: 0;
  z-index: 3
}

.accordion-header {
  margin-bottom: 0
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #f1f4f8
}

.accordion-item:first-of-type {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.375rem - 1px);
  border-top-right-radius: calc(.375rem - 1px)
}

.accordion-item:not(:first-of-type) {
  border-top: 0
}

.accordion-item:last-of-type {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: calc(.375rem - 1px);
  border-bottom-right-radius: calc(.375rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.accordion-body {
  padding: 1.5rem 2rem
}

.accordion-flush .accordion-collapse {
  border-width: 0
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0
}

.accordion-flush .accordion-item:first-child {
  border-top: 0
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button, .breadcrumb {
  border-radius: 0
}

.breadcrumb {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  font-size: .9375rem;
  list-style: none;
  margin-bottom: 0;
  padding: .75rem 0
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #506690;
  content: var(--bs-breadcrumb-divider, "/");
  float: left;
  padding-right: .5rem
}

.breadcrumb-item.active {
  color: #506690
}

.pagination {
  display: flex;
  list-style: none;
  padding-left: 0
}

.page-link {
  background-color: #fff;
  border: 1px solid #d9e2ef;
  color: #142840;
  display: block;
  position: relative;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none
  }
}

.page-link:hover {
  border-color: #d9e2ef;
  text-decoration: none;
  z-index: 2
}

.page-link:focus, .page-link:hover {
  background-color: #f1f4f8;
  color: #102033
}

.page-link:focus {
  box-shadow: none;
  outline: 0;
  z-index: 3
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px
}

.page-item.active .page-link {
  background-color: #142840;
  border-color: #142840;
  color: #fff;
  z-index: 3
}

.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #d9e2ef;
  color: #869ab8;
  pointer-events: none
}

.page-link {
  padding: .8125rem 1.25rem
}

.page-item:first-child .page-link {
  border-bottom-left-radius: .375rem;
  border-top-left-radius: .375rem
}

.page-item:last-child .page-link {
  border-bottom-right-radius: .375rem;
  border-top-right-radius: .375rem
}

.pagination-lg .page-link {
  font-size: 1.1875rem;
  padding: 1.122rem 1.5rem
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: .5rem;
  border-top-left-radius: .5rem
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: .5rem;
  border-top-right-radius: .5rem
}

.pagination-sm .page-link {
  font-size: .9375rem;
  padding: .5625rem 1rem
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: .25rem;
  border-top-left-radius: .25rem
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem
}

.badge {
  border-radius: .375rem;
  color: #fff;
  display: inline-block;
  font-size: .75em;
  font-weight: 600;
  line-height: 1;
  padding: .25rem .75rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.alert {
  border: 1px solid transparent;
  border-radius: .375rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 600
}

.alert-dismissible {
  padding-right: 3.75rem
}

.alert-dismissible .btn-close {
  padding: .9375rem 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.alert-primary {
  color: #727e8c
}

.alert-primary .alert-link {
  color: #5b6570
}

.alert-secondary {
  color: #20293a
}

.alert-secondary .alert-link {
  color: #1a212e
}

.alert-success {
  color: #1a4a3c
}

.alert-success .alert-link {
  color: #153b30
}

.alert-info {
  color: #023c44
}

.alert-info .alert-link {
  color: #023036
}

.alert-warning {
  color: #64562f
}

.alert-warning .alert-link {
  color: #504526
}

.alert-danger {
  color: #591c24
}

.alert-danger .alert-link {
  color: #47161d
}

.alert-light {
  color: #646465
}

.alert-light .alert-link {
  color: #505051
}

.alert-dark {
  color: #767f95
}

.alert-dark .alert-link {
  color: #5e6677
}

.alert-primary-desat {
  color: #2b375e
}

.alert-primary-desat .alert-link {
  color: #222c4b
}

.alert-black {
  color: #737781
}

.alert-black .alert-link {
  color: #5c5f67
}

.alert-white {
  color: #666
}

.alert-white .alert-link {
  color: #525252
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

.progress {
  background-color: #f1f4f8;
  border-radius: .375rem;
  box-shadow: inset 0 1px 2px rgba(22, 28, 45, .075);
  font-size: .79688rem;
  height: 1rem
}

.progress, .progress-bar {
  display: flex;
  overflow: hidden
}

.progress-bar {
  background-color: #142840;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width .6s ease;
  white-space: nowrap
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none
  }
}

.list-group {
  border-radius: .375rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none
}

.list-group-numbered > li:before {
  content: counters(section, ".") ". ";
  counter-increment: section
}

.list-group-item-action {
  color: #506690;
  text-align: inherit;
  width: 100%
}

.list-group-item-action:focus, .list-group-item-action:hover {
  background-color: #f9fbfd;
  color: #506690;
  text-decoration: none;
  z-index: 1
}

.list-group-item-action:active {
  background-color: #f1f4f8;
  color: #161c2d
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #f1f4f8;
  color: #1b2a4e;
  display: block;
  padding: 1.5rem 2rem;
  position: relative
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit
}

.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
  background-color: #fff;
  color: #869ab8;
  pointer-events: none
}

.list-group-item.active {
  background-color: #142840;
  border-color: #142840;
  color: #fff;
  z-index: 2
}

.list-group-item + .list-group-item {
  border-top-width: 0
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px
}

.list-group-horizontal {
  flex-direction: row
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: .375rem;
  border-top-right-radius: 0
}

.list-group-horizontal > .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: .375rem
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: 1px
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .375rem
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .375rem
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .375rem
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .375rem
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: .375rem
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 1px
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px
  }
}

.list-group-flush {
  border-radius: 0
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  background-color: #d0d4d9;
  color: #0c1826
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  background-color: #bbbfc3;
  color: #0c1826
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #0c1826;
  border-color: #0c1826;
  color: #fff
}

.list-group-item-secondary {
  background-color: #dce0e9;
  color: #303d56
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  background-color: #c6cad2;
  color: #303d56
}

.list-group-item-secondary.list-group-item-action.active {
  background-color: #303d56;
  border-color: #303d56;
  color: #fff
}

.list-group-item-success {
  background-color: #d9f1ea;
  color: #28705a
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  background-color: #c3d9d3;
  color: #28705a
}

.list-group-item-success.list-group-item-action.active {
  background-color: #28705a;
  border-color: #28705a;
  color: #fff
}

.list-group-item-info {
  background-color: #cdeaee;
  color: #025965
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  background-color: #b9d3d6;
  color: #025965
}

.list-group-item-info.list-group-item-action.active {
  background-color: #025965;
  border-color: #025965;
  color: #fff
}

.list-group-item-warning {
  background-color: #fef7e4;
  color: #968147
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  background-color: #e5decd;
  color: #968147
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #968147;
  border-color: #968147;
  color: #fff
}

.list-group-item-danger {
  background-color: #f9dade;
  color: #862b35
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  background-color: #e0c4c8;
  color: #862b35
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #862b35;
  border-color: #862b35;
  color: #fff
}

.list-group-item-light {
  background-color: #fefeff;
  color: #959798
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  background-color: #e5e5e6;
  color: #959798
}

.list-group-item-light.list-group-item-action.active {
  background-color: #959798;
  border-color: #959798;
  color: #fff
}

.list-group-item-dark {
  background-color: #d1d4dc;
  color: #10192f
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  background-color: #bcbfc6;
  color: #10192f
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #10192f;
  border-color: #10192f;
  color: #fff
}

.list-group-item-primary-desat {
  background-color: #e2e8fb;
  color: #41538e
}

.list-group-item-primary-desat.list-group-item-action:focus, .list-group-item-primary-desat.list-group-item-action:hover {
  background-color: #cbd1e2;
  color: #41538e
}

.list-group-item-primary-desat.list-group-item-action.active {
  background-color: #41538e;
  border-color: #41538e;
  color: #fff
}

.list-group-item-black {
  background-color: #d0d2d5;
  color: #0d111b
}

.list-group-item-black.list-group-item-action:focus, .list-group-item-black.list-group-item-action:hover {
  background-color: #bbbdc0;
  color: #0d111b
}

.list-group-item-black.list-group-item-action.active {
  background-color: #0d111b;
  border-color: #0d111b;
  color: #fff
}

.list-group-item-white {
  background-color: #fff;
  color: #999
}

.list-group-item-white.list-group-item-action:focus, .list-group-item-white.list-group-item-action:hover {
  background-color: #e6e6e6;
  color: #999
}

.list-group-item-white.list-group-item-action.active {
  background-color: #999;
  border-color: #999;
  color: #fff
}

.btn-close {
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23869AB8'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/.5em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  box-sizing: content-box;
  color: #869ab8;
  height: .5em;
  opacity: .5;
  padding: .25em;
  width: .5em
}

.btn-close:hover {
  color: #869ab8;
  opacity: .75;
  text-decoration: none
}

.btn-close:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0
}

.btn-close.disabled, .btn-close:disabled {
  opacity: .25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, .85);
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .375rem;
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1);
  font-size: .875rem;
  max-width: 100%;
  pointer-events: auto;
  width: 350px
}

.toast:not(.showing):not(.show) {
  opacity: 0
}

.toast.hide {
  display: none
}

.toast-container {
  max-width: 100%;
  pointer-events: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content
}

.toast-container > :not(:last-child) {
  margin-bottom: 1.25rem
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: hsla(0, 0%, 100%, .85);
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.375rem - 1px);
  border-top-right-radius: calc(.375rem - 1px);
  color: #869ab8;
  display: flex;
  padding: .5rem .75rem
}

.toast-header .btn-close {
  margin-left: .75rem;
  margin-right: -.375rem
}

.toast-body {
  word-wrap: break-word;
  padding: .75rem
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1060
}

.modal-dialog {
  margin: .5rem;
  pointer-events: none;
  position: relative;
  width: auto
}

.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform .3s ease-out
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem)
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(22, 28, 45, .2);
  border-radius: .5rem;
  box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%
}

.modal-backdrop {
  background-color: #161c2d;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .8
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid #f1f4f8;
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 2.5rem
}

.modal-header .btn-close {
  margin: -1.25rem -1.25rem -1.25rem auto;
  padding: 1.25rem
}

.modal-title {
  line-height: 1.6;
  margin-bottom: 0
}

.modal-body {
  flex: 1 1 auto;
  padding: 2.5rem;
  position: relative
}

.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc(.5rem - 1px);
  border-bottom-right-radius: calc(.5rem - 1px);
  border-top: 1px solid #f1f4f8;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 2.25rem
}

.modal-footer > * {
  margin: .25rem
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem)
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }
  .modal-content {
    box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1)
  }
  .modal-sm {
    max-width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%
}

.modal-fullscreen .modal-header {
  border-radius: 0
}

.modal-fullscreen .modal-body {
  overflow-y: auto
}

.modal-fullscreen .modal-footer {
  border-radius: 0
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0
  }
}

.tooltip {
  word-wrap: break-word;
  display: block;
  font-family: HKGroteskPro, serif;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1080
}

.tooltip.show {
  opacity: .9
}

.tooltip .tooltip-arrow {
  display: block;
  height: .4rem;
  position: absolute;
  width: .8rem
}

.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute
}

.bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #161c2d;
  border-width: .4rem .4rem 0;
  top: -1px
}

.bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
  padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  height: .8rem;
  left: 0;
  width: .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before, .bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #161c2d;
  border-width: .4rem .4rem .4rem 0;
  right: -1px
}

.bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #161c2d;
  border-width: 0 .4rem .4rem;
  bottom: -1px
}

.bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
  padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  height: .8rem;
  right: 0;
  width: .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before, .bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #161c2d;
  border-width: .4rem 0 .4rem .4rem;
  left: -1px
}

.tooltip-inner {
  background-color: #161c2d;
  border-radius: .375rem;
  color: #fff;
  max-width: 200px;
  padding: .25rem .5rem;
  text-align: center
}

.popover {
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: .375rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .1);
  display: block;
  font-family: HKGroteskPro, serif;
  font-size: .9375rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  max-width: 380px;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070
}

.popover .popover-arrow {
  display: block;
  height: .625rem;
  position: absolute;
  width: 1.25rem
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.625rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow:before, .bs-popover-top > .popover-arrow:before {
  border-top-color: rgba(0, 0, 0, .05);
  border-width: .625rem .625rem 0;
  bottom: 0
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow:after, .bs-popover-top > .popover-arrow:after {
  border-top-color: #fff;
  border-width: .625rem .625rem 0;
  bottom: 1px
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow, .bs-popover-end > .popover-arrow {
  height: 1.25rem;
  left: calc(-.625rem - 1px);
  width: .625rem
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow:before, .bs-popover-end > .popover-arrow:before {
  border-right-color: rgba(0, 0, 0, .05);
  border-width: .625rem .625rem .625rem 0;
  left: 0
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow:after, .bs-popover-end > .popover-arrow:after {
  border-right-color: #fff;
  border-width: .625rem .625rem .625rem 0;
  left: 1px
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.625rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: rgba(0, 0, 0, .05);
  border-width: 0 .625rem .625rem;
  top: 0
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: #fff;
  border-width: 0 .625rem .625rem;
  top: 1px
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
  border-bottom: 1px solid transparent;
  content: "";
  display: block;
  left: 50%;
  margin-left: -.625rem;
  position: absolute;
  top: 0;
  width: 1.25rem
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow, .bs-popover-start > .popover-arrow {
  height: 1.25rem;
  right: calc(-.625rem - 1px);
  width: .625rem
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow:before, .bs-popover-start > .popover-arrow:before {
  border-left-color: rgba(0, 0, 0, .05);
  border-width: .625rem 0 .625rem .625rem;
  right: 0
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow:after, .bs-popover-start > .popover-arrow:after {
  border-left-color: #fff;
  border-width: .625rem 0 .625rem .625rem;
  right: 1px
}

.popover-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  border-top-left-radius: calc(.375rem - 1px);
  border-top-right-radius: calc(.375rem - 1px);
  font-size: 1.0625rem;
  margin-bottom: 0;
  padding: 1.5rem 2rem
}

.popover-header:empty {
  display: none
}

.popover-body {
  color: #869ab8;
  padding: 1.5rem 2rem
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  touch-action: pan-y
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block
}

.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform .6s ease-in-out;
  width: 100%
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%)
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  opacity: 0;
  transition: opacity 0s .6s;
  z-index: 0
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none
  }
}

.carousel-control-next, .carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: .5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity .15s ease;
  width: 15%;
  z-index: 1
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
}

.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2
}

.carousel-indicators [data-bs-target] {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: .5;
  padding: 0;
  text-indent: -999px;
  transition: opacity .6s ease;
  width: 30px
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  bottom: 1.25rem;
  color: #fff;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #161c2d
}

.carousel-dark .carousel-caption {
  color: #161c2d
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}

.spinner-border {
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  border: .25em solid;
  border-radius: 50%;
  border-right: .25em solid transparent;
  display: inline-block;
  height: 2rem;
  vertical-align: -.125em;
  width: 2rem
}

.spinner-border-sm {
  border-width: .2em;
  height: 1rem;
  width: 1rem
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0)
  }
  50% {
    opacity: 1;
    transform: none
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0)
  }
  50% {
    opacity: 1;
    transform: none
  }
}

.spinner-grow {
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: -.125em;
  width: 2rem
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s
  }
}

.offcanvas {
  background-clip: padding-box;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform .3s ease-in-out;
  visibility: hidden;
  z-index: 1050
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none
  }
}

.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2.5rem
}

.offcanvas-header .btn-close {
  margin: -1.25rem -1.25rem -1.25rem auto;
  padding: 1.25rem
}

.offcanvas-title {
  line-height: 1.6;
  margin-bottom: 0
}

.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2.5rem
}

.offcanvas-start {
  border-right: 1px solid rgba(22, 28, 45, .2);
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: 400px
}

.offcanvas-end {
  border-left: 1px solid rgba(22, 28, 45, .2);
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 400px
}

.offcanvas-top {
  border-bottom: 1px solid rgba(22, 28, 45, .2);
  top: 0;
  transform: translateY(-100%)
}

.offcanvas-bottom, .offcanvas-top {
  height: 30vh;
  left: 0;
  max-height: 100%;
  right: 0
}

.offcanvas-bottom {
  border-top: 1px solid rgba(22, 28, 45, .2);
  transform: translateY(100%)
}

.offcanvas.show {
  transform: none
}

.clearfix:after {
  clear: both;
  content: "";
  display: block
}

.link-primary {
  color: #142840
}

.link-primary:focus, .link-primary:hover {
  color: #102033
}

.link-secondary {
  color: #506690
}

.link-secondary:focus, .link-secondary:hover {
  color: #405273
}

.link-success {
  color: #42ba96
}

.link-success:focus, .link-success:hover {
  color: #359578
}

.link-info {
  color: #0495a9
}

.link-info:focus, .link-info:hover {
  color: #037787
}

.link-warning {
  color: #fad776
}

.link-warning:focus, .link-warning:hover {
  color: #fbdf91
}

.link-danger {
  color: #df4759
}

.link-danger:focus, .link-danger:hover {
  color: #b23947
}

.link-light {
  color: #f9fbfd
}

.link-light:focus, .link-light:hover {
  color: #fafcfd
}

.link-dark {
  color: #1b2a4e
}

.link-dark:focus, .link-dark:hover {
  color: #16223e
}

.link-primary-desat {
  color: #6c8aec
}

.link-primary-desat:focus, .link-primary-desat:hover {
  color: #566ebd
}

.link-black {
  color: #161c2d
}

.link-black:focus, .link-black:hover {
  color: #121624
}

.link-white, .link-white:focus, .link-white:hover {
  color: #fff
}

.ratio {
  position: relative;
  width: 100%
}

.ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio)
}

.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.85714%
}

.fixed-top {
  top: 0
}

.fixed-bottom, .fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030
}

.fixed-bottom {
  bottom: 0
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important
}

.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.float-start {
  float: left !important
}

.float-end {
  float: right !important
}

.float-none {
  float: none !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-scroll {
  overflow: scroll !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-grid {
  display: grid !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

.d-none {
  display: none !important
}

.shadow {
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1) !important
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(22, 28, 45, .075) !important
}

.shadow-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .1) !important
}

.shadow-light {
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .05) !important
}

.shadow-dark {
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .15) !important
}

.shadow-light-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .05) !important
}

.shadow-dark-lg {
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15) !important
}

.shadow-lift {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1) !important
}

.shadow-none {
  box-shadow: none !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: sticky !important
}

.top-0 {
  top: 0 !important
}

.bottom-0 {
  bottom: 0 !important
}

.start-0 {
  left: 0 !important
}

.start-50 {
  left: 50% !important
}

.start-100 {
  left: 100% !important
}

.end-0 {
  right: 0 !important
}

.end-50 {
  right: 50% !important
}

.end-100 {
  right: 100% !important
}

.translate-middle {
  transform: translate(-50%, -50%) !important
}

.translate-middle-x {
  transform: translateX(-50%) !important
}

.translate-middle-y {
  transform: translateY(-50%) !important
}

.border {
  border: 1px solid #f1f4f8 !important
}

.border-0 {
  border: 0 !important
}

.border-top {
  border-top: 1px solid #f1f4f8 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right {
  border-right: 1px solid #f1f4f8 !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom {
  border-bottom: 1px solid #f1f4f8 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left {
  border-left: 1px solid #f1f4f8 !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #142840 !important
}

.border-secondary {
  border-color: #506690 !important
}

.border-success {
  border-color: #42ba96 !important
}

.border-info {
  border-color: #0495a9 !important
}

.border-warning {
  border-color: #fad776 !important
}

.border-danger {
  border-color: #df4759 !important
}

.border-light {
  border-color: #f9fbfd !important
}

.border-dark {
  border-color: #1b2a4e !important
}

.border-primary-desat {
  border-color: #6c8aec !important
}

.border-black {
  border-color: #161c2d !important
}

.border-white {
  border-color: #fff !important
}

.border-white-10 {
  border-color: hsla(0, 0%, 100%, .1) !important
}

.border-white-20 {
  border-color: hsla(0, 0%, 100%, .2) !important
}

.border-gray-300 {
  border-color: #d9e2ef !important
}

.border-gray-800 {
  border-color: #384c74 !important
}

.border-gray-800-50 {
  border-color: rgba(56, 76, 116, .5) !important
}

.border-gray-900-50 {
  border-color: rgba(27, 42, 78, .5) !important
}

.border-1 {
  border-width: 1px !important
}

.border-2 {
  border-width: 2px !important
}

.border-3 {
  border-width: 3px !important
}

.border-4 {
  border-width: 4px !important
}

.border-5 {
  border-width: 5px !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-110 {
  width: 110% !important
}

.w-120 {
  width: 120% !important
}

.w-130 {
  width: 130% !important
}

.w-140 {
  width: 140% !important
}

.w-150 {
  width: 150% !important
}

.w-auto {
  width: auto !important
}

.mw-25 {
  max-width: 25% !important
}

.mw-50 {
  max-width: 50% !important
}

.mw-75 {
  max-width: 75% !important
}

.mw-100 {
  max-width: 100% !important
}

.mw-110 {
  max-width: 110% !important
}

.mw-120 {
  max-width: 120% !important
}

.mw-130 {
  max-width: 130% !important
}

.mw-140 {
  max-width: 140% !important
}

.mw-150 {
  max-width: 150% !important
}

.vw-25 {
  width: 25vw !important
}

.vw-50 {
  width: 50vw !important
}

.vw-75 {
  width: 75vw !important
}

.vw-100 {
  width: 100vw !important
}

.vw-110 {
  width: 110vw !important
}

.vw-120 {
  width: 120vw !important
}

.vw-130 {
  width: 130vw !important
}

.vw-140 {
  width: 140vw !important
}

.vw-150 {
  width: 150vw !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-110 {
  height: 110% !important
}

.h-120 {
  height: 120% !important
}

.h-130 {
  height: 130% !important
}

.h-140 {
  height: 140% !important
}

.h-150 {
  height: 150% !important
}

.h-auto {
  height: auto !important
}

.mh-100 {
  max-height: 100% !important
}

.vh-25 {
  height: 25vh !important
}

.vh-50 {
  height: 50vh !important
}

.vh-75 {
  height: 75vh !important
}

.vh-100 {
  height: 100vh !important
}

.vh-110 {
  height: 110vh !important
}

.vh-120 {
  height: 120vh !important
}

.vh-130 {
  height: 130vh !important
}

.vh-140 {
  height: 140vh !important
}

.vh-150 {
  height: 150vh !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.gap-0 {
  gap: 0 !important
}

.gap-1 {
  gap: .25rem !important
}

.gap-2 {
  gap: .5rem !important
}

.gap-3 {
  gap: .75rem !important
}

.gap-4 {
  gap: 1rem !important
}

.gap-5 {
  gap: 1.5rem !important
}

.gap-6 {
  gap: 2rem !important
}

.gap-7 {
  gap: 2.5rem !important
}

.gap-8 {
  gap: 3rem !important
}

.gap-9 {
  gap: 4rem !important
}

.gap-10 {
  gap: 5rem !important
}

.gap-11 {
  gap: 6rem !important
}

.gap-12 {
  gap: 8rem !important
}

.gap-13 {
  gap: 10rem !important
}

.gap-14 {
  gap: 12rem !important
}

.gap-15 {
  gap: 16rem !important
}

.gap-16 {
  gap: 25rem !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.justify-content-evenly {
  justify-content: space-evenly !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.order-first {
  order: -1 !important
}

.order-0 {
  order: 0 !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.order-3 {
  order: 3 !important
}

.order-4 {
  order: 4 !important
}

.order-5 {
  order: 5 !important
}

.order-last {
  order: 6 !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.m-3 {
  margin: .75rem !important
}

.m-4 {
  margin: 1rem !important
}

.m-5 {
  margin: 1.5rem !important
}

.m-6 {
  margin: 2rem !important
}

.m-7 {
  margin: 2.5rem !important
}

.m-8 {
  margin: 3rem !important
}

.m-9 {
  margin: 4rem !important
}

.m-10 {
  margin: 5rem !important
}

.m-11 {
  margin: 6rem !important
}

.m-12 {
  margin: 8rem !important
}

.m-13 {
  margin: 10rem !important
}

.m-14 {
  margin: 12rem !important
}

.m-15 {
  margin: 16rem !important
}

.m-16 {
  margin: 25rem !important
}

.m-auto {
  margin: auto !important
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important
}

.mx-3 {
  margin-left: .75rem !important;
  margin-right: .75rem !important
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important
}

.mx-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important
}

.mx-6 {
  margin-left: 2rem !important;
  margin-right: 2rem !important
}

.mx-7 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important
}

.mx-8 {
  margin-left: 3rem !important;
  margin-right: 3rem !important
}

.mx-9 {
  margin-left: 4rem !important;
  margin-right: 4rem !important
}

.mx-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important
}

.mx-11 {
  margin-left: 6rem !important;
  margin-right: 6rem !important
}

.mx-12 {
  margin-left: 8rem !important;
  margin-right: 8rem !important
}

.mx-13 {
  margin-left: 10rem !important;
  margin-right: 10rem !important
}

.mx-14 {
  margin-left: 12rem !important;
  margin-right: 12rem !important
}

.mx-15 {
  margin-left: 16rem !important;
  margin-right: 16rem !important
}

.mx-16 {
  margin-left: 25rem !important;
  margin-right: 25rem !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important
}

.my-1 {
  margin-bottom: .25rem !important;
  margin-top: .25rem !important
}

.my-2 {
  margin-bottom: .5rem !important;
  margin-top: .5rem !important
}

.my-3 {
  margin-bottom: .75rem !important;
  margin-top: .75rem !important
}

.my-4 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important
}

.my-5 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important
}

.my-6 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important
}

.my-7 {
  margin-bottom: 2.5rem !important;
  margin-top: 2.5rem !important
}

.my-8 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important
}

.my-9 {
  margin-bottom: 4rem !important;
  margin-top: 4rem !important
}

.my-10 {
  margin-bottom: 5rem !important;
  margin-top: 5rem !important
}

.my-11 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important
}

.my-12 {
  margin-bottom: 8rem !important;
  margin-top: 8rem !important
}

.my-13 {
  margin-bottom: 10rem !important;
  margin-top: 10rem !important
}

.my-14 {
  margin-bottom: 12rem !important;
  margin-top: 12rem !important
}

.my-15 {
  margin-bottom: 16rem !important;
  margin-top: 16rem !important
}

.my-16 {
  margin-bottom: 25rem !important;
  margin-top: 25rem !important
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important
}

.mt-0 {
  margin-top: 0 !important
}

.mt-1 {
  margin-top: .25rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: .75rem !important
}

.mt-4 {
  margin-top: 1rem !important
}

.mt-5 {
  margin-top: 1.5rem !important
}

.mt-6 {
  margin-top: 2rem !important
}

.mt-7 {
  margin-top: 2.5rem !important
}

.mt-8 {
  margin-top: 3rem !important
}

.mt-9 {
  margin-top: 4rem !important
}

.mt-10 {
  margin-top: 5rem !important
}

.mt-11 {
  margin-top: 6rem !important
}

.mt-12 {
  margin-top: 8rem !important
}

.mt-13 {
  margin-top: 10rem !important
}

.mt-14 {
  margin-top: 12rem !important
}

.mt-15 {
  margin-top: 16rem !important
}

.mt-16 {
  margin-top: 25rem !important
}

.mt-auto {
  margin-top: auto !important
}

.me-0 {
  margin-right: 0 !important
}

.me-1 {
  margin-right: .25rem !important
}

.me-2 {
  margin-right: .5rem !important
}

.me-3 {
  margin-right: .75rem !important
}

.me-4 {
  margin-right: 1rem !important
}

.me-5 {
  margin-right: 1.5rem !important
}

.me-6 {
  margin-right: 2rem !important
}

.me-7 {
  margin-right: 2.5rem !important
}

.me-8 {
  margin-right: 3rem !important
}

.me-9 {
  margin-right: 4rem !important
}

.me-10 {
  margin-right: 5rem !important
}

.me-11 {
  margin-right: 6rem !important
}

.me-12 {
  margin-right: 8rem !important
}

.me-13 {
  margin-right: 10rem !important
}

.me-14 {
  margin-right: 12rem !important
}

.me-15 {
  margin-right: 16rem !important
}

.me-16 {
  margin-right: 25rem !important
}

.me-auto {
  margin-right: auto !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: .75rem !important
}

.mb-4 {
  margin-bottom: 1rem !important
}

.mb-5 {
  margin-bottom: 1.5rem !important
}

.mb-6 {
  margin-bottom: 2rem !important
}

.mb-7 {
  margin-bottom: 2.5rem !important
}

.mb-8 {
  margin-bottom: 3rem !important
}

.mb-9 {
  margin-bottom: 4rem !important
}

.mb-10 {
  margin-bottom: 5rem !important
}

.mb-11 {
  margin-bottom: 6rem !important
}

.mb-12 {
  margin-bottom: 8rem !important
}

.mb-13 {
  margin-bottom: 10rem !important
}

.mb-14 {
  margin-bottom: 12rem !important
}

.mb-15 {
  margin-bottom: 16rem !important
}

.mb-16 {
  margin-bottom: 25rem !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ms-0 {
  margin-left: 0 !important
}

.ms-1 {
  margin-left: .25rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-3 {
  margin-left: .75rem !important
}

.ms-4 {
  margin-left: 1rem !important
}

.ms-5 {
  margin-left: 1.5rem !important
}

.ms-6 {
  margin-left: 2rem !important
}

.ms-7 {
  margin-left: 2.5rem !important
}

.ms-8 {
  margin-left: 3rem !important
}

.ms-9 {
  margin-left: 4rem !important
}

.ms-10 {
  margin-left: 5rem !important
}

.ms-11 {
  margin-left: 6rem !important
}

.ms-12 {
  margin-left: 8rem !important
}

.ms-13 {
  margin-left: 10rem !important
}

.ms-14 {
  margin-left: 12rem !important
}

.ms-15 {
  margin-left: 16rem !important
}

.ms-16 {
  margin-left: 25rem !important
}

.ms-auto {
  margin-left: auto !important
}

.m-n1 {
  margin: -.25rem !important
}

.m-n2 {
  margin: -.5rem !important
}

.m-n3 {
  margin: -.75rem !important
}

.m-n4 {
  margin: -1rem !important
}

.m-n5 {
  margin: -1.5rem !important
}

.m-n6 {
  margin: -2rem !important
}

.m-n7 {
  margin: -2.5rem !important
}

.m-n8 {
  margin: -3rem !important
}

.m-n9 {
  margin: -4rem !important
}

.m-n10 {
  margin: -5rem !important
}

.m-n11 {
  margin: -6rem !important
}

.m-n12 {
  margin: -8rem !important
}

.m-n13 {
  margin: -10rem !important
}

.m-n14 {
  margin: -12rem !important
}

.m-n15 {
  margin: -16rem !important
}

.m-n16 {
  margin: -25rem !important
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important
}

.mx-n3 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important
}

.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important
}

.mx-n5 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important
}

.mx-n6 {
  margin-left: -2rem !important;
  margin-right: -2rem !important
}

.mx-n7 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important
}

.mx-n8 {
  margin-left: -3rem !important;
  margin-right: -3rem !important
}

.mx-n9 {
  margin-left: -4rem !important;
  margin-right: -4rem !important
}

.mx-n10 {
  margin-left: -5rem !important;
  margin-right: -5rem !important
}

.mx-n11 {
  margin-left: -6rem !important;
  margin-right: -6rem !important
}

.mx-n12 {
  margin-left: -8rem !important;
  margin-right: -8rem !important
}

.mx-n13 {
  margin-left: -10rem !important;
  margin-right: -10rem !important
}

.mx-n14 {
  margin-left: -12rem !important;
  margin-right: -12rem !important
}

.mx-n15 {
  margin-left: -16rem !important;
  margin-right: -16rem !important
}

.mx-n16 {
  margin-left: -25rem !important;
  margin-right: -25rem !important
}

.my-n1 {
  margin-bottom: -.25rem !important;
  margin-top: -.25rem !important
}

.my-n2 {
  margin-bottom: -.5rem !important;
  margin-top: -.5rem !important
}

.my-n3 {
  margin-bottom: -.75rem !important;
  margin-top: -.75rem !important
}

.my-n4 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important
}

.my-n5 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important
}

.my-n6 {
  margin-bottom: -2rem !important;
  margin-top: -2rem !important
}

.my-n7 {
  margin-bottom: -2.5rem !important;
  margin-top: -2.5rem !important
}

.my-n8 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important
}

.my-n9 {
  margin-bottom: -4rem !important;
  margin-top: -4rem !important
}

.my-n10 {
  margin-bottom: -5rem !important;
  margin-top: -5rem !important
}

.my-n11 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important
}

.my-n12 {
  margin-bottom: -8rem !important;
  margin-top: -8rem !important
}

.my-n13 {
  margin-bottom: -10rem !important;
  margin-top: -10rem !important
}

.my-n14 {
  margin-bottom: -12rem !important;
  margin-top: -12rem !important
}

.my-n15 {
  margin-bottom: -16rem !important;
  margin-top: -16rem !important
}

.my-n16 {
  margin-bottom: -25rem !important;
  margin-top: -25rem !important
}

.mt-n1 {
  margin-top: -.25rem !important
}

.mt-n2 {
  margin-top: -.5rem !important
}

.mt-n3 {
  margin-top: -.75rem !important
}

.mt-n4 {
  margin-top: -1rem !important
}

.mt-n5 {
  margin-top: -1.5rem !important
}

.mt-n6 {
  margin-top: -2rem !important
}

.mt-n7 {
  margin-top: -2.5rem !important
}

.mt-n8 {
  margin-top: -3rem !important
}

.mt-n9 {
  margin-top: -4rem !important
}

.mt-n10 {
  margin-top: -5rem !important
}

.mt-n11 {
  margin-top: -6rem !important
}

.mt-n12 {
  margin-top: -8rem !important
}

.mt-n13 {
  margin-top: -10rem !important
}

.mt-n14 {
  margin-top: -12rem !important
}

.mt-n15 {
  margin-top: -16rem !important
}

.mt-n16 {
  margin-top: -25rem !important
}

.me-n1 {
  margin-right: -.25rem !important
}

.me-n2 {
  margin-right: -.5rem !important
}

.me-n3 {
  margin-right: -.75rem !important
}

.me-n4 {
  margin-right: -1rem !important
}

.me-n5 {
  margin-right: -1.5rem !important
}

.me-n6 {
  margin-right: -2rem !important
}

.me-n7 {
  margin-right: -2.5rem !important
}

.me-n8 {
  margin-right: -3rem !important
}

.me-n9 {
  margin-right: -4rem !important
}

.me-n10 {
  margin-right: -5rem !important
}

.me-n11 {
  margin-right: -6rem !important
}

.me-n12 {
  margin-right: -8rem !important
}

.me-n13 {
  margin-right: -10rem !important
}

.me-n14 {
  margin-right: -12rem !important
}

.me-n15 {
  margin-right: -16rem !important
}

.me-n16 {
  margin-right: -25rem !important
}

.mb-n1 {
  margin-bottom: -.25rem !important
}

.mb-n2 {
  margin-bottom: -.5rem !important
}

.mb-n3 {
  margin-bottom: -.75rem !important
}

.mb-n4 {
  margin-bottom: -1rem !important
}

.mb-n5 {
  margin-bottom: -1.5rem !important
}

.mb-n6 {
  margin-bottom: -2rem !important
}

.mb-n7 {
  margin-bottom: -2.5rem !important
}

.mb-n8 {
  margin-bottom: -3rem !important
}

.mb-n9 {
  margin-bottom: -4rem !important
}

.mb-n10 {
  margin-bottom: -5rem !important
}

.mb-n11 {
  margin-bottom: -6rem !important
}

.mb-n12 {
  margin-bottom: -8rem !important
}

.mb-n13 {
  margin-bottom: -10rem !important
}

.mb-n14 {
  margin-bottom: -12rem !important
}

.mb-n15 {
  margin-bottom: -16rem !important
}

.mb-n16 {
  margin-bottom: -25rem !important
}

.ms-n1 {
  margin-left: -.25rem !important
}

.ms-n2 {
  margin-left: -.5rem !important
}

.ms-n3 {
  margin-left: -.75rem !important
}

.ms-n4 {
  margin-left: -1rem !important
}

.ms-n5 {
  margin-left: -1.5rem !important
}

.ms-n6 {
  margin-left: -2rem !important
}

.ms-n7 {
  margin-left: -2.5rem !important
}

.ms-n8 {
  margin-left: -3rem !important
}

.ms-n9 {
  margin-left: -4rem !important
}

.ms-n10 {
  margin-left: -5rem !important
}

.ms-n11 {
  margin-left: -6rem !important
}

.ms-n12 {
  margin-left: -8rem !important
}

.ms-n13 {
  margin-left: -10rem !important
}

.ms-n14 {
  margin-left: -12rem !important
}

.ms-n15 {
  margin-left: -16rem !important
}

.ms-n16 {
  margin-left: -25rem !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.p-3 {
  padding: .75rem !important
}

.p-4 {
  padding: 1rem !important
}

.p-5 {
  padding: 1.5rem !important
}

.p-6 {
  padding: 2rem !important
}

.p-7 {
  padding: 2.5rem !important
}

.p-8 {
  padding: 3rem !important
}

.p-9 {
  padding: 4rem !important
}

.p-10 {
  padding: 5rem !important
}

.p-11 {
  padding: 6rem !important
}

.p-12 {
  padding: 8rem !important
}

.p-13 {
  padding: 10rem !important
}

.p-14 {
  padding: 12rem !important
}

.p-15 {
  padding: 16rem !important
}

.p-16 {
  padding: 25rem !important
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important
}

.px-3 {
  padding-left: .75rem !important;
  padding-right: .75rem !important
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important
}

.px-5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}

.px-6 {
  padding-left: 2rem !important;
  padding-right: 2rem !important
}

.px-7 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important
}

.px-8 {
  padding-left: 3rem !important;
  padding-right: 3rem !important
}

.px-9 {
  padding-left: 4rem !important;
  padding-right: 4rem !important
}

.px-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important
}

.px-11 {
  padding-left: 6rem !important;
  padding-right: 6rem !important
}

.px-12 {
  padding-left: 8rem !important;
  padding-right: 8rem !important
}

.px-13 {
  padding-left: 10rem !important;
  padding-right: 10rem !important
}

.px-14 {
  padding-left: 12rem !important;
  padding-right: 12rem !important
}

.px-15 {
  padding-left: 16rem !important;
  padding-right: 16rem !important
}

.px-16 {
  padding-left: 25rem !important;
  padding-right: 25rem !important
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important
}

.py-1 {
  padding-bottom: .25rem !important;
  padding-top: .25rem !important
}

.py-2 {
  padding-bottom: .5rem !important;
  padding-top: .5rem !important
}

.py-3 {
  padding-bottom: .75rem !important;
  padding-top: .75rem !important
}

.py-4 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important
}

.py-5 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important
}

.py-6 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important
}

.py-7 {
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important
}

.py-8 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important
}

.py-9 {
  padding-bottom: 4rem !important;
  padding-top: 4rem !important
}

.py-10 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important
}

.py-11 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important
}

.py-12 {
  padding-bottom: 8rem !important;
  padding-top: 8rem !important
}

.py-13 {
  padding-bottom: 10rem !important;
  padding-top: 10rem !important
}

.py-14 {
  padding-bottom: 12rem !important;
  padding-top: 12rem !important
}

.py-15 {
  padding-bottom: 16rem !important;
  padding-top: 16rem !important
}

.py-16 {
  padding-bottom: 25rem !important;
  padding-top: 25rem !important
}

.pt-0 {
  padding-top: 0 !important
}

.pt-1 {
  padding-top: .25rem !important
}

.pt-2 {
  padding-top: .5rem !important
}

.pt-3 {
  padding-top: .75rem !important
}

.pt-4 {
  padding-top: 1rem !important
}

.pt-5 {
  padding-top: 1.5rem !important
}

.pt-6 {
  padding-top: 2rem !important
}

.pt-7 {
  padding-top: 2.5rem !important
}

.pt-8 {
  padding-top: 3rem !important
}

.pt-9 {
  padding-top: 4rem !important
}

.pt-10 {
  padding-top: 5rem !important
}

.pt-11 {
  padding-top: 6rem !important
}

.pt-12 {
  padding-top: 8rem !important
}

.pt-13 {
  padding-top: 10rem !important
}

.pt-14 {
  padding-top: 12rem !important
}

.pt-15 {
  padding-top: 16rem !important
}

.pt-16 {
  padding-top: 25rem !important
}

.pe-0 {
  padding-right: 0 !important
}

.pe-1 {
  padding-right: .25rem !important
}

.pe-2 {
  padding-right: .5rem !important
}

.pe-3 {
  padding-right: .75rem !important
}

.pe-4 {
  padding-right: 1rem !important
}

.pe-5 {
  padding-right: 1.5rem !important
}

.pe-6 {
  padding-right: 2rem !important
}

.pe-7 {
  padding-right: 2.5rem !important
}

.pe-8 {
  padding-right: 3rem !important
}

.pe-9 {
  padding-right: 4rem !important
}

.pe-10 {
  padding-right: 5rem !important
}

.pe-11 {
  padding-right: 6rem !important
}

.pe-12 {
  padding-right: 8rem !important
}

.pe-13 {
  padding-right: 10rem !important
}

.pe-14 {
  padding-right: 12rem !important
}

.pe-15 {
  padding-right: 16rem !important
}

.pe-16 {
  padding-right: 25rem !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pb-1 {
  padding-bottom: .25rem !important
}

.pb-2 {
  padding-bottom: .5rem !important
}

.pb-3 {
  padding-bottom: .75rem !important
}

.pb-4 {
  padding-bottom: 1rem !important
}

.pb-5 {
  padding-bottom: 1.5rem !important
}

.pb-6 {
  padding-bottom: 2rem !important
}

.pb-7 {
  padding-bottom: 2.5rem !important
}

.pb-8 {
  padding-bottom: 3rem !important
}

.pb-9 {
  padding-bottom: 4rem !important
}

.pb-10 {
  padding-bottom: 5rem !important
}

.pb-11 {
  padding-bottom: 6rem !important
}

.pb-12 {
  padding-bottom: 8rem !important
}

.pb-13 {
  padding-bottom: 10rem !important
}

.pb-14 {
  padding-bottom: 12rem !important
}

.pb-15 {
  padding-bottom: 16rem !important
}

.pb-16 {
  padding-bottom: 25rem !important
}

.ps-0 {
  padding-left: 0 !important
}

.ps-1 {
  padding-left: .25rem !important
}

.ps-2 {
  padding-left: .5rem !important
}

.ps-3 {
  padding-left: .75rem !important
}

.ps-4 {
  padding-left: 1rem !important
}

.ps-5 {
  padding-left: 1.5rem !important
}

.ps-6 {
  padding-left: 2rem !important
}

.ps-7 {
  padding-left: 2.5rem !important
}

.ps-8 {
  padding-left: 3rem !important
}

.ps-9 {
  padding-left: 4rem !important
}

.ps-10 {
  padding-left: 5rem !important
}

.ps-11 {
  padding-left: 6rem !important
}

.ps-12 {
  padding-left: 8rem !important
}

.ps-13 {
  padding-left: 10rem !important
}

.ps-14 {
  padding-left: 12rem !important
}

.ps-15 {
  padding-left: 16rem !important
}

.ps-16 {
  padding-left: 25rem !important
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important
}

.fs-1 {
  font-size: 2.3125rem !important
}

.fs-2 {
  font-size: 1.9375rem !important
}

.fs-3 {
  font-size: 1.3125rem !important
}

.fs-4 {
  font-size: 1.1875rem !important
}

.fs-5 {
  font-size: 1.0625rem !important
}

.fs-6 {
  font-size: .75rem !important
}

.fs-sm {
  font-size: .9375rem !important
}

.fs-lg {
  font-size: 1.1875rem !important
}

.fst-italic {
  font-style: italic !important
}

.fst-normal {
  font-style: normal !important
}

.fw-light {
  font-weight: 300 !important
}

.fw-lighter {
  font-weight: lighter !important
}

.fw-normal {
  font-weight: 400 !important
}

.fw-bold {
  font-weight: 600 !important
}

.fw-bolder {
  font-weight: 700 !important
}

.lh-1 {
  line-height: 1 !important
}

.lh-sm {
  line-height: 1.3 !important
}

.lh-base {
  line-height: 1.6 !important
}

.lh-lg {
  line-height: 1.3 !important
}

.text-start {
  text-align: left !important
}

.text-end {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

.text-decoration-none {
  text-decoration: none !important
}

.text-decoration-underline {
  text-decoration: underline !important
}

.text-decoration-line-through {
  text-decoration: line-through !important
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important
}

.text-primary {
  color: #142840 !important
}

.text-secondary {
  color: #506690 !important
}

.text-success {
  color: #42ba96 !important
}

.text-info {
  color: #0495a9 !important
}

.text-warning {
  color: #fad776 !important
}

.text-danger {
  color: #df4759 !important
}

.text-light {
  color: #f9fbfd !important
}

.text-dark {
  color: #1b2a4e !important
}

.text-primary-desat {
  color: #6c8aec !important
}

.text-black {
  color: #161c2d !important
}

.text-white {
  color: #fff !important
}

.text-gray-100 {
  color: #f9fbfd !important
}

.text-gray-200 {
  color: #f1f4f8 !important
}

.text-gray-300 {
  color: #d9e2ef !important
}

.text-gray-400 {
  color: #c6d3e6 !important
}

.text-gray-500 {
  color: #abbcd5 !important
}

.text-gray-600 {
  color: #869ab8 !important
}

.text-gray-700 {
  color: #506690 !important
}

.text-gray-800 {
  color: #384c74 !important
}

.text-gray-900 {
  color: #1b2a4e !important
}

.text-body {
  color: #161c2d !important
}

.text-muted {
  color: #869ab8 !important
}

.text-muted-80 {
  color: rgba(134, 154, 184, .8) !important
}

.text-black-50 {
  color: rgba(22, 28, 45, .5) !important
}

.text-white-50 {
  color: hsla(0, 0%, 100%, .5) !important
}

.text-white-70 {
  color: hsla(0, 0%, 100%, .7) !important
}

.text-white-75 {
  color: hsla(0, 0%, 100%, .75) !important
}

.text-white-80 {
  color: hsla(0, 0%, 100%, .8) !important
}

.text-reset {
  color: inherit !important
}

.bg-primary {
  background-color: #142840 !important
}

.bg-secondary {
  background-color: #506690 !important
}

.bg-success {
  background-color: #42ba96 !important
}

.bg-info {
  background-color: #0495a9 !important
}

.bg-warning {
  background-color: #fad776 !important
}

.bg-danger {
  background-color: #df4759 !important
}

.bg-light {
  background-color: #f9fbfd !important
}

.bg-dark {
  background-color: #1b2a4e !important
}

.bg-primary-desat {
  background-color: #6c8aec !important
}

.bg-black {
  background-color: #161c2d !important
}

.bg-white {
  background-color: #fff !important
}

.bg-white-20 {
  background-color: hsla(0, 0%, 100%, .2) !important
}

.bg-gray-200 {
  background-color: #f1f4f8 !important
}

.bg-gray-300 {
  background-color: #d9e2ef !important
}

.bg-gray-800 {
  background-color: #384c74 !important
}

.bg-gray-900 {
  background-color: #1b2a4e !important
}

.bg-gray-900-50 {
  background-color: rgba(27, 42, 78, .5) !important
}

.bg-gradient {
  background-image: var(--bs-gradient) !important
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.pe-none {
  pointer-events: none !important
}

.pe-auto {
  pointer-events: auto !important
}

.rounded {
  border-radius: .375rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.rounded-1 {
  border-radius: .25rem !important
}

.rounded-2 {
  border-radius: .375rem !important
}

.rounded-3 {
  border-radius: .5rem !important
}

.rounded-4 {
  border-radius: 1rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-top {
  border-top-left-radius: .375rem !important
}

.rounded-end, .rounded-top {
  border-top-right-radius: .375rem !important
}

.rounded-bottom, .rounded-end {
  border-bottom-right-radius: .375rem !important
}

.rounded-bottom, .rounded-start {
  border-bottom-left-radius: .375rem !important
}

.rounded-start {
  border-top-left-radius: .375rem !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

.right-0 {
  right: 0 !important
}

.left-0 {
  left: 0 !important
}

.letter-spacing-lg {
  letter-spacing: .08em !important
}

.opacity-0 {
  opacity: 0 !important
}

.opacity-1 {
  opacity: 1 !important
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important
  }
  .float-sm-end {
    float: right !important
  }
  .float-sm-none {
    float: none !important
  }
  .d-sm-inline {
    display: inline !important
  }
  .d-sm-inline-block {
    display: inline-block !important
  }
  .d-sm-block {
    display: block !important
  }
  .d-sm-grid {
    display: grid !important
  }
  .d-sm-table {
    display: table !important
  }
  .d-sm-table-row {
    display: table-row !important
  }
  .d-sm-table-cell {
    display: table-cell !important
  }
  .d-sm-flex {
    display: flex !important
  }
  .d-sm-inline-flex {
    display: inline-flex !important
  }
  .d-sm-none {
    display: none !important
  }
  .position-sm-static {
    position: static !important
  }
  .position-sm-relative {
    position: relative !important
  }
  .position-sm-absolute {
    position: absolute !important
  }
  .position-sm-fixed {
    position: fixed !important
  }
  .position-sm-sticky {
    position: sticky !important
  }
  .border-sm {
    border: 1px solid #f1f4f8 !important
  }
  .border-sm-0 {
    border: 0 !important
  }
  .border-top-sm {
    border-top: 1px solid #f1f4f8 !important
  }
  .border-top-sm-0 {
    border-top: 0 !important
  }
  .border-right-sm {
    border-right: 1px solid #f1f4f8 !important
  }
  .border-right-sm-0 {
    border-right: 0 !important
  }
  .border-bottom-sm {
    border-bottom: 1px solid #f1f4f8 !important
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important
  }
  .border-left-sm {
    border-left: 1px solid #f1f4f8 !important
  }
  .border-left-sm-0 {
    border-left: 0 !important
  }
  .w-sm-25 {
    width: 25% !important
  }
  .w-sm-50 {
    width: 50% !important
  }
  .w-sm-75 {
    width: 75% !important
  }
  .w-sm-100 {
    width: 100% !important
  }
  .w-sm-110 {
    width: 110% !important
  }
  .w-sm-120 {
    width: 120% !important
  }
  .w-sm-130 {
    width: 130% !important
  }
  .w-sm-140 {
    width: 140% !important
  }
  .w-sm-150 {
    width: 150% !important
  }
  .w-sm-auto {
    width: auto !important
  }
  .mw-sm-25 {
    max-width: 25% !important
  }
  .mw-sm-50 {
    max-width: 50% !important
  }
  .mw-sm-75 {
    max-width: 75% !important
  }
  .mw-sm-100 {
    max-width: 100% !important
  }
  .mw-sm-110 {
    max-width: 110% !important
  }
  .mw-sm-120 {
    max-width: 120% !important
  }
  .mw-sm-130 {
    max-width: 130% !important
  }
  .mw-sm-140 {
    max-width: 140% !important
  }
  .mw-sm-150 {
    max-width: 150% !important
  }
  .vw-sm-25 {
    width: 25vw !important
  }
  .vw-sm-50 {
    width: 50vw !important
  }
  .vw-sm-75 {
    width: 75vw !important
  }
  .vw-sm-100 {
    width: 100vw !important
  }
  .vw-sm-110 {
    width: 110vw !important
  }
  .vw-sm-120 {
    width: 120vw !important
  }
  .vw-sm-130 {
    width: 130vw !important
  }
  .vw-sm-140 {
    width: 140vw !important
  }
  .vw-sm-150 {
    width: 150vw !important
  }
  .h-sm-25 {
    height: 25% !important
  }
  .h-sm-50 {
    height: 50% !important
  }
  .h-sm-75 {
    height: 75% !important
  }
  .h-sm-100 {
    height: 100% !important
  }
  .h-sm-110 {
    height: 110% !important
  }
  .h-sm-120 {
    height: 120% !important
  }
  .h-sm-130 {
    height: 130% !important
  }
  .h-sm-140 {
    height: 140% !important
  }
  .h-sm-150 {
    height: 150% !important
  }
  .h-sm-auto {
    height: auto !important
  }
  .vh-sm-25 {
    height: 25vh !important
  }
  .vh-sm-50 {
    height: 50vh !important
  }
  .vh-sm-75 {
    height: 75vh !important
  }
  .vh-sm-100 {
    height: 100vh !important
  }
  .vh-sm-110 {
    height: 110vh !important
  }
  .vh-sm-120 {
    height: 120vh !important
  }
  .vh-sm-130 {
    height: 130vh !important
  }
  .vh-sm-140 {
    height: 140vh !important
  }
  .vh-sm-150 {
    height: 150vh !important
  }
  .flex-sm-fill {
    flex: 1 1 auto !important
  }
  .flex-sm-row {
    flex-direction: row !important
  }
  .flex-sm-column {
    flex-direction: column !important
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .gap-sm-0 {
    gap: 0 !important
  }
  .gap-sm-1 {
    gap: .25rem !important
  }
  .gap-sm-2 {
    gap: .5rem !important
  }
  .gap-sm-3 {
    gap: .75rem !important
  }
  .gap-sm-4 {
    gap: 1rem !important
  }
  .gap-sm-5 {
    gap: 1.5rem !important
  }
  .gap-sm-6 {
    gap: 2rem !important
  }
  .gap-sm-7 {
    gap: 2.5rem !important
  }
  .gap-sm-8 {
    gap: 3rem !important
  }
  .gap-sm-9 {
    gap: 4rem !important
  }
  .gap-sm-10 {
    gap: 5rem !important
  }
  .gap-sm-11 {
    gap: 6rem !important
  }
  .gap-sm-12 {
    gap: 8rem !important
  }
  .gap-sm-13 {
    gap: 10rem !important
  }
  .gap-sm-14 {
    gap: 12rem !important
  }
  .gap-sm-15 {
    gap: 16rem !important
  }
  .gap-sm-16 {
    gap: 25rem !important
  }
  .justify-content-sm-start {
    justify-content: flex-start !important
  }
  .justify-content-sm-end {
    justify-content: flex-end !important
  }
  .justify-content-sm-center {
    justify-content: center !important
  }
  .justify-content-sm-between {
    justify-content: space-between !important
  }
  .justify-content-sm-around {
    justify-content: space-around !important
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important
  }
  .align-items-sm-start {
    align-items: flex-start !important
  }
  .align-items-sm-end {
    align-items: flex-end !important
  }
  .align-items-sm-center {
    align-items: center !important
  }
  .align-items-sm-baseline {
    align-items: baseline !important
  }
  .align-items-sm-stretch {
    align-items: stretch !important
  }
  .align-content-sm-start {
    align-content: flex-start !important
  }
  .align-content-sm-end {
    align-content: flex-end !important
  }
  .align-content-sm-center {
    align-content: center !important
  }
  .align-content-sm-between {
    align-content: space-between !important
  }
  .align-content-sm-around {
    align-content: space-around !important
  }
  .align-content-sm-stretch {
    align-content: stretch !important
  }
  .align-self-sm-auto {
    align-self: auto !important
  }
  .align-self-sm-start {
    align-self: flex-start !important
  }
  .align-self-sm-end {
    align-self: flex-end !important
  }
  .align-self-sm-center {
    align-self: center !important
  }
  .align-self-sm-baseline {
    align-self: baseline !important
  }
  .align-self-sm-stretch {
    align-self: stretch !important
  }
  .order-sm-first {
    order: -1 !important
  }
  .order-sm-0 {
    order: 0 !important
  }
  .order-sm-1 {
    order: 1 !important
  }
  .order-sm-2 {
    order: 2 !important
  }
  .order-sm-3 {
    order: 3 !important
  }
  .order-sm-4 {
    order: 4 !important
  }
  .order-sm-5 {
    order: 5 !important
  }
  .order-sm-last {
    order: 6 !important
  }
  .m-sm-0 {
    margin: 0 !important
  }
  .m-sm-1 {
    margin: .25rem !important
  }
  .m-sm-2 {
    margin: .5rem !important
  }
  .m-sm-3 {
    margin: .75rem !important
  }
  .m-sm-4 {
    margin: 1rem !important
  }
  .m-sm-5 {
    margin: 1.5rem !important
  }
  .m-sm-6 {
    margin: 2rem !important
  }
  .m-sm-7 {
    margin: 2.5rem !important
  }
  .m-sm-8 {
    margin: 3rem !important
  }
  .m-sm-9 {
    margin: 4rem !important
  }
  .m-sm-10 {
    margin: 5rem !important
  }
  .m-sm-11 {
    margin: 6rem !important
  }
  .m-sm-12 {
    margin: 8rem !important
  }
  .m-sm-13 {
    margin: 10rem !important
  }
  .m-sm-14 {
    margin: 12rem !important
  }
  .m-sm-15 {
    margin: 16rem !important
  }
  .m-sm-16 {
    margin: 25rem !important
  }
  .m-sm-auto {
    margin: auto !important
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
  }
  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
  }
  .mx-sm-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
  }
  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }
  .mx-sm-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
  }
  .mx-sm-6 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }
  .mx-sm-7 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
  }
  .mx-sm-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }
  .mx-sm-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }
  .mx-sm-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }
  .mx-sm-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
  }
  .mx-sm-12 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
  }
  .mx-sm-13 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
  }
  .mx-sm-14 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
  }
  .mx-sm-15 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }
  .mx-sm-16 {
    margin-left: 25rem !important;
    margin-right: 25rem !important
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
  }
  .my-sm-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
  }
  .my-sm-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
  }
  .my-sm-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
  }
  .my-sm-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
  }
  .my-sm-5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
  }
  .my-sm-6 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
  }
  .my-sm-7 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
  }
  .my-sm-8 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
  }
  .my-sm-9 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
  }
  .my-sm-10 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
  }
  .my-sm-11 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
  }
  .my-sm-12 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
  }
  .my-sm-13 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
  }
  .my-sm-14 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
  }
  .my-sm-15 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
  }
  .my-sm-16 {
    margin-bottom: 25rem !important;
    margin-top: 25rem !important
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
  }
  .mt-sm-0 {
    margin-top: 0 !important
  }
  .mt-sm-1 {
    margin-top: .25rem !important
  }
  .mt-sm-2 {
    margin-top: .5rem !important
  }
  .mt-sm-3 {
    margin-top: .75rem !important
  }
  .mt-sm-4 {
    margin-top: 1rem !important
  }
  .mt-sm-5 {
    margin-top: 1.5rem !important
  }
  .mt-sm-6 {
    margin-top: 2rem !important
  }
  .mt-sm-7 {
    margin-top: 2.5rem !important
  }
  .mt-sm-8 {
    margin-top: 3rem !important
  }
  .mt-sm-9 {
    margin-top: 4rem !important
  }
  .mt-sm-10 {
    margin-top: 5rem !important
  }
  .mt-sm-11 {
    margin-top: 6rem !important
  }
  .mt-sm-12 {
    margin-top: 8rem !important
  }
  .mt-sm-13 {
    margin-top: 10rem !important
  }
  .mt-sm-14 {
    margin-top: 12rem !important
  }
  .mt-sm-15 {
    margin-top: 16rem !important
  }
  .mt-sm-16 {
    margin-top: 25rem !important
  }
  .mt-sm-auto {
    margin-top: auto !important
  }
  .me-sm-0 {
    margin-right: 0 !important
  }
  .me-sm-1 {
    margin-right: .25rem !important
  }
  .me-sm-2 {
    margin-right: .5rem !important
  }
  .me-sm-3 {
    margin-right: .75rem !important
  }
  .me-sm-4 {
    margin-right: 1rem !important
  }
  .me-sm-5 {
    margin-right: 1.5rem !important
  }
  .me-sm-6 {
    margin-right: 2rem !important
  }
  .me-sm-7 {
    margin-right: 2.5rem !important
  }
  .me-sm-8 {
    margin-right: 3rem !important
  }
  .me-sm-9 {
    margin-right: 4rem !important
  }
  .me-sm-10 {
    margin-right: 5rem !important
  }
  .me-sm-11 {
    margin-right: 6rem !important
  }
  .me-sm-12 {
    margin-right: 8rem !important
  }
  .me-sm-13 {
    margin-right: 10rem !important
  }
  .me-sm-14 {
    margin-right: 12rem !important
  }
  .me-sm-15 {
    margin-right: 16rem !important
  }
  .me-sm-16 {
    margin-right: 25rem !important
  }
  .me-sm-auto {
    margin-right: auto !important
  }
  .mb-sm-0 {
    margin-bottom: 0 !important
  }
  .mb-sm-1 {
    margin-bottom: .25rem !important
  }
  .mb-sm-2 {
    margin-bottom: .5rem !important
  }
  .mb-sm-3 {
    margin-bottom: .75rem !important
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important
  }
  .mb-sm-5 {
    margin-bottom: 1.5rem !important
  }
  .mb-sm-6 {
    margin-bottom: 2rem !important
  }
  .mb-sm-7 {
    margin-bottom: 2.5rem !important
  }
  .mb-sm-8 {
    margin-bottom: 3rem !important
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important
  }
  .mb-sm-10 {
    margin-bottom: 5rem !important
  }
  .mb-sm-11 {
    margin-bottom: 6rem !important
  }
  .mb-sm-12 {
    margin-bottom: 8rem !important
  }
  .mb-sm-13 {
    margin-bottom: 10rem !important
  }
  .mb-sm-14 {
    margin-bottom: 12rem !important
  }
  .mb-sm-15 {
    margin-bottom: 16rem !important
  }
  .mb-sm-16 {
    margin-bottom: 25rem !important
  }
  .mb-sm-auto {
    margin-bottom: auto !important
  }
  .ms-sm-0 {
    margin-left: 0 !important
  }
  .ms-sm-1 {
    margin-left: .25rem !important
  }
  .ms-sm-2 {
    margin-left: .5rem !important
  }
  .ms-sm-3 {
    margin-left: .75rem !important
  }
  .ms-sm-4 {
    margin-left: 1rem !important
  }
  .ms-sm-5 {
    margin-left: 1.5rem !important
  }
  .ms-sm-6 {
    margin-left: 2rem !important
  }
  .ms-sm-7 {
    margin-left: 2.5rem !important
  }
  .ms-sm-8 {
    margin-left: 3rem !important
  }
  .ms-sm-9 {
    margin-left: 4rem !important
  }
  .ms-sm-10 {
    margin-left: 5rem !important
  }
  .ms-sm-11 {
    margin-left: 6rem !important
  }
  .ms-sm-12 {
    margin-left: 8rem !important
  }
  .ms-sm-13 {
    margin-left: 10rem !important
  }
  .ms-sm-14 {
    margin-left: 12rem !important
  }
  .ms-sm-15 {
    margin-left: 16rem !important
  }
  .ms-sm-16 {
    margin-left: 25rem !important
  }
  .ms-sm-auto {
    margin-left: auto !important
  }
  .m-sm-n1 {
    margin: -.25rem !important
  }
  .m-sm-n2 {
    margin: -.5rem !important
  }
  .m-sm-n3 {
    margin: -.75rem !important
  }
  .m-sm-n4 {
    margin: -1rem !important
  }
  .m-sm-n5 {
    margin: -1.5rem !important
  }
  .m-sm-n6 {
    margin: -2rem !important
  }
  .m-sm-n7 {
    margin: -2.5rem !important
  }
  .m-sm-n8 {
    margin: -3rem !important
  }
  .m-sm-n9 {
    margin: -4rem !important
  }
  .m-sm-n10 {
    margin: -5rem !important
  }
  .m-sm-n11 {
    margin: -6rem !important
  }
  .m-sm-n12 {
    margin: -8rem !important
  }
  .m-sm-n13 {
    margin: -10rem !important
  }
  .m-sm-n14 {
    margin: -12rem !important
  }
  .m-sm-n15 {
    margin: -16rem !important
  }
  .m-sm-n16 {
    margin: -25rem !important
  }
  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
  }
  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
  }
  .mx-sm-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
  }
  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }
  .mx-sm-n5 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
  }
  .mx-sm-n6 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }
  .mx-sm-n7 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
  }
  .mx-sm-n8 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }
  .mx-sm-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }
  .mx-sm-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }
  .mx-sm-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
  }
  .mx-sm-n12 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
  }
  .mx-sm-n13 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
  }
  .mx-sm-n14 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
  }
  .mx-sm-n15 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
  }
  .mx-sm-n16 {
    margin-left: -25rem !important;
    margin-right: -25rem !important
  }
  .my-sm-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
  }
  .my-sm-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
  }
  .my-sm-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
  }
  .my-sm-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
  }
  .my-sm-n5 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
  }
  .my-sm-n6 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
  }
  .my-sm-n7 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
  }
  .my-sm-n8 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
  }
  .my-sm-n9 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
  }
  .my-sm-n10 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
  }
  .my-sm-n11 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
  }
  .my-sm-n12 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
  }
  .my-sm-n13 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
  }
  .my-sm-n14 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
  }
  .my-sm-n15 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
  }
  .my-sm-n16 {
    margin-bottom: -25rem !important;
    margin-top: -25rem !important
  }
  .mt-sm-n1 {
    margin-top: -.25rem !important
  }
  .mt-sm-n2 {
    margin-top: -.5rem !important
  }
  .mt-sm-n3 {
    margin-top: -.75rem !important
  }
  .mt-sm-n4 {
    margin-top: -1rem !important
  }
  .mt-sm-n5 {
    margin-top: -1.5rem !important
  }
  .mt-sm-n6 {
    margin-top: -2rem !important
  }
  .mt-sm-n7 {
    margin-top: -2.5rem !important
  }
  .mt-sm-n8 {
    margin-top: -3rem !important
  }
  .mt-sm-n9 {
    margin-top: -4rem !important
  }
  .mt-sm-n10 {
    margin-top: -5rem !important
  }
  .mt-sm-n11 {
    margin-top: -6rem !important
  }
  .mt-sm-n12 {
    margin-top: -8rem !important
  }
  .mt-sm-n13 {
    margin-top: -10rem !important
  }
  .mt-sm-n14 {
    margin-top: -12rem !important
  }
  .mt-sm-n15 {
    margin-top: -16rem !important
  }
  .mt-sm-n16 {
    margin-top: -25rem !important
  }
  .me-sm-n1 {
    margin-right: -.25rem !important
  }
  .me-sm-n2 {
    margin-right: -.5rem !important
  }
  .me-sm-n3 {
    margin-right: -.75rem !important
  }
  .me-sm-n4 {
    margin-right: -1rem !important
  }
  .me-sm-n5 {
    margin-right: -1.5rem !important
  }
  .me-sm-n6 {
    margin-right: -2rem !important
  }
  .me-sm-n7 {
    margin-right: -2.5rem !important
  }
  .me-sm-n8 {
    margin-right: -3rem !important
  }
  .me-sm-n9 {
    margin-right: -4rem !important
  }
  .me-sm-n10 {
    margin-right: -5rem !important
  }
  .me-sm-n11 {
    margin-right: -6rem !important
  }
  .me-sm-n12 {
    margin-right: -8rem !important
  }
  .me-sm-n13 {
    margin-right: -10rem !important
  }
  .me-sm-n14 {
    margin-right: -12rem !important
  }
  .me-sm-n15 {
    margin-right: -16rem !important
  }
  .me-sm-n16 {
    margin-right: -25rem !important
  }
  .mb-sm-n1 {
    margin-bottom: -.25rem !important
  }
  .mb-sm-n2 {
    margin-bottom: -.5rem !important
  }
  .mb-sm-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important
  }
  .mb-sm-n5 {
    margin-bottom: -1.5rem !important
  }
  .mb-sm-n6 {
    margin-bottom: -2rem !important
  }
  .mb-sm-n7 {
    margin-bottom: -2.5rem !important
  }
  .mb-sm-n8 {
    margin-bottom: -3rem !important
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important
  }
  .mb-sm-n10 {
    margin-bottom: -5rem !important
  }
  .mb-sm-n11 {
    margin-bottom: -6rem !important
  }
  .mb-sm-n12 {
    margin-bottom: -8rem !important
  }
  .mb-sm-n13 {
    margin-bottom: -10rem !important
  }
  .mb-sm-n14 {
    margin-bottom: -12rem !important
  }
  .mb-sm-n15 {
    margin-bottom: -16rem !important
  }
  .mb-sm-n16 {
    margin-bottom: -25rem !important
  }
  .ms-sm-n1 {
    margin-left: -.25rem !important
  }
  .ms-sm-n2 {
    margin-left: -.5rem !important
  }
  .ms-sm-n3 {
    margin-left: -.75rem !important
  }
  .ms-sm-n4 {
    margin-left: -1rem !important
  }
  .ms-sm-n5 {
    margin-left: -1.5rem !important
  }
  .ms-sm-n6 {
    margin-left: -2rem !important
  }
  .ms-sm-n7 {
    margin-left: -2.5rem !important
  }
  .ms-sm-n8 {
    margin-left: -3rem !important
  }
  .ms-sm-n9 {
    margin-left: -4rem !important
  }
  .ms-sm-n10 {
    margin-left: -5rem !important
  }
  .ms-sm-n11 {
    margin-left: -6rem !important
  }
  .ms-sm-n12 {
    margin-left: -8rem !important
  }
  .ms-sm-n13 {
    margin-left: -10rem !important
  }
  .ms-sm-n14 {
    margin-left: -12rem !important
  }
  .ms-sm-n15 {
    margin-left: -16rem !important
  }
  .ms-sm-n16 {
    margin-left: -25rem !important
  }
  .p-sm-0 {
    padding: 0 !important
  }
  .p-sm-1 {
    padding: .25rem !important
  }
  .p-sm-2 {
    padding: .5rem !important
  }
  .p-sm-3 {
    padding: .75rem !important
  }
  .p-sm-4 {
    padding: 1rem !important
  }
  .p-sm-5 {
    padding: 1.5rem !important
  }
  .p-sm-6 {
    padding: 2rem !important
  }
  .p-sm-7 {
    padding: 2.5rem !important
  }
  .p-sm-8 {
    padding: 3rem !important
  }
  .p-sm-9 {
    padding: 4rem !important
  }
  .p-sm-10 {
    padding: 5rem !important
  }
  .p-sm-11 {
    padding: 6rem !important
  }
  .p-sm-12 {
    padding: 8rem !important
  }
  .p-sm-13 {
    padding: 10rem !important
  }
  .p-sm-14 {
    padding: 12rem !important
  }
  .p-sm-15 {
    padding: 16rem !important
  }
  .p-sm-16 {
    padding: 25rem !important
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }
  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
  .px-sm-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
  }
  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }
  .px-sm-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }
  .px-sm-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }
  .px-sm-7 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }
  .px-sm-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }
  .px-sm-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }
  .px-sm-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }
  .px-sm-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
  }
  .px-sm-12 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
  }
  .px-sm-13 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
  }
  .px-sm-14 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
  }
  .px-sm-15 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
  }
  .px-sm-16 {
    padding-left: 25rem !important;
    padding-right: 25rem !important
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }
  .py-sm-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
  }
  .py-sm-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
  }
  .py-sm-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
  }
  .py-sm-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
  }
  .py-sm-5 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
  }
  .py-sm-6 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
  }
  .py-sm-7 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
  }
  .py-sm-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
  }
  .py-sm-9 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
  }
  .py-sm-10 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
  }
  .py-sm-11 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
  }
  .py-sm-12 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
  }
  .py-sm-13 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
  }
  .py-sm-14 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
  }
  .py-sm-15 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
  }
  .py-sm-16 {
    padding-bottom: 25rem !important;
    padding-top: 25rem !important
  }
  .pt-sm-0 {
    padding-top: 0 !important
  }
  .pt-sm-1 {
    padding-top: .25rem !important
  }
  .pt-sm-2 {
    padding-top: .5rem !important
  }
  .pt-sm-3 {
    padding-top: .75rem !important
  }
  .pt-sm-4 {
    padding-top: 1rem !important
  }
  .pt-sm-5 {
    padding-top: 1.5rem !important
  }
  .pt-sm-6 {
    padding-top: 2rem !important
  }
  .pt-sm-7 {
    padding-top: 2.5rem !important
  }
  .pt-sm-8 {
    padding-top: 3rem !important
  }
  .pt-sm-9 {
    padding-top: 4rem !important
  }
  .pt-sm-10 {
    padding-top: 5rem !important
  }
  .pt-sm-11 {
    padding-top: 6rem !important
  }
  .pt-sm-12 {
    padding-top: 8rem !important
  }
  .pt-sm-13 {
    padding-top: 10rem !important
  }
  .pt-sm-14 {
    padding-top: 12rem !important
  }
  .pt-sm-15 {
    padding-top: 16rem !important
  }
  .pt-sm-16 {
    padding-top: 25rem !important
  }
  .pe-sm-0 {
    padding-right: 0 !important
  }
  .pe-sm-1 {
    padding-right: .25rem !important
  }
  .pe-sm-2 {
    padding-right: .5rem !important
  }
  .pe-sm-3 {
    padding-right: .75rem !important
  }
  .pe-sm-4 {
    padding-right: 1rem !important
  }
  .pe-sm-5 {
    padding-right: 1.5rem !important
  }
  .pe-sm-6 {
    padding-right: 2rem !important
  }
  .pe-sm-7 {
    padding-right: 2.5rem !important
  }
  .pe-sm-8 {
    padding-right: 3rem !important
  }
  .pe-sm-9 {
    padding-right: 4rem !important
  }
  .pe-sm-10 {
    padding-right: 5rem !important
  }
  .pe-sm-11 {
    padding-right: 6rem !important
  }
  .pe-sm-12 {
    padding-right: 8rem !important
  }
  .pe-sm-13 {
    padding-right: 10rem !important
  }
  .pe-sm-14 {
    padding-right: 12rem !important
  }
  .pe-sm-15 {
    padding-right: 16rem !important
  }
  .pe-sm-16 {
    padding-right: 25rem !important
  }
  .pb-sm-0 {
    padding-bottom: 0 !important
  }
  .pb-sm-1 {
    padding-bottom: .25rem !important
  }
  .pb-sm-2 {
    padding-bottom: .5rem !important
  }
  .pb-sm-3 {
    padding-bottom: .75rem !important
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important
  }
  .pb-sm-5 {
    padding-bottom: 1.5rem !important
  }
  .pb-sm-6 {
    padding-bottom: 2rem !important
  }
  .pb-sm-7 {
    padding-bottom: 2.5rem !important
  }
  .pb-sm-8 {
    padding-bottom: 3rem !important
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important
  }
  .pb-sm-10 {
    padding-bottom: 5rem !important
  }
  .pb-sm-11 {
    padding-bottom: 6rem !important
  }
  .pb-sm-12 {
    padding-bottom: 8rem !important
  }
  .pb-sm-13 {
    padding-bottom: 10rem !important
  }
  .pb-sm-14 {
    padding-bottom: 12rem !important
  }
  .pb-sm-15 {
    padding-bottom: 16rem !important
  }
  .pb-sm-16 {
    padding-bottom: 25rem !important
  }
  .ps-sm-0 {
    padding-left: 0 !important
  }
  .ps-sm-1 {
    padding-left: .25rem !important
  }
  .ps-sm-2 {
    padding-left: .5rem !important
  }
  .ps-sm-3 {
    padding-left: .75rem !important
  }
  .ps-sm-4 {
    padding-left: 1rem !important
  }
  .ps-sm-5 {
    padding-left: 1.5rem !important
  }
  .ps-sm-6 {
    padding-left: 2rem !important
  }
  .ps-sm-7 {
    padding-left: 2.5rem !important
  }
  .ps-sm-8 {
    padding-left: 3rem !important
  }
  .ps-sm-9 {
    padding-left: 4rem !important
  }
  .ps-sm-10 {
    padding-left: 5rem !important
  }
  .ps-sm-11 {
    padding-left: 6rem !important
  }
  .ps-sm-12 {
    padding-left: 8rem !important
  }
  .ps-sm-13 {
    padding-left: 10rem !important
  }
  .ps-sm-14 {
    padding-left: 12rem !important
  }
  .ps-sm-15 {
    padding-left: 16rem !important
  }
  .ps-sm-16 {
    padding-left: 25rem !important
  }
  .text-sm-start {
    text-align: left !important
  }
  .text-sm-end {
    text-align: right !important
  }
  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important
  }
  .float-md-end {
    float: right !important
  }
  .float-md-none {
    float: none !important
  }
  .d-md-inline {
    display: inline !important
  }
  .d-md-inline-block {
    display: inline-block !important
  }
  .d-md-block {
    display: block !important
  }
  .d-md-grid {
    display: grid !important
  }
  .d-md-table {
    display: table !important
  }
  .d-md-table-row {
    display: table-row !important
  }
  .d-md-table-cell {
    display: table-cell !important
  }
  .d-md-flex {
    display: flex !important
  }
  .d-md-inline-flex {
    display: inline-flex !important
  }
  .d-md-none {
    display: none !important
  }
  .position-md-static {
    position: static !important
  }
  .position-md-relative {
    position: relative !important
  }
  .position-md-absolute {
    position: absolute !important
  }
  .position-md-fixed {
    position: fixed !important
  }
  .position-md-sticky {
    position: sticky !important
  }
  .border-md {
    border: 1px solid #f1f4f8 !important
  }
  .border-md-0 {
    border: 0 !important
  }
  .border-top-md {
    border-top: 1px solid #f1f4f8 !important
  }
  .border-top-md-0 {
    border-top: 0 !important
  }
  .border-right-md {
    border-right: 1px solid #f1f4f8 !important
  }
  .border-right-md-0 {
    border-right: 0 !important
  }
  .border-bottom-md {
    border-bottom: 1px solid #f1f4f8 !important
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important
  }
  .border-left-md {
    border-left: 1px solid #f1f4f8 !important
  }
  .border-left-md-0 {
    border-left: 0 !important
  }
  .w-md-25 {
    width: 25% !important
  }
  .w-md-50 {
    width: 50% !important
  }
  .w-md-75 {
    width: 75% !important
  }
  .w-md-100 {
    width: 100% !important
  }
  .w-md-110 {
    width: 110% !important
  }
  .w-md-120 {
    width: 120% !important
  }
  .w-md-130 {
    width: 130% !important
  }
  .w-md-140 {
    width: 140% !important
  }
  .w-md-150 {
    width: 150% !important
  }
  .w-md-auto {
    width: auto !important
  }
  .mw-md-25 {
    max-width: 25% !important
  }
  .mw-md-50 {
    max-width: 50% !important
  }
  .mw-md-75 {
    max-width: 75% !important
  }
  .mw-md-100 {
    max-width: 100% !important
  }
  .mw-md-110 {
    max-width: 110% !important
  }
  .mw-md-120 {
    max-width: 120% !important
  }
  .mw-md-130 {
    max-width: 130% !important
  }
  .mw-md-140 {
    max-width: 140% !important
  }
  .mw-md-150 {
    max-width: 150% !important
  }
  .vw-md-25 {
    width: 25vw !important
  }
  .vw-md-50 {
    width: 50vw !important
  }
  .vw-md-75 {
    width: 75vw !important
  }
  .vw-md-100 {
    width: 100vw !important
  }
  .vw-md-110 {
    width: 110vw !important
  }
  .vw-md-120 {
    width: 120vw !important
  }
  .vw-md-130 {
    width: 130vw !important
  }
  .vw-md-140 {
    width: 140vw !important
  }
  .vw-md-150 {
    width: 150vw !important
  }
  .h-md-25 {
    height: 25% !important
  }
  .h-md-50 {
    height: 50% !important
  }
  .h-md-75 {
    height: 75% !important
  }
  .h-md-100 {
    height: 100% !important
  }
  .h-md-110 {
    height: 110% !important
  }
  .h-md-120 {
    height: 120% !important
  }
  .h-md-130 {
    height: 130% !important
  }
  .h-md-140 {
    height: 140% !important
  }
  .h-md-150 {
    height: 150% !important
  }
  .h-md-auto {
    height: auto !important
  }
  .vh-md-25 {
    height: 25vh !important
  }
  .vh-md-50 {
    height: 50vh !important
  }
  .vh-md-75 {
    height: 75vh !important
  }
  .vh-md-100 {
    height: 100vh !important
  }
  .vh-md-110 {
    height: 110vh !important
  }
  .vh-md-120 {
    height: 120vh !important
  }
  .vh-md-130 {
    height: 130vh !important
  }
  .vh-md-140 {
    height: 140vh !important
  }
  .vh-md-150 {
    height: 150vh !important
  }
  .flex-md-fill {
    flex: 1 1 auto !important
  }
  .flex-md-row {
    flex-direction: row !important
  }
  .flex-md-column {
    flex-direction: column !important
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-md-wrap {
    flex-wrap: wrap !important
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .gap-md-0 {
    gap: 0 !important
  }
  .gap-md-1 {
    gap: .25rem !important
  }
  .gap-md-2 {
    gap: .5rem !important
  }
  .gap-md-3 {
    gap: .75rem !important
  }
  .gap-md-4 {
    gap: 1rem !important
  }
  .gap-md-5 {
    gap: 1.5rem !important
  }
  .gap-md-6 {
    gap: 2rem !important
  }
  .gap-md-7 {
    gap: 2.5rem !important
  }
  .gap-md-8 {
    gap: 3rem !important
  }
  .gap-md-9 {
    gap: 4rem !important
  }
  .gap-md-10 {
    gap: 5rem !important
  }
  .gap-md-11 {
    gap: 6rem !important
  }
  .gap-md-12 {
    gap: 8rem !important
  }
  .gap-md-13 {
    gap: 10rem !important
  }
  .gap-md-14 {
    gap: 12rem !important
  }
  .gap-md-15 {
    gap: 16rem !important
  }
  .gap-md-16 {
    gap: 25rem !important
  }
  .justify-content-md-start {
    justify-content: flex-start !important
  }
  .justify-content-md-end {
    justify-content: flex-end !important
  }
  .justify-content-md-center {
    justify-content: center !important
  }
  .justify-content-md-between {
    justify-content: space-between !important
  }
  .justify-content-md-around {
    justify-content: space-around !important
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important
  }
  .align-items-md-start {
    align-items: flex-start !important
  }
  .align-items-md-end {
    align-items: flex-end !important
  }
  .align-items-md-center {
    align-items: center !important
  }
  .align-items-md-baseline {
    align-items: baseline !important
  }
  .align-items-md-stretch {
    align-items: stretch !important
  }
  .align-content-md-start {
    align-content: flex-start !important
  }
  .align-content-md-end {
    align-content: flex-end !important
  }
  .align-content-md-center {
    align-content: center !important
  }
  .align-content-md-between {
    align-content: space-between !important
  }
  .align-content-md-around {
    align-content: space-around !important
  }
  .align-content-md-stretch {
    align-content: stretch !important
  }
  .align-self-md-auto {
    align-self: auto !important
  }
  .align-self-md-start {
    align-self: flex-start !important
  }
  .align-self-md-end {
    align-self: flex-end !important
  }
  .align-self-md-center {
    align-self: center !important
  }
  .align-self-md-baseline {
    align-self: baseline !important
  }
  .align-self-md-stretch {
    align-self: stretch !important
  }
  .order-md-first {
    order: -1 !important
  }
  .order-md-0 {
    order: 0 !important
  }
  .order-md-1 {
    order: 1 !important
  }
  .order-md-2 {
    order: 2 !important
  }
  .order-md-3 {
    order: 3 !important
  }
  .order-md-4 {
    order: 4 !important
  }
  .order-md-5 {
    order: 5 !important
  }
  .order-md-last {
    order: 6 !important
  }
  .m-md-0 {
    margin: 0 !important
  }
  .m-md-1 {
    margin: .25rem !important
  }
  .m-md-2 {
    margin: .5rem !important
  }
  .m-md-3 {
    margin: .75rem !important
  }
  .m-md-4 {
    margin: 1rem !important
  }
  .m-md-5 {
    margin: 1.5rem !important
  }
  .m-md-6 {
    margin: 2rem !important
  }
  .m-md-7 {
    margin: 2.5rem !important
  }
  .m-md-8 {
    margin: 3rem !important
  }
  .m-md-9 {
    margin: 4rem !important
  }
  .m-md-10 {
    margin: 5rem !important
  }
  .m-md-11 {
    margin: 6rem !important
  }
  .m-md-12 {
    margin: 8rem !important
  }
  .m-md-13 {
    margin: 10rem !important
  }
  .m-md-14 {
    margin: 12rem !important
  }
  .m-md-15 {
    margin: 16rem !important
  }
  .m-md-16 {
    margin: 25rem !important
  }
  .m-md-auto {
    margin: auto !important
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
  }
  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
  }
  .mx-md-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
  }
  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }
  .mx-md-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
  }
  .mx-md-6 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }
  .mx-md-7 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
  }
  .mx-md-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }
  .mx-md-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }
  .mx-md-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }
  .mx-md-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
  }
  .mx-md-12 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
  }
  .mx-md-13 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
  }
  .mx-md-14 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
  }
  .mx-md-15 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }
  .mx-md-16 {
    margin-left: 25rem !important;
    margin-right: 25rem !important
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
  }
  .my-md-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
  }
  .my-md-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
  }
  .my-md-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
  }
  .my-md-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
  }
  .my-md-5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
  }
  .my-md-6 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
  }
  .my-md-7 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
  }
  .my-md-8 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
  }
  .my-md-9 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
  }
  .my-md-10 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
  }
  .my-md-11 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
  }
  .my-md-12 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
  }
  .my-md-13 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
  }
  .my-md-14 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
  }
  .my-md-15 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
  }
  .my-md-16 {
    margin-bottom: 25rem !important;
    margin-top: 25rem !important
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
  }
  .mt-md-0 {
    margin-top: 0 !important
  }
  .mt-md-1 {
    margin-top: .25rem !important
  }
  .mt-md-2 {
    margin-top: .5rem !important
  }
  .mt-md-3 {
    margin-top: .75rem !important
  }
  .mt-md-4 {
    margin-top: 1rem !important
  }
  .mt-md-5 {
    margin-top: 1.5rem !important
  }
  .mt-md-6 {
    margin-top: 2rem !important
  }
  .mt-md-7 {
    margin-top: 2.5rem !important
  }
  .mt-md-8 {
    margin-top: 3rem !important
  }
  .mt-md-9 {
    margin-top: 4rem !important
  }
  .mt-md-10 {
    margin-top: 5rem !important
  }
  .mt-md-11 {
    margin-top: 6rem !important
  }
  .mt-md-12 {
    margin-top: 8rem !important
  }
  .mt-md-13 {
    margin-top: 10rem !important
  }
  .mt-md-14 {
    margin-top: 12rem !important
  }
  .mt-md-15 {
    margin-top: 16rem !important
  }
  .mt-md-16 {
    margin-top: 25rem !important
  }
  .mt-md-auto {
    margin-top: auto !important
  }
  .me-md-0 {
    margin-right: 0 !important
  }
  .me-md-1 {
    margin-right: .25rem !important
  }
  .me-md-2 {
    margin-right: .5rem !important
  }
  .me-md-3 {
    margin-right: .75rem !important
  }
  .me-md-4 {
    margin-right: 1rem !important
  }
  .me-md-5 {
    margin-right: 1.5rem !important
  }
  .me-md-6 {
    margin-right: 2rem !important
  }
  .me-md-7 {
    margin-right: 2.5rem !important
  }
  .me-md-8 {
    margin-right: 3rem !important
  }
  .me-md-9 {
    margin-right: 4rem !important
  }
  .me-md-10 {
    margin-right: 5rem !important
  }
  .me-md-11 {
    margin-right: 6rem !important
  }
  .me-md-12 {
    margin-right: 8rem !important
  }
  .me-md-13 {
    margin-right: 10rem !important
  }
  .me-md-14 {
    margin-right: 12rem !important
  }
  .me-md-15 {
    margin-right: 16rem !important
  }
  .me-md-16 {
    margin-right: 25rem !important
  }
  .me-md-auto {
    margin-right: auto !important
  }
  .mb-md-0 {
    margin-bottom: 0 !important
  }
  .mb-md-1 {
    margin-bottom: .25rem !important
  }
  .mb-md-2 {
    margin-bottom: .5rem !important
  }
  .mb-md-3 {
    margin-bottom: .75rem !important
  }
  .mb-md-4 {
    margin-bottom: 1rem !important
  }
  .mb-md-5 {
    margin-bottom: 1.5rem !important
  }
  .mb-md-6 {
    margin-bottom: 2rem !important
  }
  .mb-md-7 {
    margin-bottom: 2.5rem !important
  }
  .mb-md-8 {
    margin-bottom: 3rem !important
  }
  .mb-md-9 {
    margin-bottom: 4rem !important
  }
  .mb-md-10 {
    margin-bottom: 5rem !important
  }
  .mb-md-11 {
    margin-bottom: 6rem !important
  }
  .mb-md-12 {
    margin-bottom: 8rem !important
  }
  .mb-md-13 {
    margin-bottom: 10rem !important
  }
  .mb-md-14 {
    margin-bottom: 12rem !important
  }
  .mb-md-15 {
    margin-bottom: 16rem !important
  }
  .mb-md-16 {
    margin-bottom: 25rem !important
  }
  .mb-md-auto {
    margin-bottom: auto !important
  }
  .ms-md-0 {
    margin-left: 0 !important
  }
  .ms-md-1 {
    margin-left: .25rem !important
  }
  .ms-md-2 {
    margin-left: .5rem !important
  }
  .ms-md-3 {
    margin-left: .75rem !important
  }
  .ms-md-4 {
    margin-left: 1rem !important
  }
  .ms-md-5 {
    margin-left: 1.5rem !important
  }
  .ms-md-6 {
    margin-left: 2rem !important
  }
  .ms-md-7 {
    margin-left: 2.5rem !important
  }
  .ms-md-8 {
    margin-left: 3rem !important
  }
  .ms-md-9 {
    margin-left: 4rem !important
  }
  .ms-md-10 {
    margin-left: 5rem !important
  }
  .ms-md-11 {
    margin-left: 6rem !important
  }
  .ms-md-12 {
    margin-left: 8rem !important
  }
  .ms-md-13 {
    margin-left: 10rem !important
  }
  .ms-md-14 {
    margin-left: 12rem !important
  }
  .ms-md-15 {
    margin-left: 16rem !important
  }
  .ms-md-16 {
    margin-left: 25rem !important
  }
  .ms-md-auto {
    margin-left: auto !important
  }
  .m-md-n1 {
    margin: -.25rem !important
  }
  .m-md-n2 {
    margin: -.5rem !important
  }
  .m-md-n3 {
    margin: -.75rem !important
  }
  .m-md-n4 {
    margin: -1rem !important
  }
  .m-md-n5 {
    margin: -1.5rem !important
  }
  .m-md-n6 {
    margin: -2rem !important
  }
  .m-md-n7 {
    margin: -2.5rem !important
  }
  .m-md-n8 {
    margin: -3rem !important
  }
  .m-md-n9 {
    margin: -4rem !important
  }
  .m-md-n10 {
    margin: -5rem !important
  }
  .m-md-n11 {
    margin: -6rem !important
  }
  .m-md-n12 {
    margin: -8rem !important
  }
  .m-md-n13 {
    margin: -10rem !important
  }
  .m-md-n14 {
    margin: -12rem !important
  }
  .m-md-n15 {
    margin: -16rem !important
  }
  .m-md-n16 {
    margin: -25rem !important
  }
  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
  }
  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
  }
  .mx-md-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
  }
  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }
  .mx-md-n5 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
  }
  .mx-md-n6 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }
  .mx-md-n7 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
  }
  .mx-md-n8 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }
  .mx-md-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }
  .mx-md-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }
  .mx-md-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
  }
  .mx-md-n12 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
  }
  .mx-md-n13 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
  }
  .mx-md-n14 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
  }
  .mx-md-n15 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
  }
  .mx-md-n16 {
    margin-left: -25rem !important;
    margin-right: -25rem !important
  }
  .my-md-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
  }
  .my-md-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
  }
  .my-md-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
  }
  .my-md-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
  }
  .my-md-n5 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
  }
  .my-md-n6 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
  }
  .my-md-n7 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
  }
  .my-md-n8 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
  }
  .my-md-n9 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
  }
  .my-md-n10 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
  }
  .my-md-n11 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
  }
  .my-md-n12 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
  }
  .my-md-n13 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
  }
  .my-md-n14 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
  }
  .my-md-n15 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
  }
  .my-md-n16 {
    margin-bottom: -25rem !important;
    margin-top: -25rem !important
  }
  .mt-md-n1 {
    margin-top: -.25rem !important
  }
  .mt-md-n2 {
    margin-top: -.5rem !important
  }
  .mt-md-n3 {
    margin-top: -.75rem !important
  }
  .mt-md-n4 {
    margin-top: -1rem !important
  }
  .mt-md-n5 {
    margin-top: -1.5rem !important
  }
  .mt-md-n6 {
    margin-top: -2rem !important
  }
  .mt-md-n7 {
    margin-top: -2.5rem !important
  }
  .mt-md-n8 {
    margin-top: -3rem !important
  }
  .mt-md-n9 {
    margin-top: -4rem !important
  }
  .mt-md-n10 {
    margin-top: -5rem !important
  }
  .mt-md-n11 {
    margin-top: -6rem !important
  }
  .mt-md-n12 {
    margin-top: -8rem !important
  }
  .mt-md-n13 {
    margin-top: -10rem !important
  }
  .mt-md-n14 {
    margin-top: -12rem !important
  }
  .mt-md-n15 {
    margin-top: -16rem !important
  }
  .mt-md-n16 {
    margin-top: -25rem !important
  }
  .me-md-n1 {
    margin-right: -.25rem !important
  }
  .me-md-n2 {
    margin-right: -.5rem !important
  }
  .me-md-n3 {
    margin-right: -.75rem !important
  }
  .me-md-n4 {
    margin-right: -1rem !important
  }
  .me-md-n5 {
    margin-right: -1.5rem !important
  }
  .me-md-n6 {
    margin-right: -2rem !important
  }
  .me-md-n7 {
    margin-right: -2.5rem !important
  }
  .me-md-n8 {
    margin-right: -3rem !important
  }
  .me-md-n9 {
    margin-right: -4rem !important
  }
  .me-md-n10 {
    margin-right: -5rem !important
  }
  .me-md-n11 {
    margin-right: -6rem !important
  }
  .me-md-n12 {
    margin-right: -8rem !important
  }
  .me-md-n13 {
    margin-right: -10rem !important
  }
  .me-md-n14 {
    margin-right: -12rem !important
  }
  .me-md-n15 {
    margin-right: -16rem !important
  }
  .me-md-n16 {
    margin-right: -25rem !important
  }
  .mb-md-n1 {
    margin-bottom: -.25rem !important
  }
  .mb-md-n2 {
    margin-bottom: -.5rem !important
  }
  .mb-md-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important
  }
  .mb-md-n5 {
    margin-bottom: -1.5rem !important
  }
  .mb-md-n6 {
    margin-bottom: -2rem !important
  }
  .mb-md-n7 {
    margin-bottom: -2.5rem !important
  }
  .mb-md-n8 {
    margin-bottom: -3rem !important
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important
  }
  .mb-md-n10 {
    margin-bottom: -5rem !important
  }
  .mb-md-n11 {
    margin-bottom: -6rem !important
  }
  .mb-md-n12 {
    margin-bottom: -8rem !important
  }
  .mb-md-n13 {
    margin-bottom: -10rem !important
  }
  .mb-md-n14 {
    margin-bottom: -12rem !important
  }
  .mb-md-n15 {
    margin-bottom: -16rem !important
  }
  .mb-md-n16 {
    margin-bottom: -25rem !important
  }
  .ms-md-n1 {
    margin-left: -.25rem !important
  }
  .ms-md-n2 {
    margin-left: -.5rem !important
  }
  .ms-md-n3 {
    margin-left: -.75rem !important
  }
  .ms-md-n4 {
    margin-left: -1rem !important
  }
  .ms-md-n5 {
    margin-left: -1.5rem !important
  }
  .ms-md-n6 {
    margin-left: -2rem !important
  }
  .ms-md-n7 {
    margin-left: -2.5rem !important
  }
  .ms-md-n8 {
    margin-left: -3rem !important
  }
  .ms-md-n9 {
    margin-left: -4rem !important
  }
  .ms-md-n10 {
    margin-left: -5rem !important
  }
  .ms-md-n11 {
    margin-left: -6rem !important
  }
  .ms-md-n12 {
    margin-left: -8rem !important
  }
  .ms-md-n13 {
    margin-left: -10rem !important
  }
  .ms-md-n14 {
    margin-left: -12rem !important
  }
  .ms-md-n15 {
    margin-left: -16rem !important
  }
  .ms-md-n16 {
    margin-left: -25rem !important
  }
  .p-md-0 {
    padding: 0 !important
  }
  .p-md-1 {
    padding: .25rem !important
  }
  .p-md-2 {
    padding: .5rem !important
  }
  .p-md-3 {
    padding: .75rem !important
  }
  .p-md-4 {
    padding: 1rem !important
  }
  .p-md-5 {
    padding: 1.5rem !important
  }
  .p-md-6 {
    padding: 2rem !important
  }
  .p-md-7 {
    padding: 2.5rem !important
  }
  .p-md-8 {
    padding: 3rem !important
  }
  .p-md-9 {
    padding: 4rem !important
  }
  .p-md-10 {
    padding: 5rem !important
  }
  .p-md-11 {
    padding: 6rem !important
  }
  .p-md-12 {
    padding: 8rem !important
  }
  .p-md-13 {
    padding: 10rem !important
  }
  .p-md-14 {
    padding: 12rem !important
  }
  .p-md-15 {
    padding: 16rem !important
  }
  .p-md-16 {
    padding: 25rem !important
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }
  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
  .px-md-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
  }
  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }
  .px-md-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }
  .px-md-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }
  .px-md-7 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }
  .px-md-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }
  .px-md-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }
  .px-md-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }
  .px-md-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
  }
  .px-md-12 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
  }
  .px-md-13 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
  }
  .px-md-14 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
  }
  .px-md-15 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
  }
  .px-md-16 {
    padding-left: 25rem !important;
    padding-right: 25rem !important
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }
  .py-md-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
  }
  .py-md-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
  }
  .py-md-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
  }
  .py-md-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
  }
  .py-md-5 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
  }
  .py-md-6 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
  }
  .py-md-7 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
  }
  .py-md-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
  }
  .py-md-9 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
  }
  .py-md-10 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
  }
  .py-md-11 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
  }
  .py-md-12 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
  }
  .py-md-13 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
  }
  .py-md-14 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
  }
  .py-md-15 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
  }
  .py-md-16 {
    padding-bottom: 25rem !important;
    padding-top: 25rem !important
  }
  .pt-md-0 {
    padding-top: 0 !important
  }
  .pt-md-1 {
    padding-top: .25rem !important
  }
  .pt-md-2 {
    padding-top: .5rem !important
  }
  .pt-md-3 {
    padding-top: .75rem !important
  }
  .pt-md-4 {
    padding-top: 1rem !important
  }
  .pt-md-5 {
    padding-top: 1.5rem !important
  }
  .pt-md-6 {
    padding-top: 2rem !important
  }
  .pt-md-7 {
    padding-top: 2.5rem !important
  }
  .pt-md-8 {
    padding-top: 3rem !important
  }
  .pt-md-9 {
    padding-top: 4rem !important
  }
  .pt-md-10 {
    padding-top: 5rem !important
  }
  .pt-md-11 {
    padding-top: 6rem !important
  }
  .pt-md-12 {
    padding-top: 8rem !important
  }
  .pt-md-13 {
    padding-top: 10rem !important
  }
  .pt-md-14 {
    padding-top: 12rem !important
  }
  .pt-md-15 {
    padding-top: 16rem !important
  }
  .pt-md-16 {
    padding-top: 25rem !important
  }
  .pe-md-0 {
    padding-right: 0 !important
  }
  .pe-md-1 {
    padding-right: .25rem !important
  }
  .pe-md-2 {
    padding-right: .5rem !important
  }
  .pe-md-3 {
    padding-right: .75rem !important
  }
  .pe-md-4 {
    padding-right: 1rem !important
  }
  .pe-md-5 {
    padding-right: 1.5rem !important
  }
  .pe-md-6 {
    padding-right: 2rem !important
  }
  .pe-md-7 {
    padding-right: 2.5rem !important
  }
  .pe-md-8 {
    padding-right: 3rem !important
  }
  .pe-md-9 {
    padding-right: 4rem !important
  }
  .pe-md-10 {
    padding-right: 5rem !important
  }
  .pe-md-11 {
    padding-right: 6rem !important
  }
  .pe-md-12 {
    padding-right: 8rem !important
  }
  .pe-md-13 {
    padding-right: 10rem !important
  }
  .pe-md-14 {
    padding-right: 12rem !important
  }
  .pe-md-15 {
    padding-right: 16rem !important
  }
  .pe-md-16 {
    padding-right: 25rem !important
  }
  .pb-md-0 {
    padding-bottom: 0 !important
  }
  .pb-md-1 {
    padding-bottom: .25rem !important
  }
  .pb-md-2 {
    padding-bottom: .5rem !important
  }
  .pb-md-3 {
    padding-bottom: .75rem !important
  }
  .pb-md-4 {
    padding-bottom: 1rem !important
  }
  .pb-md-5 {
    padding-bottom: 1.5rem !important
  }
  .pb-md-6 {
    padding-bottom: 2rem !important
  }
  .pb-md-7 {
    padding-bottom: 2.5rem !important
  }
  .pb-md-8 {
    padding-bottom: 3rem !important
  }
  .pb-md-9 {
    padding-bottom: 4rem !important
  }
  .pb-md-10 {
    padding-bottom: 5rem !important
  }
  .pb-md-11 {
    padding-bottom: 6rem !important
  }
  .pb-md-12 {
    padding-bottom: 8rem !important
  }
  .pb-md-13 {
    padding-bottom: 10rem !important
  }
  .pb-md-14 {
    padding-bottom: 12rem !important
  }
  .pb-md-15 {
    padding-bottom: 16rem !important
  }
  .pb-md-16 {
    padding-bottom: 25rem !important
  }
  .ps-md-0 {
    padding-left: 0 !important
  }
  .ps-md-1 {
    padding-left: .25rem !important
  }
  .ps-md-2 {
    padding-left: .5rem !important
  }
  .ps-md-3 {
    padding-left: .75rem !important
  }
  .ps-md-4 {
    padding-left: 1rem !important
  }
  .ps-md-5 {
    padding-left: 1.5rem !important
  }
  .ps-md-6 {
    padding-left: 2rem !important
  }
  .ps-md-7 {
    padding-left: 2.5rem !important
  }
  .ps-md-8 {
    padding-left: 3rem !important
  }
  .ps-md-9 {
    padding-left: 4rem !important
  }
  .ps-md-10 {
    padding-left: 5rem !important
  }
  .ps-md-11 {
    padding-left: 6rem !important
  }
  .ps-md-12 {
    padding-left: 8rem !important
  }
  .ps-md-13 {
    padding-left: 10rem !important
  }
  .ps-md-14 {
    padding-left: 12rem !important
  }
  .ps-md-15 {
    padding-left: 16rem !important
  }
  .ps-md-16 {
    padding-left: 25rem !important
  }
  .text-md-start {
    text-align: left !important
  }
  .text-md-end {
    text-align: right !important
  }
  .text-md-center {
    text-align: center !important
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important
  }
  .float-lg-end {
    float: right !important
  }
  .float-lg-none {
    float: none !important
  }
  .d-lg-inline {
    display: inline !important
  }
  .d-lg-inline-block {
    display: inline-block !important
  }
  .d-lg-block {
    display: block !important
  }
  .d-lg-grid {
    display: grid !important
  }
  .d-lg-table {
    display: table !important
  }
  .d-lg-table-row {
    display: table-row !important
  }
  .d-lg-table-cell {
    display: table-cell !important
  }
  .d-lg-flex {
    display: flex !important
  }
  .d-lg-inline-flex {
    display: inline-flex !important
  }
  .d-lg-none {
    display: none !important
  }
  .position-lg-static {
    position: static !important
  }
  .position-lg-relative {
    position: relative !important
  }
  .position-lg-absolute {
    position: absolute !important
  }
  .position-lg-fixed {
    position: fixed !important
  }
  .position-lg-sticky {
    position: sticky !important
  }
  .border-lg {
    border: 1px solid #f1f4f8 !important
  }
  .border-lg-0 {
    border: 0 !important
  }
  .border-top-lg {
    border-top: 1px solid #f1f4f8 !important
  }
  .border-top-lg-0 {
    border-top: 0 !important
  }
  .border-right-lg {
    border-right: 1px solid #f1f4f8 !important
  }
  .border-right-lg-0 {
    border-right: 0 !important
  }
  .border-bottom-lg {
    border-bottom: 1px solid #f1f4f8 !important
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important
  }
  .border-left-lg {
    border-left: 1px solid #f1f4f8 !important
  }
  .border-left-lg-0 {
    border-left: 0 !important
  }
  .w-lg-25 {
    width: 25% !important
  }
  .w-lg-50 {
    width: 50% !important
  }
  .w-lg-75 {
    width: 75% !important
  }
  .w-lg-100 {
    width: 100% !important
  }
  .w-lg-110 {
    width: 110% !important
  }
  .w-lg-120 {
    width: 120% !important
  }
  .w-lg-130 {
    width: 130% !important
  }
  .w-lg-140 {
    width: 140% !important
  }
  .w-lg-150 {
    width: 150% !important
  }
  .w-lg-auto {
    width: auto !important
  }
  .mw-lg-25 {
    max-width: 25% !important
  }
  .mw-lg-50 {
    max-width: 50% !important
  }
  .mw-lg-75 {
    max-width: 75% !important
  }
  .mw-lg-100 {
    max-width: 100% !important
  }
  .mw-lg-110 {
    max-width: 110% !important
  }
  .mw-lg-120 {
    max-width: 120% !important
  }
  .mw-lg-130 {
    max-width: 130% !important
  }
  .mw-lg-140 {
    max-width: 140% !important
  }
  .mw-lg-150 {
    max-width: 150% !important
  }
  .vw-lg-25 {
    width: 25vw !important
  }
  .vw-lg-50 {
    width: 50vw !important
  }
  .vw-lg-75 {
    width: 75vw !important
  }
  .vw-lg-100 {
    width: 100vw !important
  }
  .vw-lg-110 {
    width: 110vw !important
  }
  .vw-lg-120 {
    width: 120vw !important
  }
  .vw-lg-130 {
    width: 130vw !important
  }
  .vw-lg-140 {
    width: 140vw !important
  }
  .vw-lg-150 {
    width: 150vw !important
  }
  .h-lg-25 {
    height: 25% !important
  }
  .h-lg-50 {
    height: 50% !important
  }
  .h-lg-75 {
    height: 75% !important
  }
  .h-lg-100 {
    height: 100% !important
  }
  .h-lg-110 {
    height: 110% !important
  }
  .h-lg-120 {
    height: 120% !important
  }
  .h-lg-130 {
    height: 130% !important
  }
  .h-lg-140 {
    height: 140% !important
  }
  .h-lg-150 {
    height: 150% !important
  }
  .h-lg-auto {
    height: auto !important
  }
  .vh-lg-25 {
    height: 25vh !important
  }
  .vh-lg-50 {
    height: 50vh !important
  }
  .vh-lg-75 {
    height: 75vh !important
  }
  .vh-lg-100 {
    height: 100vh !important
  }
  .vh-lg-110 {
    height: 110vh !important
  }
  .vh-lg-120 {
    height: 120vh !important
  }
  .vh-lg-130 {
    height: 130vh !important
  }
  .vh-lg-140 {
    height: 140vh !important
  }
  .vh-lg-150 {
    height: 150vh !important
  }
  .flex-lg-fill {
    flex: 1 1 auto !important
  }
  .flex-lg-row {
    flex-direction: row !important
  }
  .flex-lg-column {
    flex-direction: column !important
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .gap-lg-0 {
    gap: 0 !important
  }
  .gap-lg-1 {
    gap: .25rem !important
  }
  .gap-lg-2 {
    gap: .5rem !important
  }
  .gap-lg-3 {
    gap: .75rem !important
  }
  .gap-lg-4 {
    gap: 1rem !important
  }
  .gap-lg-5 {
    gap: 1.5rem !important
  }
  .gap-lg-6 {
    gap: 2rem !important
  }
  .gap-lg-7 {
    gap: 2.5rem !important
  }
  .gap-lg-8 {
    gap: 3rem !important
  }
  .gap-lg-9 {
    gap: 4rem !important
  }
  .gap-lg-10 {
    gap: 5rem !important
  }
  .gap-lg-11 {
    gap: 6rem !important
  }
  .gap-lg-12 {
    gap: 8rem !important
  }
  .gap-lg-13 {
    gap: 10rem !important
  }
  .gap-lg-14 {
    gap: 12rem !important
  }
  .gap-lg-15 {
    gap: 16rem !important
  }
  .gap-lg-16 {
    gap: 25rem !important
  }
  .justify-content-lg-start {
    justify-content: flex-start !important
  }
  .justify-content-lg-end {
    justify-content: flex-end !important
  }
  .justify-content-lg-center {
    justify-content: center !important
  }
  .justify-content-lg-between {
    justify-content: space-between !important
  }
  .justify-content-lg-around {
    justify-content: space-around !important
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important
  }
  .align-items-lg-start {
    align-items: flex-start !important
  }
  .align-items-lg-end {
    align-items: flex-end !important
  }
  .align-items-lg-center {
    align-items: center !important
  }
  .align-items-lg-baseline {
    align-items: baseline !important
  }
  .align-items-lg-stretch {
    align-items: stretch !important
  }
  .align-content-lg-start {
    align-content: flex-start !important
  }
  .align-content-lg-end {
    align-content: flex-end !important
  }
  .align-content-lg-center {
    align-content: center !important
  }
  .align-content-lg-between {
    align-content: space-between !important
  }
  .align-content-lg-around {
    align-content: space-around !important
  }
  .align-content-lg-stretch {
    align-content: stretch !important
  }
  .align-self-lg-auto {
    align-self: auto !important
  }
  .align-self-lg-start {
    align-self: flex-start !important
  }
  .align-self-lg-end {
    align-self: flex-end !important
  }
  .align-self-lg-center {
    align-self: center !important
  }
  .align-self-lg-baseline {
    align-self: baseline !important
  }
  .align-self-lg-stretch {
    align-self: stretch !important
  }
  .order-lg-first {
    order: -1 !important
  }
  .order-lg-0 {
    order: 0 !important
  }
  .order-lg-1 {
    order: 1 !important
  }
  .order-lg-2 {
    order: 2 !important
  }
  .order-lg-3 {
    order: 3 !important
  }
  .order-lg-4 {
    order: 4 !important
  }
  .order-lg-5 {
    order: 5 !important
  }
  .order-lg-last {
    order: 6 !important
  }
  .m-lg-0 {
    margin: 0 !important
  }
  .m-lg-1 {
    margin: .25rem !important
  }
  .m-lg-2 {
    margin: .5rem !important
  }
  .m-lg-3 {
    margin: .75rem !important
  }
  .m-lg-4 {
    margin: 1rem !important
  }
  .m-lg-5 {
    margin: 1.5rem !important
  }
  .m-lg-6 {
    margin: 2rem !important
  }
  .m-lg-7 {
    margin: 2.5rem !important
  }
  .m-lg-8 {
    margin: 3rem !important
  }
  .m-lg-9 {
    margin: 4rem !important
  }
  .m-lg-10 {
    margin: 5rem !important
  }
  .m-lg-11 {
    margin: 6rem !important
  }
  .m-lg-12 {
    margin: 8rem !important
  }
  .m-lg-13 {
    margin: 10rem !important
  }
  .m-lg-14 {
    margin: 12rem !important
  }
  .m-lg-15 {
    margin: 16rem !important
  }
  .m-lg-16 {
    margin: 25rem !important
  }
  .m-lg-auto {
    margin: auto !important
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
  }
  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
  }
  .mx-lg-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
  }
  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }
  .mx-lg-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
  }
  .mx-lg-6 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }
  .mx-lg-7 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
  }
  .mx-lg-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }
  .mx-lg-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }
  .mx-lg-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }
  .mx-lg-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
  }
  .mx-lg-12 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
  }
  .mx-lg-13 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
  }
  .mx-lg-14 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
  }
  .mx-lg-15 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }
  .mx-lg-16 {
    margin-left: 25rem !important;
    margin-right: 25rem !important
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
  }
  .my-lg-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
  }
  .my-lg-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
  }
  .my-lg-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
  }
  .my-lg-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
  }
  .my-lg-5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
  }
  .my-lg-6 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
  }
  .my-lg-7 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
  }
  .my-lg-8 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
  }
  .my-lg-9 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
  }
  .my-lg-10 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
  }
  .my-lg-11 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
  }
  .my-lg-12 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
  }
  .my-lg-13 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
  }
  .my-lg-14 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
  }
  .my-lg-15 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
  }
  .my-lg-16 {
    margin-bottom: 25rem !important;
    margin-top: 25rem !important
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
  }
  .mt-lg-0 {
    margin-top: 0 !important
  }
  .mt-lg-1 {
    margin-top: .25rem !important
  }
  .mt-lg-2 {
    margin-top: .5rem !important
  }
  .mt-lg-3 {
    margin-top: .75rem !important
  }
  .mt-lg-4 {
    margin-top: 1rem !important
  }
  .mt-lg-5 {
    margin-top: 1.5rem !important
  }
  .mt-lg-6 {
    margin-top: 2rem !important
  }
  .mt-lg-7 {
    margin-top: 2.5rem !important
  }
  .mt-lg-8 {
    margin-top: 3rem !important
  }
  .mt-lg-9 {
    margin-top: 4rem !important
  }
  .mt-lg-10 {
    margin-top: 5rem !important
  }
  .mt-lg-11 {
    margin-top: 6rem !important
  }
  .mt-lg-12 {
    margin-top: 8rem !important
  }
  .mt-lg-13 {
    margin-top: 10rem !important
  }
  .mt-lg-14 {
    margin-top: 12rem !important
  }
  .mt-lg-15 {
    margin-top: 16rem !important
  }
  .mt-lg-16 {
    margin-top: 25rem !important
  }
  .mt-lg-auto {
    margin-top: auto !important
  }
  .me-lg-0 {
    margin-right: 0 !important
  }
  .me-lg-1 {
    margin-right: .25rem !important
  }
  .me-lg-2 {
    margin-right: .5rem !important
  }
  .me-lg-3 {
    margin-right: .75rem !important
  }
  .me-lg-4 {
    margin-right: 1rem !important
  }
  .me-lg-5 {
    margin-right: 1.5rem !important
  }
  .me-lg-6 {
    margin-right: 2rem !important
  }
  .me-lg-7 {
    margin-right: 2.5rem !important
  }
  .me-lg-8 {
    margin-right: 3rem !important
  }
  .me-lg-9 {
    margin-right: 4rem !important
  }
  .me-lg-10 {
    margin-right: 5rem !important
  }
  .me-lg-11 {
    margin-right: 6rem !important
  }
  .me-lg-12 {
    margin-right: 8rem !important
  }
  .me-lg-13 {
    margin-right: 10rem !important
  }
  .me-lg-14 {
    margin-right: 12rem !important
  }
  .me-lg-15 {
    margin-right: 16rem !important
  }
  .me-lg-16 {
    margin-right: 25rem !important
  }
  .me-lg-auto {
    margin-right: auto !important
  }
  .mb-lg-0 {
    margin-bottom: 0 !important
  }
  .mb-lg-1 {
    margin-bottom: .25rem !important
  }
  .mb-lg-2 {
    margin-bottom: .5rem !important
  }
  .mb-lg-3 {
    margin-bottom: .75rem !important
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important
  }
  .mb-lg-5 {
    margin-bottom: 1.5rem !important
  }
  .mb-lg-6 {
    margin-bottom: 2rem !important
  }
  .mb-lg-7 {
    margin-bottom: 2.5rem !important
  }
  .mb-lg-8 {
    margin-bottom: 3rem !important
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important
  }
  .mb-lg-10 {
    margin-bottom: 5rem !important
  }
  .mb-lg-11 {
    margin-bottom: 6rem !important
  }
  .mb-lg-12 {
    margin-bottom: 8rem !important
  }
  .mb-lg-13 {
    margin-bottom: 10rem !important
  }
  .mb-lg-14 {
    margin-bottom: 12rem !important
  }
  .mb-lg-15 {
    margin-bottom: 16rem !important
  }
  .mb-lg-16 {
    margin-bottom: 25rem !important
  }
  .mb-lg-auto {
    margin-bottom: auto !important
  }
  .ms-lg-0 {
    margin-left: 0 !important
  }
  .ms-lg-1 {
    margin-left: .25rem !important
  }
  .ms-lg-2 {
    margin-left: .5rem !important
  }
  .ms-lg-3 {
    margin-left: .75rem !important
  }
  .ms-lg-4 {
    margin-left: 1rem !important
  }
  .ms-lg-5 {
    margin-left: 1.5rem !important
  }
  .ms-lg-6 {
    margin-left: 2rem !important
  }
  .ms-lg-7 {
    margin-left: 2.5rem !important
  }
  .ms-lg-8 {
    margin-left: 3rem !important
  }
  .ms-lg-9 {
    margin-left: 4rem !important
  }
  .ms-lg-10 {
    margin-left: 5rem !important
  }
  .ms-lg-11 {
    margin-left: 6rem !important
  }
  .ms-lg-12 {
    margin-left: 8rem !important
  }
  .ms-lg-13 {
    margin-left: 10rem !important
  }
  .ms-lg-14 {
    margin-left: 12rem !important
  }
  .ms-lg-15 {
    margin-left: 16rem !important
  }
  .ms-lg-16 {
    margin-left: 25rem !important
  }
  .ms-lg-auto {
    margin-left: auto !important
  }
  .m-lg-n1 {
    margin: -.25rem !important
  }
  .m-lg-n2 {
    margin: -.5rem !important
  }
  .m-lg-n3 {
    margin: -.75rem !important
  }
  .m-lg-n4 {
    margin: -1rem !important
  }
  .m-lg-n5 {
    margin: -1.5rem !important
  }
  .m-lg-n6 {
    margin: -2rem !important
  }
  .m-lg-n7 {
    margin: -2.5rem !important
  }
  .m-lg-n8 {
    margin: -3rem !important
  }
  .m-lg-n9 {
    margin: -4rem !important
  }
  .m-lg-n10 {
    margin: -5rem !important
  }
  .m-lg-n11 {
    margin: -6rem !important
  }
  .m-lg-n12 {
    margin: -8rem !important
  }
  .m-lg-n13 {
    margin: -10rem !important
  }
  .m-lg-n14 {
    margin: -12rem !important
  }
  .m-lg-n15 {
    margin: -16rem !important
  }
  .m-lg-n16 {
    margin: -25rem !important
  }
  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
  }
  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
  }
  .mx-lg-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
  }
  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }
  .mx-lg-n5 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
  }
  .mx-lg-n6 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }
  .mx-lg-n7 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
  }
  .mx-lg-n8 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }
  .mx-lg-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }
  .mx-lg-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }
  .mx-lg-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
  }
  .mx-lg-n12 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
  }
  .mx-lg-n13 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
  }
  .mx-lg-n14 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
  }
  .mx-lg-n15 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
  }
  .mx-lg-n16 {
    margin-left: -25rem !important;
    margin-right: -25rem !important
  }
  .my-lg-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
  }
  .my-lg-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
  }
  .my-lg-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
  }
  .my-lg-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
  }
  .my-lg-n5 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
  }
  .my-lg-n6 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
  }
  .my-lg-n7 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
  }
  .my-lg-n8 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
  }
  .my-lg-n9 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
  }
  .my-lg-n10 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
  }
  .my-lg-n11 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
  }
  .my-lg-n12 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
  }
  .my-lg-n13 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
  }
  .my-lg-n14 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
  }
  .my-lg-n15 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
  }
  .my-lg-n16 {
    margin-bottom: -25rem !important;
    margin-top: -25rem !important
  }
  .mt-lg-n1 {
    margin-top: -.25rem !important
  }
  .mt-lg-n2 {
    margin-top: -.5rem !important
  }
  .mt-lg-n3 {
    margin-top: -.75rem !important
  }
  .mt-lg-n4 {
    margin-top: -1rem !important
  }
  .mt-lg-n5 {
    margin-top: -1.5rem !important
  }
  .mt-lg-n6 {
    margin-top: -2rem !important
  }
  .mt-lg-n7 {
    margin-top: -2.5rem !important
  }
  .mt-lg-n8 {
    margin-top: -3rem !important
  }
  .mt-lg-n9 {
    margin-top: -4rem !important
  }
  .mt-lg-n10 {
    margin-top: -5rem !important
  }
  .mt-lg-n11 {
    margin-top: -6rem !important
  }
  .mt-lg-n12 {
    margin-top: -8rem !important
  }
  .mt-lg-n13 {
    margin-top: -10rem !important
  }
  .mt-lg-n14 {
    margin-top: -12rem !important
  }
  .mt-lg-n15 {
    margin-top: -16rem !important
  }
  .mt-lg-n16 {
    margin-top: -25rem !important
  }
  .me-lg-n1 {
    margin-right: -.25rem !important
  }
  .me-lg-n2 {
    margin-right: -.5rem !important
  }
  .me-lg-n3 {
    margin-right: -.75rem !important
  }
  .me-lg-n4 {
    margin-right: -1rem !important
  }
  .me-lg-n5 {
    margin-right: -1.5rem !important
  }
  .me-lg-n6 {
    margin-right: -2rem !important
  }
  .me-lg-n7 {
    margin-right: -2.5rem !important
  }
  .me-lg-n8 {
    margin-right: -3rem !important
  }
  .me-lg-n9 {
    margin-right: -4rem !important
  }
  .me-lg-n10 {
    margin-right: -5rem !important
  }
  .me-lg-n11 {
    margin-right: -6rem !important
  }
  .me-lg-n12 {
    margin-right: -8rem !important
  }
  .me-lg-n13 {
    margin-right: -10rem !important
  }
  .me-lg-n14 {
    margin-right: -12rem !important
  }
  .me-lg-n15 {
    margin-right: -16rem !important
  }
  .me-lg-n16 {
    margin-right: -25rem !important
  }
  .mb-lg-n1 {
    margin-bottom: -.25rem !important
  }
  .mb-lg-n2 {
    margin-bottom: -.5rem !important
  }
  .mb-lg-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important
  }
  .mb-lg-n5 {
    margin-bottom: -1.5rem !important
  }
  .mb-lg-n6 {
    margin-bottom: -2rem !important
  }
  .mb-lg-n7 {
    margin-bottom: -2.5rem !important
  }
  .mb-lg-n8 {
    margin-bottom: -3rem !important
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important
  }
  .mb-lg-n10 {
    margin-bottom: -5rem !important
  }
  .mb-lg-n11 {
    margin-bottom: -6rem !important
  }
  .mb-lg-n12 {
    margin-bottom: -8rem !important
  }
  .mb-lg-n13 {
    margin-bottom: -10rem !important
  }
  .mb-lg-n14 {
    margin-bottom: -12rem !important
  }
  .mb-lg-n15 {
    margin-bottom: -16rem !important
  }
  .mb-lg-n16 {
    margin-bottom: -25rem !important
  }
  .ms-lg-n1 {
    margin-left: -.25rem !important
  }
  .ms-lg-n2 {
    margin-left: -.5rem !important
  }
  .ms-lg-n3 {
    margin-left: -.75rem !important
  }
  .ms-lg-n4 {
    margin-left: -1rem !important
  }
  .ms-lg-n5 {
    margin-left: -1.5rem !important
  }
  .ms-lg-n6 {
    margin-left: -2rem !important
  }
  .ms-lg-n7 {
    margin-left: -2.5rem !important
  }
  .ms-lg-n8 {
    margin-left: -3rem !important
  }
  .ms-lg-n9 {
    margin-left: -4rem !important
  }
  .ms-lg-n10 {
    margin-left: -5rem !important
  }
  .ms-lg-n11 {
    margin-left: -6rem !important
  }
  .ms-lg-n12 {
    margin-left: -8rem !important
  }
  .ms-lg-n13 {
    margin-left: -10rem !important
  }
  .ms-lg-n14 {
    margin-left: -12rem !important
  }
  .ms-lg-n15 {
    margin-left: -16rem !important
  }
  .ms-lg-n16 {
    margin-left: -25rem !important
  }
  .p-lg-0 {
    padding: 0 !important
  }
  .p-lg-1 {
    padding: .25rem !important
  }
  .p-lg-2 {
    padding: .5rem !important
  }
  .p-lg-3 {
    padding: .75rem !important
  }
  .p-lg-4 {
    padding: 1rem !important
  }
  .p-lg-5 {
    padding: 1.5rem !important
  }
  .p-lg-6 {
    padding: 2rem !important
  }
  .p-lg-7 {
    padding: 2.5rem !important
  }
  .p-lg-8 {
    padding: 3rem !important
  }
  .p-lg-9 {
    padding: 4rem !important
  }
  .p-lg-10 {
    padding: 5rem !important
  }
  .p-lg-11 {
    padding: 6rem !important
  }
  .p-lg-12 {
    padding: 8rem !important
  }
  .p-lg-13 {
    padding: 10rem !important
  }
  .p-lg-14 {
    padding: 12rem !important
  }
  .p-lg-15 {
    padding: 16rem !important
  }
  .p-lg-16 {
    padding: 25rem !important
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }
  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
  .px-lg-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
  }
  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }
  .px-lg-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }
  .px-lg-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }
  .px-lg-7 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }
  .px-lg-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }
  .px-lg-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }
  .px-lg-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
  }
  .px-lg-12 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
  }
  .px-lg-13 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
  }
  .px-lg-14 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
  }
  .px-lg-15 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
  }
  .px-lg-16 {
    padding-left: 25rem !important;
    padding-right: 25rem !important
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }
  .py-lg-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
  }
  .py-lg-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
  }
  .py-lg-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
  }
  .py-lg-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
  }
  .py-lg-5 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
  }
  .py-lg-6 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
  }
  .py-lg-7 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
  }
  .py-lg-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
  }
  .py-lg-9 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
  }
  .py-lg-10 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
  }
  .py-lg-11 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
  }
  .py-lg-12 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
  }
  .py-lg-13 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
  }
  .py-lg-14 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
  }
  .py-lg-15 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
  }
  .py-lg-16 {
    padding-bottom: 25rem !important;
    padding-top: 25rem !important
  }
  .pt-lg-0 {
    padding-top: 0 !important
  }
  .pt-lg-1 {
    padding-top: .25rem !important
  }
  .pt-lg-2 {
    padding-top: .5rem !important
  }
  .pt-lg-3 {
    padding-top: .75rem !important
  }
  .pt-lg-4 {
    padding-top: 1rem !important
  }
  .pt-lg-5 {
    padding-top: 1.5rem !important
  }
  .pt-lg-6 {
    padding-top: 2rem !important
  }
  .pt-lg-7 {
    padding-top: 2.5rem !important
  }
  .pt-lg-8 {
    padding-top: 3rem !important
  }
  .pt-lg-9 {
    padding-top: 4rem !important
  }
  .pt-lg-10 {
    padding-top: 5rem !important
  }
  .pt-lg-11 {
    padding-top: 6rem !important
  }
  .pt-lg-12 {
    padding-top: 8rem !important
  }
  .pt-lg-13 {
    padding-top: 10rem !important
  }
  .pt-lg-14 {
    padding-top: 12rem !important
  }
  .pt-lg-15 {
    padding-top: 16rem !important
  }
  .pt-lg-16 {
    padding-top: 25rem !important
  }
  .pe-lg-0 {
    padding-right: 0 !important
  }
  .pe-lg-1 {
    padding-right: .25rem !important
  }
  .pe-lg-2 {
    padding-right: .5rem !important
  }
  .pe-lg-3 {
    padding-right: .75rem !important
  }
  .pe-lg-4 {
    padding-right: 1rem !important
  }
  .pe-lg-5 {
    padding-right: 1.5rem !important
  }
  .pe-lg-6 {
    padding-right: 2rem !important
  }
  .pe-lg-7 {
    padding-right: 2.5rem !important
  }
  .pe-lg-8 {
    padding-right: 3rem !important
  }
  .pe-lg-9 {
    padding-right: 4rem !important
  }
  .pe-lg-10 {
    padding-right: 5rem !important
  }
  .pe-lg-11 {
    padding-right: 6rem !important
  }
  .pe-lg-12 {
    padding-right: 8rem !important
  }
  .pe-lg-13 {
    padding-right: 10rem !important
  }
  .pe-lg-14 {
    padding-right: 12rem !important
  }
  .pe-lg-15 {
    padding-right: 16rem !important
  }
  .pe-lg-16 {
    padding-right: 25rem !important
  }
  .pb-lg-0 {
    padding-bottom: 0 !important
  }
  .pb-lg-1 {
    padding-bottom: .25rem !important
  }
  .pb-lg-2 {
    padding-bottom: .5rem !important
  }
  .pb-lg-3 {
    padding-bottom: .75rem !important
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important
  }
  .pb-lg-5 {
    padding-bottom: 1.5rem !important
  }
  .pb-lg-6 {
    padding-bottom: 2rem !important
  }
  .pb-lg-7 {
    padding-bottom: 2.5rem !important
  }
  .pb-lg-8 {
    padding-bottom: 3rem !important
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important
  }
  .pb-lg-10 {
    padding-bottom: 5rem !important
  }
  .pb-lg-11 {
    padding-bottom: 6rem !important
  }
  .pb-lg-12 {
    padding-bottom: 8rem !important
  }
  .pb-lg-13 {
    padding-bottom: 10rem !important
  }
  .pb-lg-14 {
    padding-bottom: 12rem !important
  }
  .pb-lg-15 {
    padding-bottom: 16rem !important
  }
  .pb-lg-16 {
    padding-bottom: 25rem !important
  }
  .ps-lg-0 {
    padding-left: 0 !important
  }
  .ps-lg-1 {
    padding-left: .25rem !important
  }
  .ps-lg-2 {
    padding-left: .5rem !important
  }
  .ps-lg-3 {
    padding-left: .75rem !important
  }
  .ps-lg-4 {
    padding-left: 1rem !important
  }
  .ps-lg-5 {
    padding-left: 1.5rem !important
  }
  .ps-lg-6 {
    padding-left: 2rem !important
  }
  .ps-lg-7 {
    padding-left: 2.5rem !important
  }
  .ps-lg-8 {
    padding-left: 3rem !important
  }
  .ps-lg-9 {
    padding-left: 4rem !important
  }
  .ps-lg-10 {
    padding-left: 5rem !important
  }
  .ps-lg-11 {
    padding-left: 6rem !important
  }
  .ps-lg-12 {
    padding-left: 8rem !important
  }
  .ps-lg-13 {
    padding-left: 10rem !important
  }
  .ps-lg-14 {
    padding-left: 12rem !important
  }
  .ps-lg-15 {
    padding-left: 16rem !important
  }
  .ps-lg-16 {
    padding-left: 25rem !important
  }
  .text-lg-start {
    text-align: left !important
  }
  .text-lg-end {
    text-align: right !important
  }
  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important
  }
  .float-xl-end {
    float: right !important
  }
  .float-xl-none {
    float: none !important
  }
  .d-xl-inline {
    display: inline !important
  }
  .d-xl-inline-block {
    display: inline-block !important
  }
  .d-xl-block {
    display: block !important
  }
  .d-xl-grid {
    display: grid !important
  }
  .d-xl-table {
    display: table !important
  }
  .d-xl-table-row {
    display: table-row !important
  }
  .d-xl-table-cell {
    display: table-cell !important
  }
  .d-xl-flex {
    display: flex !important
  }
  .d-xl-inline-flex {
    display: inline-flex !important
  }
  .d-xl-none {
    display: none !important
  }
  .position-xl-static {
    position: static !important
  }
  .position-xl-relative {
    position: relative !important
  }
  .position-xl-absolute {
    position: absolute !important
  }
  .position-xl-fixed {
    position: fixed !important
  }
  .position-xl-sticky {
    position: sticky !important
  }
  .border-xl {
    border: 1px solid #f1f4f8 !important
  }
  .border-xl-0 {
    border: 0 !important
  }
  .border-top-xl {
    border-top: 1px solid #f1f4f8 !important
  }
  .border-top-xl-0 {
    border-top: 0 !important
  }
  .border-right-xl {
    border-right: 1px solid #f1f4f8 !important
  }
  .border-right-xl-0 {
    border-right: 0 !important
  }
  .border-bottom-xl {
    border-bottom: 1px solid #f1f4f8 !important
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important
  }
  .border-left-xl {
    border-left: 1px solid #f1f4f8 !important
  }
  .border-left-xl-0 {
    border-left: 0 !important
  }
  .w-xl-25 {
    width: 25% !important
  }
  .w-xl-50 {
    width: 50% !important
  }
  .w-xl-75 {
    width: 75% !important
  }
  .w-xl-100 {
    width: 100% !important
  }
  .w-xl-110 {
    width: 110% !important
  }
  .w-xl-120 {
    width: 120% !important
  }
  .w-xl-130 {
    width: 130% !important
  }
  .w-xl-140 {
    width: 140% !important
  }
  .w-xl-150 {
    width: 150% !important
  }
  .w-xl-auto {
    width: auto !important
  }
  .mw-xl-25 {
    max-width: 25% !important
  }
  .mw-xl-50 {
    max-width: 50% !important
  }
  .mw-xl-75 {
    max-width: 75% !important
  }
  .mw-xl-100 {
    max-width: 100% !important
  }
  .mw-xl-110 {
    max-width: 110% !important
  }
  .mw-xl-120 {
    max-width: 120% !important
  }
  .mw-xl-130 {
    max-width: 130% !important
  }
  .mw-xl-140 {
    max-width: 140% !important
  }
  .mw-xl-150 {
    max-width: 150% !important
  }
  .vw-xl-25 {
    width: 25vw !important
  }
  .vw-xl-50 {
    width: 50vw !important
  }
  .vw-xl-75 {
    width: 75vw !important
  }
  .vw-xl-100 {
    width: 100vw !important
  }
  .vw-xl-110 {
    width: 110vw !important
  }
  .vw-xl-120 {
    width: 120vw !important
  }
  .vw-xl-130 {
    width: 130vw !important
  }
  .vw-xl-140 {
    width: 140vw !important
  }
  .vw-xl-150 {
    width: 150vw !important
  }
  .h-xl-25 {
    height: 25% !important
  }
  .h-xl-50 {
    height: 50% !important
  }
  .h-xl-75 {
    height: 75% !important
  }
  .h-xl-100 {
    height: 100% !important
  }
  .h-xl-110 {
    height: 110% !important
  }
  .h-xl-120 {
    height: 120% !important
  }
  .h-xl-130 {
    height: 130% !important
  }
  .h-xl-140 {
    height: 140% !important
  }
  .h-xl-150 {
    height: 150% !important
  }
  .h-xl-auto {
    height: auto !important
  }
  .vh-xl-25 {
    height: 25vh !important
  }
  .vh-xl-50 {
    height: 50vh !important
  }
  .vh-xl-75 {
    height: 75vh !important
  }
  .vh-xl-100 {
    height: 100vh !important
  }
  .vh-xl-110 {
    height: 110vh !important
  }
  .vh-xl-120 {
    height: 120vh !important
  }
  .vh-xl-130 {
    height: 130vh !important
  }
  .vh-xl-140 {
    height: 140vh !important
  }
  .vh-xl-150 {
    height: 150vh !important
  }
  .flex-xl-fill {
    flex: 1 1 auto !important
  }
  .flex-xl-row {
    flex-direction: row !important
  }
  .flex-xl-column {
    flex-direction: column !important
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .gap-xl-0 {
    gap: 0 !important
  }
  .gap-xl-1 {
    gap: .25rem !important
  }
  .gap-xl-2 {
    gap: .5rem !important
  }
  .gap-xl-3 {
    gap: .75rem !important
  }
  .gap-xl-4 {
    gap: 1rem !important
  }
  .gap-xl-5 {
    gap: 1.5rem !important
  }
  .gap-xl-6 {
    gap: 2rem !important
  }
  .gap-xl-7 {
    gap: 2.5rem !important
  }
  .gap-xl-8 {
    gap: 3rem !important
  }
  .gap-xl-9 {
    gap: 4rem !important
  }
  .gap-xl-10 {
    gap: 5rem !important
  }
  .gap-xl-11 {
    gap: 6rem !important
  }
  .gap-xl-12 {
    gap: 8rem !important
  }
  .gap-xl-13 {
    gap: 10rem !important
  }
  .gap-xl-14 {
    gap: 12rem !important
  }
  .gap-xl-15 {
    gap: 16rem !important
  }
  .gap-xl-16 {
    gap: 25rem !important
  }
  .justify-content-xl-start {
    justify-content: flex-start !important
  }
  .justify-content-xl-end {
    justify-content: flex-end !important
  }
  .justify-content-xl-center {
    justify-content: center !important
  }
  .justify-content-xl-between {
    justify-content: space-between !important
  }
  .justify-content-xl-around {
    justify-content: space-around !important
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important
  }
  .align-items-xl-start {
    align-items: flex-start !important
  }
  .align-items-xl-end {
    align-items: flex-end !important
  }
  .align-items-xl-center {
    align-items: center !important
  }
  .align-items-xl-baseline {
    align-items: baseline !important
  }
  .align-items-xl-stretch {
    align-items: stretch !important
  }
  .align-content-xl-start {
    align-content: flex-start !important
  }
  .align-content-xl-end {
    align-content: flex-end !important
  }
  .align-content-xl-center {
    align-content: center !important
  }
  .align-content-xl-between {
    align-content: space-between !important
  }
  .align-content-xl-around {
    align-content: space-around !important
  }
  .align-content-xl-stretch {
    align-content: stretch !important
  }
  .align-self-xl-auto {
    align-self: auto !important
  }
  .align-self-xl-start {
    align-self: flex-start !important
  }
  .align-self-xl-end {
    align-self: flex-end !important
  }
  .align-self-xl-center {
    align-self: center !important
  }
  .align-self-xl-baseline {
    align-self: baseline !important
  }
  .align-self-xl-stretch {
    align-self: stretch !important
  }
  .order-xl-first {
    order: -1 !important
  }
  .order-xl-0 {
    order: 0 !important
  }
  .order-xl-1 {
    order: 1 !important
  }
  .order-xl-2 {
    order: 2 !important
  }
  .order-xl-3 {
    order: 3 !important
  }
  .order-xl-4 {
    order: 4 !important
  }
  .order-xl-5 {
    order: 5 !important
  }
  .order-xl-last {
    order: 6 !important
  }
  .m-xl-0 {
    margin: 0 !important
  }
  .m-xl-1 {
    margin: .25rem !important
  }
  .m-xl-2 {
    margin: .5rem !important
  }
  .m-xl-3 {
    margin: .75rem !important
  }
  .m-xl-4 {
    margin: 1rem !important
  }
  .m-xl-5 {
    margin: 1.5rem !important
  }
  .m-xl-6 {
    margin: 2rem !important
  }
  .m-xl-7 {
    margin: 2.5rem !important
  }
  .m-xl-8 {
    margin: 3rem !important
  }
  .m-xl-9 {
    margin: 4rem !important
  }
  .m-xl-10 {
    margin: 5rem !important
  }
  .m-xl-11 {
    margin: 6rem !important
  }
  .m-xl-12 {
    margin: 8rem !important
  }
  .m-xl-13 {
    margin: 10rem !important
  }
  .m-xl-14 {
    margin: 12rem !important
  }
  .m-xl-15 {
    margin: 16rem !important
  }
  .m-xl-16 {
    margin: 25rem !important
  }
  .m-xl-auto {
    margin: auto !important
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
  }
  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
  }
  .mx-xl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
  }
  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }
  .mx-xl-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
  }
  .mx-xl-6 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }
  .mx-xl-7 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
  }
  .mx-xl-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }
  .mx-xl-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }
  .mx-xl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }
  .mx-xl-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
  }
  .mx-xl-12 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
  }
  .mx-xl-13 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
  }
  .mx-xl-14 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
  }
  .mx-xl-15 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }
  .mx-xl-16 {
    margin-left: 25rem !important;
    margin-right: 25rem !important
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
  }
  .my-xl-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
  }
  .my-xl-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
  }
  .my-xl-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
  }
  .my-xl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
  }
  .my-xl-5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
  }
  .my-xl-6 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
  }
  .my-xl-7 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
  }
  .my-xl-8 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
  }
  .my-xl-9 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
  }
  .my-xl-10 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
  }
  .my-xl-11 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
  }
  .my-xl-12 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
  }
  .my-xl-13 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
  }
  .my-xl-14 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
  }
  .my-xl-15 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
  }
  .my-xl-16 {
    margin-bottom: 25rem !important;
    margin-top: 25rem !important
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
  }
  .mt-xl-0 {
    margin-top: 0 !important
  }
  .mt-xl-1 {
    margin-top: .25rem !important
  }
  .mt-xl-2 {
    margin-top: .5rem !important
  }
  .mt-xl-3 {
    margin-top: .75rem !important
  }
  .mt-xl-4 {
    margin-top: 1rem !important
  }
  .mt-xl-5 {
    margin-top: 1.5rem !important
  }
  .mt-xl-6 {
    margin-top: 2rem !important
  }
  .mt-xl-7 {
    margin-top: 2.5rem !important
  }
  .mt-xl-8 {
    margin-top: 3rem !important
  }
  .mt-xl-9 {
    margin-top: 4rem !important
  }
  .mt-xl-10 {
    margin-top: 5rem !important
  }
  .mt-xl-11 {
    margin-top: 6rem !important
  }
  .mt-xl-12 {
    margin-top: 8rem !important
  }
  .mt-xl-13 {
    margin-top: 10rem !important
  }
  .mt-xl-14 {
    margin-top: 12rem !important
  }
  .mt-xl-15 {
    margin-top: 16rem !important
  }
  .mt-xl-16 {
    margin-top: 25rem !important
  }
  .mt-xl-auto {
    margin-top: auto !important
  }
  .me-xl-0 {
    margin-right: 0 !important
  }
  .me-xl-1 {
    margin-right: .25rem !important
  }
  .me-xl-2 {
    margin-right: .5rem !important
  }
  .me-xl-3 {
    margin-right: .75rem !important
  }
  .me-xl-4 {
    margin-right: 1rem !important
  }
  .me-xl-5 {
    margin-right: 1.5rem !important
  }
  .me-xl-6 {
    margin-right: 2rem !important
  }
  .me-xl-7 {
    margin-right: 2.5rem !important
  }
  .me-xl-8 {
    margin-right: 3rem !important
  }
  .me-xl-9 {
    margin-right: 4rem !important
  }
  .me-xl-10 {
    margin-right: 5rem !important
  }
  .me-xl-11 {
    margin-right: 6rem !important
  }
  .me-xl-12 {
    margin-right: 8rem !important
  }
  .me-xl-13 {
    margin-right: 10rem !important
  }
  .me-xl-14 {
    margin-right: 12rem !important
  }
  .me-xl-15 {
    margin-right: 16rem !important
  }
  .me-xl-16 {
    margin-right: 25rem !important
  }
  .me-xl-auto {
    margin-right: auto !important
  }
  .mb-xl-0 {
    margin-bottom: 0 !important
  }
  .mb-xl-1 {
    margin-bottom: .25rem !important
  }
  .mb-xl-2 {
    margin-bottom: .5rem !important
  }
  .mb-xl-3 {
    margin-bottom: .75rem !important
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important
  }
  .mb-xl-5 {
    margin-bottom: 1.5rem !important
  }
  .mb-xl-6 {
    margin-bottom: 2rem !important
  }
  .mb-xl-7 {
    margin-bottom: 2.5rem !important
  }
  .mb-xl-8 {
    margin-bottom: 3rem !important
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important
  }
  .mb-xl-10 {
    margin-bottom: 5rem !important
  }
  .mb-xl-11 {
    margin-bottom: 6rem !important
  }
  .mb-xl-12 {
    margin-bottom: 8rem !important
  }
  .mb-xl-13 {
    margin-bottom: 10rem !important
  }
  .mb-xl-14 {
    margin-bottom: 12rem !important
  }
  .mb-xl-15 {
    margin-bottom: 16rem !important
  }
  .mb-xl-16 {
    margin-bottom: 25rem !important
  }
  .mb-xl-auto {
    margin-bottom: auto !important
  }
  .ms-xl-0 {
    margin-left: 0 !important
  }
  .ms-xl-1 {
    margin-left: .25rem !important
  }
  .ms-xl-2 {
    margin-left: .5rem !important
  }
  .ms-xl-3 {
    margin-left: .75rem !important
  }
  .ms-xl-4 {
    margin-left: 1rem !important
  }
  .ms-xl-5 {
    margin-left: 1.5rem !important
  }
  .ms-xl-6 {
    margin-left: 2rem !important
  }
  .ms-xl-7 {
    margin-left: 2.5rem !important
  }
  .ms-xl-8 {
    margin-left: 3rem !important
  }
  .ms-xl-9 {
    margin-left: 4rem !important
  }
  .ms-xl-10 {
    margin-left: 5rem !important
  }
  .ms-xl-11 {
    margin-left: 6rem !important
  }
  .ms-xl-12 {
    margin-left: 8rem !important
  }
  .ms-xl-13 {
    margin-left: 10rem !important
  }
  .ms-xl-14 {
    margin-left: 12rem !important
  }
  .ms-xl-15 {
    margin-left: 16rem !important
  }
  .ms-xl-16 {
    margin-left: 25rem !important
  }
  .ms-xl-auto {
    margin-left: auto !important
  }
  .m-xl-n1 {
    margin: -.25rem !important
  }
  .m-xl-n2 {
    margin: -.5rem !important
  }
  .m-xl-n3 {
    margin: -.75rem !important
  }
  .m-xl-n4 {
    margin: -1rem !important
  }
  .m-xl-n5 {
    margin: -1.5rem !important
  }
  .m-xl-n6 {
    margin: -2rem !important
  }
  .m-xl-n7 {
    margin: -2.5rem !important
  }
  .m-xl-n8 {
    margin: -3rem !important
  }
  .m-xl-n9 {
    margin: -4rem !important
  }
  .m-xl-n10 {
    margin: -5rem !important
  }
  .m-xl-n11 {
    margin: -6rem !important
  }
  .m-xl-n12 {
    margin: -8rem !important
  }
  .m-xl-n13 {
    margin: -10rem !important
  }
  .m-xl-n14 {
    margin: -12rem !important
  }
  .m-xl-n15 {
    margin: -16rem !important
  }
  .m-xl-n16 {
    margin: -25rem !important
  }
  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
  }
  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
  }
  .mx-xl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
  }
  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }
  .mx-xl-n5 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
  }
  .mx-xl-n6 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }
  .mx-xl-n7 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
  }
  .mx-xl-n8 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }
  .mx-xl-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }
  .mx-xl-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }
  .mx-xl-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
  }
  .mx-xl-n12 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
  }
  .mx-xl-n13 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
  }
  .mx-xl-n14 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
  }
  .mx-xl-n15 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
  }
  .mx-xl-n16 {
    margin-left: -25rem !important;
    margin-right: -25rem !important
  }
  .my-xl-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
  }
  .my-xl-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
  }
  .my-xl-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
  }
  .my-xl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
  }
  .my-xl-n5 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
  }
  .my-xl-n6 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
  }
  .my-xl-n7 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
  }
  .my-xl-n8 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
  }
  .my-xl-n9 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
  }
  .my-xl-n10 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
  }
  .my-xl-n11 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
  }
  .my-xl-n12 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
  }
  .my-xl-n13 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
  }
  .my-xl-n14 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
  }
  .my-xl-n15 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
  }
  .my-xl-n16 {
    margin-bottom: -25rem !important;
    margin-top: -25rem !important
  }
  .mt-xl-n1 {
    margin-top: -.25rem !important
  }
  .mt-xl-n2 {
    margin-top: -.5rem !important
  }
  .mt-xl-n3 {
    margin-top: -.75rem !important
  }
  .mt-xl-n4 {
    margin-top: -1rem !important
  }
  .mt-xl-n5 {
    margin-top: -1.5rem !important
  }
  .mt-xl-n6 {
    margin-top: -2rem !important
  }
  .mt-xl-n7 {
    margin-top: -2.5rem !important
  }
  .mt-xl-n8 {
    margin-top: -3rem !important
  }
  .mt-xl-n9 {
    margin-top: -4rem !important
  }
  .mt-xl-n10 {
    margin-top: -5rem !important
  }
  .mt-xl-n11 {
    margin-top: -6rem !important
  }
  .mt-xl-n12 {
    margin-top: -8rem !important
  }
  .mt-xl-n13 {
    margin-top: -10rem !important
  }
  .mt-xl-n14 {
    margin-top: -12rem !important
  }
  .mt-xl-n15 {
    margin-top: -16rem !important
  }
  .mt-xl-n16 {
    margin-top: -25rem !important
  }
  .me-xl-n1 {
    margin-right: -.25rem !important
  }
  .me-xl-n2 {
    margin-right: -.5rem !important
  }
  .me-xl-n3 {
    margin-right: -.75rem !important
  }
  .me-xl-n4 {
    margin-right: -1rem !important
  }
  .me-xl-n5 {
    margin-right: -1.5rem !important
  }
  .me-xl-n6 {
    margin-right: -2rem !important
  }
  .me-xl-n7 {
    margin-right: -2.5rem !important
  }
  .me-xl-n8 {
    margin-right: -3rem !important
  }
  .me-xl-n9 {
    margin-right: -4rem !important
  }
  .me-xl-n10 {
    margin-right: -5rem !important
  }
  .me-xl-n11 {
    margin-right: -6rem !important
  }
  .me-xl-n12 {
    margin-right: -8rem !important
  }
  .me-xl-n13 {
    margin-right: -10rem !important
  }
  .me-xl-n14 {
    margin-right: -12rem !important
  }
  .me-xl-n15 {
    margin-right: -16rem !important
  }
  .me-xl-n16 {
    margin-right: -25rem !important
  }
  .mb-xl-n1 {
    margin-bottom: -.25rem !important
  }
  .mb-xl-n2 {
    margin-bottom: -.5rem !important
  }
  .mb-xl-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important
  }
  .mb-xl-n5 {
    margin-bottom: -1.5rem !important
  }
  .mb-xl-n6 {
    margin-bottom: -2rem !important
  }
  .mb-xl-n7 {
    margin-bottom: -2.5rem !important
  }
  .mb-xl-n8 {
    margin-bottom: -3rem !important
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important
  }
  .mb-xl-n10 {
    margin-bottom: -5rem !important
  }
  .mb-xl-n11 {
    margin-bottom: -6rem !important
  }
  .mb-xl-n12 {
    margin-bottom: -8rem !important
  }
  .mb-xl-n13 {
    margin-bottom: -10rem !important
  }
  .mb-xl-n14 {
    margin-bottom: -12rem !important
  }
  .mb-xl-n15 {
    margin-bottom: -16rem !important
  }
  .mb-xl-n16 {
    margin-bottom: -25rem !important
  }
  .ms-xl-n1 {
    margin-left: -.25rem !important
  }
  .ms-xl-n2 {
    margin-left: -.5rem !important
  }
  .ms-xl-n3 {
    margin-left: -.75rem !important
  }
  .ms-xl-n4 {
    margin-left: -1rem !important
  }
  .ms-xl-n5 {
    margin-left: -1.5rem !important
  }
  .ms-xl-n6 {
    margin-left: -2rem !important
  }
  .ms-xl-n7 {
    margin-left: -2.5rem !important
  }
  .ms-xl-n8 {
    margin-left: -3rem !important
  }
  .ms-xl-n9 {
    margin-left: -4rem !important
  }
  .ms-xl-n10 {
    margin-left: -5rem !important
  }
  .ms-xl-n11 {
    margin-left: -6rem !important
  }
  .ms-xl-n12 {
    margin-left: -8rem !important
  }
  .ms-xl-n13 {
    margin-left: -10rem !important
  }
  .ms-xl-n14 {
    margin-left: -12rem !important
  }
  .ms-xl-n15 {
    margin-left: -16rem !important
  }
  .ms-xl-n16 {
    margin-left: -25rem !important
  }
  .p-xl-0 {
    padding: 0 !important
  }
  .p-xl-1 {
    padding: .25rem !important
  }
  .p-xl-2 {
    padding: .5rem !important
  }
  .p-xl-3 {
    padding: .75rem !important
  }
  .p-xl-4 {
    padding: 1rem !important
  }
  .p-xl-5 {
    padding: 1.5rem !important
  }
  .p-xl-6 {
    padding: 2rem !important
  }
  .p-xl-7 {
    padding: 2.5rem !important
  }
  .p-xl-8 {
    padding: 3rem !important
  }
  .p-xl-9 {
    padding: 4rem !important
  }
  .p-xl-10 {
    padding: 5rem !important
  }
  .p-xl-11 {
    padding: 6rem !important
  }
  .p-xl-12 {
    padding: 8rem !important
  }
  .p-xl-13 {
    padding: 10rem !important
  }
  .p-xl-14 {
    padding: 12rem !important
  }
  .p-xl-15 {
    padding: 16rem !important
  }
  .p-xl-16 {
    padding: 25rem !important
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }
  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
  .px-xl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
  }
  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }
  .px-xl-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }
  .px-xl-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }
  .px-xl-7 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }
  .px-xl-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }
  .px-xl-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }
  .px-xl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }
  .px-xl-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
  }
  .px-xl-12 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
  }
  .px-xl-13 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
  }
  .px-xl-14 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
  }
  .px-xl-15 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
  }
  .px-xl-16 {
    padding-left: 25rem !important;
    padding-right: 25rem !important
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }
  .py-xl-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
  }
  .py-xl-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
  }
  .py-xl-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
  }
  .py-xl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
  }
  .py-xl-5 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
  }
  .py-xl-6 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
  }
  .py-xl-7 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
  }
  .py-xl-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
  }
  .py-xl-9 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
  }
  .py-xl-10 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
  }
  .py-xl-11 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
  }
  .py-xl-12 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
  }
  .py-xl-13 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
  }
  .py-xl-14 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
  }
  .py-xl-15 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
  }
  .py-xl-16 {
    padding-bottom: 25rem !important;
    padding-top: 25rem !important
  }
  .pt-xl-0 {
    padding-top: 0 !important
  }
  .pt-xl-1 {
    padding-top: .25rem !important
  }
  .pt-xl-2 {
    padding-top: .5rem !important
  }
  .pt-xl-3 {
    padding-top: .75rem !important
  }
  .pt-xl-4 {
    padding-top: 1rem !important
  }
  .pt-xl-5 {
    padding-top: 1.5rem !important
  }
  .pt-xl-6 {
    padding-top: 2rem !important
  }
  .pt-xl-7 {
    padding-top: 2.5rem !important
  }
  .pt-xl-8 {
    padding-top: 3rem !important
  }
  .pt-xl-9 {
    padding-top: 4rem !important
  }
  .pt-xl-10 {
    padding-top: 5rem !important
  }
  .pt-xl-11 {
    padding-top: 6rem !important
  }
  .pt-xl-12 {
    padding-top: 8rem !important
  }
  .pt-xl-13 {
    padding-top: 10rem !important
  }
  .pt-xl-14 {
    padding-top: 12rem !important
  }
  .pt-xl-15 {
    padding-top: 16rem !important
  }
  .pt-xl-16 {
    padding-top: 25rem !important
  }
  .pe-xl-0 {
    padding-right: 0 !important
  }
  .pe-xl-1 {
    padding-right: .25rem !important
  }
  .pe-xl-2 {
    padding-right: .5rem !important
  }
  .pe-xl-3 {
    padding-right: .75rem !important
  }
  .pe-xl-4 {
    padding-right: 1rem !important
  }
  .pe-xl-5 {
    padding-right: 1.5rem !important
  }
  .pe-xl-6 {
    padding-right: 2rem !important
  }
  .pe-xl-7 {
    padding-right: 2.5rem !important
  }
  .pe-xl-8 {
    padding-right: 3rem !important
  }
  .pe-xl-9 {
    padding-right: 4rem !important
  }
  .pe-xl-10 {
    padding-right: 5rem !important
  }
  .pe-xl-11 {
    padding-right: 6rem !important
  }
  .pe-xl-12 {
    padding-right: 8rem !important
  }
  .pe-xl-13 {
    padding-right: 10rem !important
  }
  .pe-xl-14 {
    padding-right: 12rem !important
  }
  .pe-xl-15 {
    padding-right: 16rem !important
  }
  .pe-xl-16 {
    padding-right: 25rem !important
  }
  .pb-xl-0 {
    padding-bottom: 0 !important
  }
  .pb-xl-1 {
    padding-bottom: .25rem !important
  }
  .pb-xl-2 {
    padding-bottom: .5rem !important
  }
  .pb-xl-3 {
    padding-bottom: .75rem !important
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important
  }
  .pb-xl-5 {
    padding-bottom: 1.5rem !important
  }
  .pb-xl-6 {
    padding-bottom: 2rem !important
  }
  .pb-xl-7 {
    padding-bottom: 2.5rem !important
  }
  .pb-xl-8 {
    padding-bottom: 3rem !important
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important
  }
  .pb-xl-10 {
    padding-bottom: 5rem !important
  }
  .pb-xl-11 {
    padding-bottom: 6rem !important
  }
  .pb-xl-12 {
    padding-bottom: 8rem !important
  }
  .pb-xl-13 {
    padding-bottom: 10rem !important
  }
  .pb-xl-14 {
    padding-bottom: 12rem !important
  }
  .pb-xl-15 {
    padding-bottom: 16rem !important
  }
  .pb-xl-16 {
    padding-bottom: 25rem !important
  }
  .ps-xl-0 {
    padding-left: 0 !important
  }
  .ps-xl-1 {
    padding-left: .25rem !important
  }
  .ps-xl-2 {
    padding-left: .5rem !important
  }
  .ps-xl-3 {
    padding-left: .75rem !important
  }
  .ps-xl-4 {
    padding-left: 1rem !important
  }
  .ps-xl-5 {
    padding-left: 1.5rem !important
  }
  .ps-xl-6 {
    padding-left: 2rem !important
  }
  .ps-xl-7 {
    padding-left: 2.5rem !important
  }
  .ps-xl-8 {
    padding-left: 3rem !important
  }
  .ps-xl-9 {
    padding-left: 4rem !important
  }
  .ps-xl-10 {
    padding-left: 5rem !important
  }
  .ps-xl-11 {
    padding-left: 6rem !important
  }
  .ps-xl-12 {
    padding-left: 8rem !important
  }
  .ps-xl-13 {
    padding-left: 10rem !important
  }
  .ps-xl-14 {
    padding-left: 12rem !important
  }
  .ps-xl-15 {
    padding-left: 16rem !important
  }
  .ps-xl-16 {
    padding-left: 25rem !important
  }
  .text-xl-start {
    text-align: left !important
  }
  .text-xl-end {
    text-align: right !important
  }
  .text-xl-center {
    text-align: center !important
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important
  }
  .float-xxl-end {
    float: right !important
  }
  .float-xxl-none {
    float: none !important
  }
  .d-xxl-inline {
    display: inline !important
  }
  .d-xxl-inline-block {
    display: inline-block !important
  }
  .d-xxl-block {
    display: block !important
  }
  .d-xxl-grid {
    display: grid !important
  }
  .d-xxl-table {
    display: table !important
  }
  .d-xxl-table-row {
    display: table-row !important
  }
  .d-xxl-table-cell {
    display: table-cell !important
  }
  .d-xxl-flex {
    display: flex !important
  }
  .d-xxl-inline-flex {
    display: inline-flex !important
  }
  .d-xxl-none {
    display: none !important
  }
  .position-xxl-static {
    position: static !important
  }
  .position-xxl-relative {
    position: relative !important
  }
  .position-xxl-absolute {
    position: absolute !important
  }
  .position-xxl-fixed {
    position: fixed !important
  }
  .position-xxl-sticky {
    position: sticky !important
  }
  .border-xxl {
    border: 1px solid #f1f4f8 !important
  }
  .border-xxl-0 {
    border: 0 !important
  }
  .border-top-xxl {
    border-top: 1px solid #f1f4f8 !important
  }
  .border-top-xxl-0 {
    border-top: 0 !important
  }
  .border-right-xxl {
    border-right: 1px solid #f1f4f8 !important
  }
  .border-right-xxl-0 {
    border-right: 0 !important
  }
  .border-bottom-xxl {
    border-bottom: 1px solid #f1f4f8 !important
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important
  }
  .border-left-xxl {
    border-left: 1px solid #f1f4f8 !important
  }
  .border-left-xxl-0 {
    border-left: 0 !important
  }
  .w-xxl-25 {
    width: 25% !important
  }
  .w-xxl-50 {
    width: 50% !important
  }
  .w-xxl-75 {
    width: 75% !important
  }
  .w-xxl-100 {
    width: 100% !important
  }
  .w-xxl-110 {
    width: 110% !important
  }
  .w-xxl-120 {
    width: 120% !important
  }
  .w-xxl-130 {
    width: 130% !important
  }
  .w-xxl-140 {
    width: 140% !important
  }
  .w-xxl-150 {
    width: 150% !important
  }
  .w-xxl-auto {
    width: auto !important
  }
  .mw-xxl-25 {
    max-width: 25% !important
  }
  .mw-xxl-50 {
    max-width: 50% !important
  }
  .mw-xxl-75 {
    max-width: 75% !important
  }
  .mw-xxl-100 {
    max-width: 100% !important
  }
  .mw-xxl-110 {
    max-width: 110% !important
  }
  .mw-xxl-120 {
    max-width: 120% !important
  }
  .mw-xxl-130 {
    max-width: 130% !important
  }
  .mw-xxl-140 {
    max-width: 140% !important
  }
  .mw-xxl-150 {
    max-width: 150% !important
  }
  .vw-xxl-25 {
    width: 25vw !important
  }
  .vw-xxl-50 {
    width: 50vw !important
  }
  .vw-xxl-75 {
    width: 75vw !important
  }
  .vw-xxl-100 {
    width: 100vw !important
  }
  .vw-xxl-110 {
    width: 110vw !important
  }
  .vw-xxl-120 {
    width: 120vw !important
  }
  .vw-xxl-130 {
    width: 130vw !important
  }
  .vw-xxl-140 {
    width: 140vw !important
  }
  .vw-xxl-150 {
    width: 150vw !important
  }
  .h-xxl-25 {
    height: 25% !important
  }
  .h-xxl-50 {
    height: 50% !important
  }
  .h-xxl-75 {
    height: 75% !important
  }
  .h-xxl-100 {
    height: 100% !important
  }
  .h-xxl-110 {
    height: 110% !important
  }
  .h-xxl-120 {
    height: 120% !important
  }
  .h-xxl-130 {
    height: 130% !important
  }
  .h-xxl-140 {
    height: 140% !important
  }
  .h-xxl-150 {
    height: 150% !important
  }
  .h-xxl-auto {
    height: auto !important
  }
  .vh-xxl-25 {
    height: 25vh !important
  }
  .vh-xxl-50 {
    height: 50vh !important
  }
  .vh-xxl-75 {
    height: 75vh !important
  }
  .vh-xxl-100 {
    height: 100vh !important
  }
  .vh-xxl-110 {
    height: 110vh !important
  }
  .vh-xxl-120 {
    height: 120vh !important
  }
  .vh-xxl-130 {
    height: 130vh !important
  }
  .vh-xxl-140 {
    height: 140vh !important
  }
  .vh-xxl-150 {
    height: 150vh !important
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important
  }
  .flex-xxl-row {
    flex-direction: row !important
  }
  .flex-xxl-column {
    flex-direction: column !important
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .gap-xxl-0 {
    gap: 0 !important
  }
  .gap-xxl-1 {
    gap: .25rem !important
  }
  .gap-xxl-2 {
    gap: .5rem !important
  }
  .gap-xxl-3 {
    gap: .75rem !important
  }
  .gap-xxl-4 {
    gap: 1rem !important
  }
  .gap-xxl-5 {
    gap: 1.5rem !important
  }
  .gap-xxl-6 {
    gap: 2rem !important
  }
  .gap-xxl-7 {
    gap: 2.5rem !important
  }
  .gap-xxl-8 {
    gap: 3rem !important
  }
  .gap-xxl-9 {
    gap: 4rem !important
  }
  .gap-xxl-10 {
    gap: 5rem !important
  }
  .gap-xxl-11 {
    gap: 6rem !important
  }
  .gap-xxl-12 {
    gap: 8rem !important
  }
  .gap-xxl-13 {
    gap: 10rem !important
  }
  .gap-xxl-14 {
    gap: 12rem !important
  }
  .gap-xxl-15 {
    gap: 16rem !important
  }
  .gap-xxl-16 {
    gap: 25rem !important
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important
  }
  .justify-content-xxl-center {
    justify-content: center !important
  }
  .justify-content-xxl-between {
    justify-content: space-between !important
  }
  .justify-content-xxl-around {
    justify-content: space-around !important
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important
  }
  .align-items-xxl-start {
    align-items: flex-start !important
  }
  .align-items-xxl-end {
    align-items: flex-end !important
  }
  .align-items-xxl-center {
    align-items: center !important
  }
  .align-items-xxl-baseline {
    align-items: baseline !important
  }
  .align-items-xxl-stretch {
    align-items: stretch !important
  }
  .align-content-xxl-start {
    align-content: flex-start !important
  }
  .align-content-xxl-end {
    align-content: flex-end !important
  }
  .align-content-xxl-center {
    align-content: center !important
  }
  .align-content-xxl-between {
    align-content: space-between !important
  }
  .align-content-xxl-around {
    align-content: space-around !important
  }
  .align-content-xxl-stretch {
    align-content: stretch !important
  }
  .align-self-xxl-auto {
    align-self: auto !important
  }
  .align-self-xxl-start {
    align-self: flex-start !important
  }
  .align-self-xxl-end {
    align-self: flex-end !important
  }
  .align-self-xxl-center {
    align-self: center !important
  }
  .align-self-xxl-baseline {
    align-self: baseline !important
  }
  .align-self-xxl-stretch {
    align-self: stretch !important
  }
  .order-xxl-first {
    order: -1 !important
  }
  .order-xxl-0 {
    order: 0 !important
  }
  .order-xxl-1 {
    order: 1 !important
  }
  .order-xxl-2 {
    order: 2 !important
  }
  .order-xxl-3 {
    order: 3 !important
  }
  .order-xxl-4 {
    order: 4 !important
  }
  .order-xxl-5 {
    order: 5 !important
  }
  .order-xxl-last {
    order: 6 !important
  }
  .m-xxl-0 {
    margin: 0 !important
  }
  .m-xxl-1 {
    margin: .25rem !important
  }
  .m-xxl-2 {
    margin: .5rem !important
  }
  .m-xxl-3 {
    margin: .75rem !important
  }
  .m-xxl-4 {
    margin: 1rem !important
  }
  .m-xxl-5 {
    margin: 1.5rem !important
  }
  .m-xxl-6 {
    margin: 2rem !important
  }
  .m-xxl-7 {
    margin: 2.5rem !important
  }
  .m-xxl-8 {
    margin: 3rem !important
  }
  .m-xxl-9 {
    margin: 4rem !important
  }
  .m-xxl-10 {
    margin: 5rem !important
  }
  .m-xxl-11 {
    margin: 6rem !important
  }
  .m-xxl-12 {
    margin: 8rem !important
  }
  .m-xxl-13 {
    margin: 10rem !important
  }
  .m-xxl-14 {
    margin: 12rem !important
  }
  .m-xxl-15 {
    margin: 16rem !important
  }
  .m-xxl-16 {
    margin: 25rem !important
  }
  .m-xxl-auto {
    margin: auto !important
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
  }
  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important
  }
  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important
  }
  .mx-xxl-3 {
    margin-left: .75rem !important;
    margin-right: .75rem !important
  }
  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
  }
  .mx-xxl-5 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
  }
  .mx-xxl-6 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
  }
  .mx-xxl-7 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
  }
  .mx-xxl-8 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
  }
  .mx-xxl-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
  }
  .mx-xxl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
  }
  .mx-xxl-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
  }
  .mx-xxl-12 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
  }
  .mx-xxl-13 {
    margin-left: 10rem !important;
    margin-right: 10rem !important
  }
  .mx-xxl-14 {
    margin-left: 12rem !important;
    margin-right: 12rem !important
  }
  .mx-xxl-15 {
    margin-left: 16rem !important;
    margin-right: 16rem !important
  }
  .mx-xxl-16 {
    margin-left: 25rem !important;
    margin-right: 25rem !important
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important
  }
  .my-xxl-1 {
    margin-bottom: .25rem !important;
    margin-top: .25rem !important
  }
  .my-xxl-2 {
    margin-bottom: .5rem !important;
    margin-top: .5rem !important
  }
  .my-xxl-3 {
    margin-bottom: .75rem !important;
    margin-top: .75rem !important
  }
  .my-xxl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important
  }
  .my-xxl-5 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important
  }
  .my-xxl-6 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important
  }
  .my-xxl-7 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important
  }
  .my-xxl-8 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important
  }
  .my-xxl-9 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important
  }
  .my-xxl-10 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important
  }
  .my-xxl-11 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important
  }
  .my-xxl-12 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important
  }
  .my-xxl-13 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important
  }
  .my-xxl-14 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important
  }
  .my-xxl-15 {
    margin-bottom: 16rem !important;
    margin-top: 16rem !important
  }
  .my-xxl-16 {
    margin-bottom: 25rem !important;
    margin-top: 25rem !important
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important
  }
  .mt-xxl-0 {
    margin-top: 0 !important
  }
  .mt-xxl-1 {
    margin-top: .25rem !important
  }
  .mt-xxl-2 {
    margin-top: .5rem !important
  }
  .mt-xxl-3 {
    margin-top: .75rem !important
  }
  .mt-xxl-4 {
    margin-top: 1rem !important
  }
  .mt-xxl-5 {
    margin-top: 1.5rem !important
  }
  .mt-xxl-6 {
    margin-top: 2rem !important
  }
  .mt-xxl-7 {
    margin-top: 2.5rem !important
  }
  .mt-xxl-8 {
    margin-top: 3rem !important
  }
  .mt-xxl-9 {
    margin-top: 4rem !important
  }
  .mt-xxl-10 {
    margin-top: 5rem !important
  }
  .mt-xxl-11 {
    margin-top: 6rem !important
  }
  .mt-xxl-12 {
    margin-top: 8rem !important
  }
  .mt-xxl-13 {
    margin-top: 10rem !important
  }
  .mt-xxl-14 {
    margin-top: 12rem !important
  }
  .mt-xxl-15 {
    margin-top: 16rem !important
  }
  .mt-xxl-16 {
    margin-top: 25rem !important
  }
  .mt-xxl-auto {
    margin-top: auto !important
  }
  .me-xxl-0 {
    margin-right: 0 !important
  }
  .me-xxl-1 {
    margin-right: .25rem !important
  }
  .me-xxl-2 {
    margin-right: .5rem !important
  }
  .me-xxl-3 {
    margin-right: .75rem !important
  }
  .me-xxl-4 {
    margin-right: 1rem !important
  }
  .me-xxl-5 {
    margin-right: 1.5rem !important
  }
  .me-xxl-6 {
    margin-right: 2rem !important
  }
  .me-xxl-7 {
    margin-right: 2.5rem !important
  }
  .me-xxl-8 {
    margin-right: 3rem !important
  }
  .me-xxl-9 {
    margin-right: 4rem !important
  }
  .me-xxl-10 {
    margin-right: 5rem !important
  }
  .me-xxl-11 {
    margin-right: 6rem !important
  }
  .me-xxl-12 {
    margin-right: 8rem !important
  }
  .me-xxl-13 {
    margin-right: 10rem !important
  }
  .me-xxl-14 {
    margin-right: 12rem !important
  }
  .me-xxl-15 {
    margin-right: 16rem !important
  }
  .me-xxl-16 {
    margin-right: 25rem !important
  }
  .me-xxl-auto {
    margin-right: auto !important
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important
  }
  .mb-xxl-1 {
    margin-bottom: .25rem !important
  }
  .mb-xxl-2 {
    margin-bottom: .5rem !important
  }
  .mb-xxl-3 {
    margin-bottom: .75rem !important
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important
  }
  .mb-xxl-5 {
    margin-bottom: 1.5rem !important
  }
  .mb-xxl-6 {
    margin-bottom: 2rem !important
  }
  .mb-xxl-7 {
    margin-bottom: 2.5rem !important
  }
  .mb-xxl-8 {
    margin-bottom: 3rem !important
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important
  }
  .mb-xxl-10 {
    margin-bottom: 5rem !important
  }
  .mb-xxl-11 {
    margin-bottom: 6rem !important
  }
  .mb-xxl-12 {
    margin-bottom: 8rem !important
  }
  .mb-xxl-13 {
    margin-bottom: 10rem !important
  }
  .mb-xxl-14 {
    margin-bottom: 12rem !important
  }
  .mb-xxl-15 {
    margin-bottom: 16rem !important
  }
  .mb-xxl-16 {
    margin-bottom: 25rem !important
  }
  .mb-xxl-auto {
    margin-bottom: auto !important
  }
  .ms-xxl-0 {
    margin-left: 0 !important
  }
  .ms-xxl-1 {
    margin-left: .25rem !important
  }
  .ms-xxl-2 {
    margin-left: .5rem !important
  }
  .ms-xxl-3 {
    margin-left: .75rem !important
  }
  .ms-xxl-4 {
    margin-left: 1rem !important
  }
  .ms-xxl-5 {
    margin-left: 1.5rem !important
  }
  .ms-xxl-6 {
    margin-left: 2rem !important
  }
  .ms-xxl-7 {
    margin-left: 2.5rem !important
  }
  .ms-xxl-8 {
    margin-left: 3rem !important
  }
  .ms-xxl-9 {
    margin-left: 4rem !important
  }
  .ms-xxl-10 {
    margin-left: 5rem !important
  }
  .ms-xxl-11 {
    margin-left: 6rem !important
  }
  .ms-xxl-12 {
    margin-left: 8rem !important
  }
  .ms-xxl-13 {
    margin-left: 10rem !important
  }
  .ms-xxl-14 {
    margin-left: 12rem !important
  }
  .ms-xxl-15 {
    margin-left: 16rem !important
  }
  .ms-xxl-16 {
    margin-left: 25rem !important
  }
  .ms-xxl-auto {
    margin-left: auto !important
  }
  .m-xxl-n1 {
    margin: -.25rem !important
  }
  .m-xxl-n2 {
    margin: -.5rem !important
  }
  .m-xxl-n3 {
    margin: -.75rem !important
  }
  .m-xxl-n4 {
    margin: -1rem !important
  }
  .m-xxl-n5 {
    margin: -1.5rem !important
  }
  .m-xxl-n6 {
    margin: -2rem !important
  }
  .m-xxl-n7 {
    margin: -2.5rem !important
  }
  .m-xxl-n8 {
    margin: -3rem !important
  }
  .m-xxl-n9 {
    margin: -4rem !important
  }
  .m-xxl-n10 {
    margin: -5rem !important
  }
  .m-xxl-n11 {
    margin: -6rem !important
  }
  .m-xxl-n12 {
    margin: -8rem !important
  }
  .m-xxl-n13 {
    margin: -10rem !important
  }
  .m-xxl-n14 {
    margin: -12rem !important
  }
  .m-xxl-n15 {
    margin: -16rem !important
  }
  .m-xxl-n16 {
    margin: -25rem !important
  }
  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important
  }
  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important
  }
  .mx-xxl-n3 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important
  }
  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
  }
  .mx-xxl-n5 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important
  }
  .mx-xxl-n6 {
    margin-left: -2rem !important;
    margin-right: -2rem !important
  }
  .mx-xxl-n7 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important
  }
  .mx-xxl-n8 {
    margin-left: -3rem !important;
    margin-right: -3rem !important
  }
  .mx-xxl-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important
  }
  .mx-xxl-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important
  }
  .mx-xxl-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important
  }
  .mx-xxl-n12 {
    margin-left: -8rem !important;
    margin-right: -8rem !important
  }
  .mx-xxl-n13 {
    margin-left: -10rem !important;
    margin-right: -10rem !important
  }
  .mx-xxl-n14 {
    margin-left: -12rem !important;
    margin-right: -12rem !important
  }
  .mx-xxl-n15 {
    margin-left: -16rem !important;
    margin-right: -16rem !important
  }
  .mx-xxl-n16 {
    margin-left: -25rem !important;
    margin-right: -25rem !important
  }
  .my-xxl-n1 {
    margin-bottom: -.25rem !important;
    margin-top: -.25rem !important
  }
  .my-xxl-n2 {
    margin-bottom: -.5rem !important;
    margin-top: -.5rem !important
  }
  .my-xxl-n3 {
    margin-bottom: -.75rem !important;
    margin-top: -.75rem !important
  }
  .my-xxl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important
  }
  .my-xxl-n5 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important
  }
  .my-xxl-n6 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important
  }
  .my-xxl-n7 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important
  }
  .my-xxl-n8 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important
  }
  .my-xxl-n9 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important
  }
  .my-xxl-n10 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important
  }
  .my-xxl-n11 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important
  }
  .my-xxl-n12 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important
  }
  .my-xxl-n13 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important
  }
  .my-xxl-n14 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important
  }
  .my-xxl-n15 {
    margin-bottom: -16rem !important;
    margin-top: -16rem !important
  }
  .my-xxl-n16 {
    margin-bottom: -25rem !important;
    margin-top: -25rem !important
  }
  .mt-xxl-n1 {
    margin-top: -.25rem !important
  }
  .mt-xxl-n2 {
    margin-top: -.5rem !important
  }
  .mt-xxl-n3 {
    margin-top: -.75rem !important
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important
  }
  .mt-xxl-n5 {
    margin-top: -1.5rem !important
  }
  .mt-xxl-n6 {
    margin-top: -2rem !important
  }
  .mt-xxl-n7 {
    margin-top: -2.5rem !important
  }
  .mt-xxl-n8 {
    margin-top: -3rem !important
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important
  }
  .mt-xxl-n10 {
    margin-top: -5rem !important
  }
  .mt-xxl-n11 {
    margin-top: -6rem !important
  }
  .mt-xxl-n12 {
    margin-top: -8rem !important
  }
  .mt-xxl-n13 {
    margin-top: -10rem !important
  }
  .mt-xxl-n14 {
    margin-top: -12rem !important
  }
  .mt-xxl-n15 {
    margin-top: -16rem !important
  }
  .mt-xxl-n16 {
    margin-top: -25rem !important
  }
  .me-xxl-n1 {
    margin-right: -.25rem !important
  }
  .me-xxl-n2 {
    margin-right: -.5rem !important
  }
  .me-xxl-n3 {
    margin-right: -.75rem !important
  }
  .me-xxl-n4 {
    margin-right: -1rem !important
  }
  .me-xxl-n5 {
    margin-right: -1.5rem !important
  }
  .me-xxl-n6 {
    margin-right: -2rem !important
  }
  .me-xxl-n7 {
    margin-right: -2.5rem !important
  }
  .me-xxl-n8 {
    margin-right: -3rem !important
  }
  .me-xxl-n9 {
    margin-right: -4rem !important
  }
  .me-xxl-n10 {
    margin-right: -5rem !important
  }
  .me-xxl-n11 {
    margin-right: -6rem !important
  }
  .me-xxl-n12 {
    margin-right: -8rem !important
  }
  .me-xxl-n13 {
    margin-right: -10rem !important
  }
  .me-xxl-n14 {
    margin-right: -12rem !important
  }
  .me-xxl-n15 {
    margin-right: -16rem !important
  }
  .me-xxl-n16 {
    margin-right: -25rem !important
  }
  .mb-xxl-n1 {
    margin-bottom: -.25rem !important
  }
  .mb-xxl-n2 {
    margin-bottom: -.5rem !important
  }
  .mb-xxl-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important
  }
  .mb-xxl-n5 {
    margin-bottom: -1.5rem !important
  }
  .mb-xxl-n6 {
    margin-bottom: -2rem !important
  }
  .mb-xxl-n7 {
    margin-bottom: -2.5rem !important
  }
  .mb-xxl-n8 {
    margin-bottom: -3rem !important
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important
  }
  .mb-xxl-n10 {
    margin-bottom: -5rem !important
  }
  .mb-xxl-n11 {
    margin-bottom: -6rem !important
  }
  .mb-xxl-n12 {
    margin-bottom: -8rem !important
  }
  .mb-xxl-n13 {
    margin-bottom: -10rem !important
  }
  .mb-xxl-n14 {
    margin-bottom: -12rem !important
  }
  .mb-xxl-n15 {
    margin-bottom: -16rem !important
  }
  .mb-xxl-n16 {
    margin-bottom: -25rem !important
  }
  .ms-xxl-n1 {
    margin-left: -.25rem !important
  }
  .ms-xxl-n2 {
    margin-left: -.5rem !important
  }
  .ms-xxl-n3 {
    margin-left: -.75rem !important
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important
  }
  .ms-xxl-n5 {
    margin-left: -1.5rem !important
  }
  .ms-xxl-n6 {
    margin-left: -2rem !important
  }
  .ms-xxl-n7 {
    margin-left: -2.5rem !important
  }
  .ms-xxl-n8 {
    margin-left: -3rem !important
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important
  }
  .ms-xxl-n10 {
    margin-left: -5rem !important
  }
  .ms-xxl-n11 {
    margin-left: -6rem !important
  }
  .ms-xxl-n12 {
    margin-left: -8rem !important
  }
  .ms-xxl-n13 {
    margin-left: -10rem !important
  }
  .ms-xxl-n14 {
    margin-left: -12rem !important
  }
  .ms-xxl-n15 {
    margin-left: -16rem !important
  }
  .ms-xxl-n16 {
    margin-left: -25rem !important
  }
  .p-xxl-0 {
    padding: 0 !important
  }
  .p-xxl-1 {
    padding: .25rem !important
  }
  .p-xxl-2 {
    padding: .5rem !important
  }
  .p-xxl-3 {
    padding: .75rem !important
  }
  .p-xxl-4 {
    padding: 1rem !important
  }
  .p-xxl-5 {
    padding: 1.5rem !important
  }
  .p-xxl-6 {
    padding: 2rem !important
  }
  .p-xxl-7 {
    padding: 2.5rem !important
  }
  .p-xxl-8 {
    padding: 3rem !important
  }
  .p-xxl-9 {
    padding: 4rem !important
  }
  .p-xxl-10 {
    padding: 5rem !important
  }
  .p-xxl-11 {
    padding: 6rem !important
  }
  .p-xxl-12 {
    padding: 8rem !important
  }
  .p-xxl-13 {
    padding: 10rem !important
  }
  .p-xxl-14 {
    padding: 12rem !important
  }
  .p-xxl-15 {
    padding: 16rem !important
  }
  .p-xxl-16 {
    padding: 25rem !important
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
  }
  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important
  }
  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important
  }
  .px-xxl-3 {
    padding-left: .75rem !important;
    padding-right: .75rem !important
  }
  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
  }
  .px-xxl-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
  }
  .px-xxl-6 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
  }
  .px-xxl-7 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
  }
  .px-xxl-8 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
  }
  .px-xxl-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
  }
  .px-xxl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
  }
  .px-xxl-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
  }
  .px-xxl-12 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
  }
  .px-xxl-13 {
    padding-left: 10rem !important;
    padding-right: 10rem !important
  }
  .px-xxl-14 {
    padding-left: 12rem !important;
    padding-right: 12rem !important
  }
  .px-xxl-15 {
    padding-left: 16rem !important;
    padding-right: 16rem !important
  }
  .px-xxl-16 {
    padding-left: 25rem !important;
    padding-right: 25rem !important
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important
  }
  .py-xxl-1 {
    padding-bottom: .25rem !important;
    padding-top: .25rem !important
  }
  .py-xxl-2 {
    padding-bottom: .5rem !important;
    padding-top: .5rem !important
  }
  .py-xxl-3 {
    padding-bottom: .75rem !important;
    padding-top: .75rem !important
  }
  .py-xxl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important
  }
  .py-xxl-5 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important
  }
  .py-xxl-6 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important
  }
  .py-xxl-7 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important
  }
  .py-xxl-8 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important
  }
  .py-xxl-9 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important
  }
  .py-xxl-10 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important
  }
  .py-xxl-11 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important
  }
  .py-xxl-12 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important
  }
  .py-xxl-13 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important
  }
  .py-xxl-14 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important
  }
  .py-xxl-15 {
    padding-bottom: 16rem !important;
    padding-top: 16rem !important
  }
  .py-xxl-16 {
    padding-bottom: 25rem !important;
    padding-top: 25rem !important
  }
  .pt-xxl-0 {
    padding-top: 0 !important
  }
  .pt-xxl-1 {
    padding-top: .25rem !important
  }
  .pt-xxl-2 {
    padding-top: .5rem !important
  }
  .pt-xxl-3 {
    padding-top: .75rem !important
  }
  .pt-xxl-4 {
    padding-top: 1rem !important
  }
  .pt-xxl-5 {
    padding-top: 1.5rem !important
  }
  .pt-xxl-6 {
    padding-top: 2rem !important
  }
  .pt-xxl-7 {
    padding-top: 2.5rem !important
  }
  .pt-xxl-8 {
    padding-top: 3rem !important
  }
  .pt-xxl-9 {
    padding-top: 4rem !important
  }
  .pt-xxl-10 {
    padding-top: 5rem !important
  }
  .pt-xxl-11 {
    padding-top: 6rem !important
  }
  .pt-xxl-12 {
    padding-top: 8rem !important
  }
  .pt-xxl-13 {
    padding-top: 10rem !important
  }
  .pt-xxl-14 {
    padding-top: 12rem !important
  }
  .pt-xxl-15 {
    padding-top: 16rem !important
  }
  .pt-xxl-16 {
    padding-top: 25rem !important
  }
  .pe-xxl-0 {
    padding-right: 0 !important
  }
  .pe-xxl-1 {
    padding-right: .25rem !important
  }
  .pe-xxl-2 {
    padding-right: .5rem !important
  }
  .pe-xxl-3 {
    padding-right: .75rem !important
  }
  .pe-xxl-4 {
    padding-right: 1rem !important
  }
  .pe-xxl-5 {
    padding-right: 1.5rem !important
  }
  .pe-xxl-6 {
    padding-right: 2rem !important
  }
  .pe-xxl-7 {
    padding-right: 2.5rem !important
  }
  .pe-xxl-8 {
    padding-right: 3rem !important
  }
  .pe-xxl-9 {
    padding-right: 4rem !important
  }
  .pe-xxl-10 {
    padding-right: 5rem !important
  }
  .pe-xxl-11 {
    padding-right: 6rem !important
  }
  .pe-xxl-12 {
    padding-right: 8rem !important
  }
  .pe-xxl-13 {
    padding-right: 10rem !important
  }
  .pe-xxl-14 {
    padding-right: 12rem !important
  }
  .pe-xxl-15 {
    padding-right: 16rem !important
  }
  .pe-xxl-16 {
    padding-right: 25rem !important
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important
  }
  .pb-xxl-1 {
    padding-bottom: .25rem !important
  }
  .pb-xxl-2 {
    padding-bottom: .5rem !important
  }
  .pb-xxl-3 {
    padding-bottom: .75rem !important
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important
  }
  .pb-xxl-5 {
    padding-bottom: 1.5rem !important
  }
  .pb-xxl-6 {
    padding-bottom: 2rem !important
  }
  .pb-xxl-7 {
    padding-bottom: 2.5rem !important
  }
  .pb-xxl-8 {
    padding-bottom: 3rem !important
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important
  }
  .pb-xxl-10 {
    padding-bottom: 5rem !important
  }
  .pb-xxl-11 {
    padding-bottom: 6rem !important
  }
  .pb-xxl-12 {
    padding-bottom: 8rem !important
  }
  .pb-xxl-13 {
    padding-bottom: 10rem !important
  }
  .pb-xxl-14 {
    padding-bottom: 12rem !important
  }
  .pb-xxl-15 {
    padding-bottom: 16rem !important
  }
  .pb-xxl-16 {
    padding-bottom: 25rem !important
  }
  .ps-xxl-0 {
    padding-left: 0 !important
  }
  .ps-xxl-1 {
    padding-left: .25rem !important
  }
  .ps-xxl-2 {
    padding-left: .5rem !important
  }
  .ps-xxl-3 {
    padding-left: .75rem !important
  }
  .ps-xxl-4 {
    padding-left: 1rem !important
  }
  .ps-xxl-5 {
    padding-left: 1.5rem !important
  }
  .ps-xxl-6 {
    padding-left: 2rem !important
  }
  .ps-xxl-7 {
    padding-left: 2.5rem !important
  }
  .ps-xxl-8 {
    padding-left: 3rem !important
  }
  .ps-xxl-9 {
    padding-left: 4rem !important
  }
  .ps-xxl-10 {
    padding-left: 5rem !important
  }
  .ps-xxl-11 {
    padding-left: 6rem !important
  }
  .ps-xxl-12 {
    padding-left: 8rem !important
  }
  .ps-xxl-13 {
    padding-left: 10rem !important
  }
  .ps-xxl-14 {
    padding-left: 12rem !important
  }
  .ps-xxl-15 {
    padding-left: 16rem !important
  }
  .ps-xxl-16 {
    padding-left: 25rem !important
  }
  .text-xxl-start {
    text-align: left !important
  }
  .text-xxl-end {
    text-align: right !important
  }
  .text-xxl-center {
    text-align: center !important
  }
}

@media print {
  .d-print-inline {
    display: inline !important
  }
  .d-print-inline-block {
    display: inline-block !important
  }
  .d-print-block {
    display: block !important
  }
  .d-print-grid {
    display: grid !important
  }
  .d-print-table {
    display: table !important
  }
  .d-print-table-row {
    display: table-row !important
  }
  .d-print-table-cell {
    display: table-cell !important
  }
  .d-print-flex {
    display: flex !important
  }
  .d-print-inline-flex {
    display: inline-flex !important
  }
  .d-print-none {
    display: none !important
  }
}

.bg-cover {
  background: no-repeat 50%/cover
}

@media (max-width: 991.98px) {
  .bg-between {
    background-image: none !important
  }
}

@media (min-width: 992px) {
  .bg-between {
    background-position: 0, 100%;
    background-repeat: no-repeat;
    background-size: auto 70%
  }
}

.bg-gradient-light-white {
  background-image: linear-gradient(180deg, #f9fbfd 0, #fff)
}

.bg-gradient-dark-black {
  background-image: linear-gradient(180deg, #1b2a4e 0, #161c2d)
}

.bg-gradient-multicolor {
  background-image: conic-gradient(from 52deg at 50% 50%, #8024a0 0deg, #31eec1 33deg, #6924a0 222deg, #d6723b 264deg, #d5498c 295deg, #8024a0 1turn)
}

.bg-pattern-2 {
  background-image: linear-gradient(180deg, transparent 0, #161c2d), url(../img/patterns/pattern-2.png)
}

.img-skewed {
  perspective: 1500px;
  transform-style: preserve-3d
}

.img-skewed-start {
  perspective-origin: 0
}

.img-skewed-start .img-skewed-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(-35deg) rotateX(15deg)
}

.img-skewed-end {
  perspective-origin: 100%
}

.img-skewed-end .img-skewed-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(35deg) rotateX(15deg)
}

.lift {
  transition: box-shadow .25s ease, transform .25s ease
}

.lift:focus, .lift:hover {
  box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1) !important;
  transform: translate3d(0, -3px, 0)
}

.lift-lg:focus, .lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .05) !important;
  transform: translate3d(0, -5px, 0)
}

.overlay {
  position: relative
}

.overlay:before {
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.overlay-primary:before {
  background-color: #142840
}

.overlay-gradient-primary-end:before {
  background-image: linear-gradient(180deg, transparent, #142840)
}

@media (min-width: 992px) {
  .overlay-gradient-primary-end:before {
    background-image: linear-gradient(90deg, transparent, #142840 50%, #142840)
  }
}

.overlay-gradient-primary-down:before, .overlay-gradient-primary-start:before {
  background-image: linear-gradient(180deg, transparent, #142840)
}

@media (min-width: 992px) {
  .overlay-gradient-primary-start:before {
    background-image: linear-gradient(270deg, transparent, #142840 50%, #142840)
  }
}

.overlay-secondary:before {
  background-color: #506690
}

.overlay-gradient-secondary-end:before {
  background-image: linear-gradient(180deg, transparent, #506690)
}

@media (min-width: 992px) {
  .overlay-gradient-secondary-end:before {
    background-image: linear-gradient(90deg, transparent, #506690 50%, #506690)
  }
}

.overlay-gradient-secondary-down:before, .overlay-gradient-secondary-start:before {
  background-image: linear-gradient(180deg, transparent, #506690)
}

@media (min-width: 992px) {
  .overlay-gradient-secondary-start:before {
    background-image: linear-gradient(270deg, transparent, #506690 50%, #506690)
  }
}

.overlay-success:before {
  background-color: #42ba96
}

.overlay-gradient-success-end:before {
  background-image: linear-gradient(180deg, transparent, #42ba96)
}

@media (min-width: 992px) {
  .overlay-gradient-success-end:before {
    background-image: linear-gradient(90deg, transparent, #42ba96 50%, #42ba96)
  }
}

.overlay-gradient-success-down:before, .overlay-gradient-success-start:before {
  background-image: linear-gradient(180deg, transparent, #42ba96)
}

@media (min-width: 992px) {
  .overlay-gradient-success-start:before {
    background-image: linear-gradient(270deg, transparent, #42ba96 50%, #42ba96)
  }
}

.overlay-info:before {
  background-color: #0495a9
}

.overlay-gradient-info-end:before {
  background-image: linear-gradient(180deg, transparent, #0495a9)
}

@media (min-width: 992px) {
  .overlay-gradient-info-end:before {
    background-image: linear-gradient(90deg, transparent, #0495a9 50%, #0495a9)
  }
}

.overlay-gradient-info-down:before, .overlay-gradient-info-start:before {
  background-image: linear-gradient(180deg, transparent, #0495a9)
}

@media (min-width: 992px) {
  .overlay-gradient-info-start:before {
    background-image: linear-gradient(270deg, transparent, #0495a9 50%, #0495a9)
  }
}

.overlay-warning:before {
  background-color: #fad776
}

.overlay-gradient-warning-end:before {
  background-image: linear-gradient(180deg, transparent, #fad776)
}

@media (min-width: 992px) {
  .overlay-gradient-warning-end:before {
    background-image: linear-gradient(90deg, transparent, #fad776 50%, #fad776)
  }
}

.overlay-gradient-warning-down:before, .overlay-gradient-warning-start:before {
  background-image: linear-gradient(180deg, transparent, #fad776)
}

@media (min-width: 992px) {
  .overlay-gradient-warning-start:before {
    background-image: linear-gradient(270deg, transparent, #fad776 50%, #fad776)
  }
}

.overlay-danger:before {
  background-color: #df4759
}

.overlay-gradient-danger-end:before {
  background-image: linear-gradient(180deg, transparent, #df4759)
}

@media (min-width: 992px) {
  .overlay-gradient-danger-end:before {
    background-image: linear-gradient(90deg, transparent, #df4759 50%, #df4759)
  }
}

.overlay-gradient-danger-down:before, .overlay-gradient-danger-start:before {
  background-image: linear-gradient(180deg, transparent, #df4759)
}

@media (min-width: 992px) {
  .overlay-gradient-danger-start:before {
    background-image: linear-gradient(270deg, transparent, #df4759 50%, #df4759)
  }
}

.overlay-light:before {
  background-color: #f9fbfd
}

.overlay-gradient-light-end:before {
  background-image: linear-gradient(180deg, transparent, #f9fbfd)
}

@media (min-width: 992px) {
  .overlay-gradient-light-end:before {
    background-image: linear-gradient(90deg, transparent, #f9fbfd 50%, #f9fbfd)
  }
}

.overlay-gradient-light-down:before, .overlay-gradient-light-start:before {
  background-image: linear-gradient(180deg, transparent, #f9fbfd)
}

@media (min-width: 992px) {
  .overlay-gradient-light-start:before {
    background-image: linear-gradient(270deg, transparent, #f9fbfd 50%, #f9fbfd)
  }
}

.overlay-dark:before {
  background-color: #1b2a4e
}

.overlay-gradient-dark-end:before {
  background-image: linear-gradient(180deg, transparent, #1b2a4e)
}

@media (min-width: 992px) {
  .overlay-gradient-dark-end:before {
    background-image: linear-gradient(90deg, transparent, #1b2a4e 50%, #1b2a4e)
  }
}

.overlay-gradient-dark-down:before, .overlay-gradient-dark-start:before {
  background-image: linear-gradient(180deg, transparent, #1b2a4e)
}

@media (min-width: 992px) {
  .overlay-gradient-dark-start:before {
    background-image: linear-gradient(270deg, transparent, #1b2a4e 50%, #1b2a4e)
  }
}

.overlay-primary-desat:before {
  background-color: #6c8aec
}

.overlay-gradient-primary-desat-end:before {
  background-image: linear-gradient(180deg, transparent, #6c8aec)
}

@media (min-width: 992px) {
  .overlay-gradient-primary-desat-end:before {
    background-image: linear-gradient(90deg, transparent, #6c8aec 50%, #6c8aec)
  }
}

.overlay-gradient-primary-desat-down:before, .overlay-gradient-primary-desat-start:before {
  background-image: linear-gradient(180deg, transparent, #6c8aec)
}

@media (min-width: 992px) {
  .overlay-gradient-primary-desat-start:before {
    background-image: linear-gradient(270deg, transparent, #6c8aec 50%, #6c8aec)
  }
}

.overlay-black:before {
  background-color: #161c2d
}

.overlay-gradient-black-end:before {
  background-image: linear-gradient(180deg, transparent, #161c2d)
}

@media (min-width: 992px) {
  .overlay-gradient-black-end:before {
    background-image: linear-gradient(90deg, transparent, #161c2d 50%, #161c2d)
  }
}

.overlay-gradient-black-down:before, .overlay-gradient-black-start:before {
  background-image: linear-gradient(180deg, transparent, #161c2d)
}

@media (min-width: 992px) {
  .overlay-gradient-black-start:before {
    background-image: linear-gradient(270deg, transparent, #161c2d 50%, #161c2d)
  }
}

.overlay-white:before {
  background-color: #fff
}

.overlay-gradient-white-end:before {
  background-image: linear-gradient(180deg, transparent, #fff)
}

@media (min-width: 992px) {
  .overlay-gradient-white-end:before {
    background-image: linear-gradient(90deg, transparent, #fff 50%, #fff)
  }
}

.overlay-gradient-white-down:before, .overlay-gradient-white-start:before {
  background-image: linear-gradient(180deg, transparent, #fff)
}

@media (min-width: 992px) {
  .overlay-gradient-white-start:before {
    background-image: linear-gradient(270deg, transparent, #fff 50%, #fff)
  }
}

.overlay-10:before {
  opacity: .1
}

.overlay-20:before {
  opacity: .2
}

.overlay-30:before {
  opacity: .3
}

.overlay-40:before {
  opacity: .4
}

.overlay-50:before {
  opacity: .5
}

.overlay-60:before {
  opacity: .6
}

.overlay-70:before {
  opacity: .7
}

.overlay-80:before {
  opacity: .8
}

.overlay-90:before {
  opacity: .9
}

.center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.shadow-multicolor {
  box-shadow: -300px 100px 300px rgba(80, 26, 232, .2), 300px -100px 300px rgba(36, 161, 252, .2)
}

@media (min-width: 576px) {
  .w-cover {
    width: calc(100% + 50vw - 270px + 2.5rem)
  }
}

@media (min-width: 768px) {
  .w-cover {
    width: calc(100% + 50vw - 360px + 2.5rem)
  }
}

@media (min-width: 992px) {
  .w-cover {
    width: calc(100% + 50vw - 480px + 2.5rem)
  }
}

@media (min-width: 1200px) {
  .w-cover {
    width: calc(100% + 50vw - 520px + 2.5rem)
  }
}

@media (max-width: 991.98px) {
  .font-size-lg {
    font-size: 1.0625rem !important
  }
}

html {
  overflow-x: hidden
}

@media (hover: none) {
  body {
    overflow-x: hidden
  }
}

@font-face {
  font-family: HKGroteskPro;
  font-weight: 400;
  src: url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff2) format("woff2"), url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Regular.woff) format("woff")
}

@font-face {
  font-family: HKGroteskPro;
  font-weight: 600;
  src: url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff2) format("woff2"), url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Medium.woff) format("woff")
}

@font-face {
  font-family: HKGroteskPro;
  font-weight: 700;
  src: url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff2) format("woff2"), url(../fonts/HK%20Grotesk%20Pro/HKGroteskPro-Bold.woff) format("woff")
}

@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Feather/Feather.ttf?sdxovp) format("truetype"), url(../fonts/Feather/Feather.woff?sdxovp) format("woff"), url(../fonts/Feather/Feather.svg?sdxovp#Feather) format("svg")
}

.h1, .h2, h1, h2 {
  margin-bottom: 1rem
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4 {
  letter-spacing: -.01em
}

.h1, h1 {
  line-height: 1.3
}

@media (max-width: 991.98px) {
  .h1, h1 {
    font-size: 1.875rem
  }
}

.h2, h2 {
  line-height: 1.35
}

@media (max-width: 991.98px) {
  .h2, h2 {
    font-size: 1.5rem
  }
}

.h3, h3 {
  line-height: 1.4
}

@media (max-width: 991.98px) {
  .h3, h3 {
    font-size: 1.125rem
  }
}

.h4, h4 {
  line-height: 1.45
}

@media (max-width: 991.98px) {
  .h4, h4 {
    font-size: 1.0625rem
  }
}

.h5, h5 {
  line-height: 1.5
}

.h6, h6 {
  line-height: 1.55
}

.h6.text-uppercase, .text-uppercase.h6, h6.text-uppercase {
  letter-spacing: .08em
}

.display-1, .display-2, .display-3, .display-4 {
  letter-spacing: -.02em
}

.display-1 {
  line-height: 1.2
}

@media (max-width: 991.98px) {
  .display-1 {
    font-size: 3.125rem
  }
}

.display-2 {
  line-height: 1.2
}

@media (max-width: 991.98px) {
  .display-2 {
    font-size: 2.5rem
  }
}

.display-3 {
  line-height: 1.2
}

@media (max-width: 991.98px) {
  .display-3 {
    font-size: 2.25rem
  }
}

.display-4 {
  line-height: 1.2
}

@media (max-width: 991.98px) {
  .display-4 {
    font-size: 2rem
  }
  .lead {
    font-size: 1.1875rem
  }
}

.blockquote-img {
  max-height: 2.5rem;
  max-width: 100%;
  width: auto
}

.blockquote-footer {
  margin-top: 0
}

.blockquote-footer:before {
  display: none
}

.list-social-icon {
  height: auto;
  max-width: 1.25rem
}

.hr-sm {
  width: 60px
}

.hr-sm:not([size]) {
  height: 2px
}

.hr-md {
  width: 100px
}

.hr-md:not([size]) {
  height: 2px
}

code {
  line-height: 1.35
}

.img-cover {
  height: auto;
  margin-left: calc(50% - 50vw);
  width: 100vw
}

.img-fluid > img, .img-fluid > svg {
  height: inherit;
  max-width: inherit
}

.figure {
  display: block
}

.figure-img {
  margin-bottom: 1rem
}

[class^=container] {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
  z-index: 1
}

[class^=col] {
  position: relative
}

.table-align-middle tbody > tr > td, .table-align-middle thead > tr > th {
  vertical-align: middle
}

.form-floating > label {
  color: #869ab8
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  font-size: .75rem
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  font-size: .75rem
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  font-size: .75rem
}

.form-check {
  margin-bottom: 0
}

.form-check + .form-check {
  margin-top: .125rem
}

.form-check-dark .form-check-input {
  background-color: hsla(0, 0%, 100%, .2)
}

.form-check-dark .form-check-input:checked {
  background-color: #fff
}

.form-switch {
  min-height: 1.375rem
}

.form-switch > .form-check-input {
  height: 1.375rem
}

.form-switch > .form-check-label {
  margin-top: calc(.6875rem - .8em)
}

.form-check-dark.form-switch .form-check-input, .form-check-dark.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-dark.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23142840'/%3E%3C/svg%3E")
}

.form-control-xs {
  font-size: .9375rem;
  line-height: 1.6;
  min-height: calc(2rem + 2px);
  padding: .25rem .625rem
}

.form-control-flush, .form-floating > .form-control-flush {
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
  border-top-width: 0;
  padding-left: 0;
  padding-right: 0
}

.form-floating > .form-control-flush ~ label {
  border-width: 0;
  padding-left: 0;
  padding-right: 0
}

.form-group {
  margin-bottom: 1rem
}

.form-select-xs {
  background-position: right .625rem center;
  background-size: .75em;
  font-size: .9375rem;
  height: calc(2rem + 2px);
  line-height: 1.6;
  padding: .25rem calc(1.25rem + .75em) .25rem .625rem
}

.input-group-xs .form-control {
  min-height: calc(2rem + 2px)
}

.input-group-xs .form-control, .input-group-xs .input-group-text {
  font-size: .9375rem;
  line-height: 1.6;
  padding: .25rem .625rem
}

.btn > img {
  max-height: 1em;
  width: auto
}

.btn:not([class*=btn-outline]) {
  border-color: transparent !important
}

.btn-xs {
  font-size: .9375rem;
  padding: .25rem .625rem
}

.btn-white {
  background-color: #fff;
  color: #142840
}

.btn-white:focus, .btn-white:hover {
  background-color: #fff;
  color: #0e1c2d
}

.btn-white.active {
  background-color: #f2f2f2;
  color: #0e1c2d
}

.btn-group .btn-white {
  border-color: #d9e2ef !important;
  color: #869ab8
}

.btn-group .btn-white.active {
  background-color: #f2f2f2;
  box-shadow: none;
  color: #768daf
}

.btn-gray-300-20 {
  background-color: rgba(217, 226, 239, .2);
  color: #fff
}

.btn-gray-300-20.active, .btn-gray-300-20:focus, .btn-gray-300-20:hover {
  background-color: rgba(217, 226, 239, .25);
  color: #fff
}

.btn-gray-400 {
  background-color: #c6d3e6;
  color: #fff
}

.btn-gray-400.active, .btn-gray-400:focus, .btn-gray-400:hover {
  background-color: #b4c5de;
  color: #f2f2f2
}

.btn-gray-400-10 {
  background-color: rgba(198, 211, 230, .1);
  color: #fff
}

.btn-gray-400-10.active, .btn-gray-400-10:focus, .btn-gray-400-10:hover {
  background-color: rgba(198, 211, 230, .15);
  color: #fff
}

.btn-outline-white {
  border-color: #d9e2ef;
  color: #161c2d
}

.btn-outline-white.active, .btn-outline-white:focus, .btn-outline-white:hover {
  background-color: #f2f2f2;
  border-color: #c6d3e6;
  color: #161c2d
}

.btn-outline-gray-300 {
  border-color: #d9e2ef;
  color: #142840
}

.btn-outline-gray-300.active, .btn-outline-gray-300:focus, .btn-outline-gray-300:hover {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.btn-primary-soft {
  background-color: rgba(20, 40, 64, .1);
  color: #142840
}

.btn-primary-soft.active, .btn-primary-soft:focus, .btn-primary-soft:hover {
  background-color: rgba(20, 40, 64, .15);
  color: #142840
}

.btn-secondary-soft {
  background-color: rgba(80, 102, 144, .1);
  color: #506690
}

.btn-secondary-soft.active, .btn-secondary-soft:focus, .btn-secondary-soft:hover {
  background-color: rgba(80, 102, 144, .15);
  color: #506690
}

.btn-success-soft {
  background-color: rgba(66, 186, 150, .1);
  color: #42ba96
}

.btn-success-soft.active, .btn-success-soft:focus, .btn-success-soft:hover {
  background-color: rgba(66, 186, 150, .15);
  color: #42ba96
}

.btn-info-soft {
  background-color: rgba(4, 149, 169, .1);
  color: #0495a9
}

.btn-info-soft.active, .btn-info-soft:focus, .btn-info-soft:hover {
  background-color: rgba(4, 149, 169, .15);
  color: #0495a9
}

.btn-warning-soft {
  background-color: rgba(250, 215, 118, .1);
  color: #fad776
}

.btn-warning-soft.active, .btn-warning-soft:focus, .btn-warning-soft:hover {
  background-color: rgba(250, 215, 118, .15);
  color: #fad776
}

.btn-danger-soft {
  background-color: rgba(223, 71, 89, .1);
  color: #df4759
}

.btn-danger-soft.active, .btn-danger-soft:focus, .btn-danger-soft:hover {
  background-color: rgba(223, 71, 89, .15);
  color: #df4759
}

.btn-light-soft {
  background-color: rgba(249, 251, 253, .1);
  color: #f9fbfd
}

.btn-light-soft.active, .btn-light-soft:focus, .btn-light-soft:hover {
  background-color: rgba(249, 251, 253, .15);
  color: #f9fbfd
}

.btn-dark-soft {
  background-color: rgba(27, 42, 78, .1);
  color: #1b2a4e
}

.btn-dark-soft.active, .btn-dark-soft:focus, .btn-dark-soft:hover {
  background-color: rgba(27, 42, 78, .15);
  color: #1b2a4e
}

.btn-primary-desat-soft {
  background-color: rgba(108, 138, 236, .1);
  color: #6c8aec
}

.btn-primary-desat-soft.active, .btn-primary-desat-soft:focus, .btn-primary-desat-soft:hover {
  background-color: rgba(108, 138, 236, .15);
  color: #6c8aec
}

.btn-black-soft {
  background-color: rgba(22, 28, 45, .1);
  color: #161c2d
}

.btn-black-soft.active, .btn-black-soft:focus, .btn-black-soft:hover {
  background-color: rgba(22, 28, 45, .15);
  color: #161c2d
}

.btn-white-soft {
  background-color: hsla(0, 0%, 100%, .1);
  color: #fff
}

.btn-white-soft.active, .btn-white-soft:focus, .btn-white-soft:hover {
  background-color: hsla(0, 0%, 100%, .15);
  color: #fff
}

.btn-pill {
  border-radius: 50rem
}

.btn-rounded-circle {
  border-radius: 50rem;
  padding-left: .8125rem;
  padding-right: .8125rem
}

.btn-rounded-circle > * {
  display: block;
  line-height: 1;
  width: 1em
}

.btn-group-lg > .btn-rounded-circle.btn, .btn-rounded-circle.btn-lg {
  padding-left: 1.122rem;
  padding-right: 1.122rem
}

.btn-group-sm > .btn-rounded-circle.btn, .btn-rounded-circle.btn-sm {
  padding-left: .5625rem;
  padding-right: .5625rem
}

.btn-rounded-circle.btn-xs {
  padding-left: .25rem;
  padding-right: .25rem
}

.dropdown-menu {
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

.dropdown-item {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  min-width: 100%;
  width: auto
}

.dropdown-item:focus, .dropdown-item:hover {
  outline: none
}

.dropdown-item + .dropdown-item {
  margin-top: .45rem
}

.dropdown-link {
  color: inherit
}

.dropdown-link:hover {
  text-decoration: inherit
}

.dropdown-link:focus, .dropdown-link:hover {
  outline: none
}

.dropdown-header {
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: .08em;
  line-height: 1.55;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
  padding-bottom: .5rem;
  padding-top: 0;
  text-transform: uppercase
}

.dropdown-toggle:after {
  border: none !important;
  content: "\e92e";
  font-family: Feather;
  vertical-align: middle
}

.dropend > .dropdown-toggle:after {
  content: "\e930"
}

.dropdown-menu-xs {
  min-width: 0;
  padding: 1rem 1.25rem
}

.dropdown-menu-md {
  min-width: 22rem
}

.dropdown-menu-lg {
  min-width: 35rem
}

.dropdown-menu-xl {
  min-width: 42rem
}

.dropdown-menu-lg, .dropdown-menu-xl {
  padding: 2.375rem 2.5rem
}

.dropdown-menu-lg .dropdown-item, .dropdown-menu-xl .dropdown-header {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.dropend > .dropdown-menu {
  top: -1.625rem
}

.navbar {
  transition: all .2s ease-in-out;
  transition-property: background-color, color;
  z-index: 1030
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-item .nav-link {
    color: #506690
  }
  .navbar-dark .navbar-nav .nav-item.active .nav-link, .navbar-dark .navbar-nav .nav-item .nav-link.active, .navbar-dark .navbar-nav .nav-item .nav-link:focus, .navbar-dark .navbar-nav .nav-item .nav-link:hover {
    color: #142840
  }
  .navbar-dark .navbar-collapse .navbar-toggler {
    color: #506690
  }
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important
}

.navbar-brand {
  font-weight: 600
}

.navbar-brand-img {
  max-height: 2.4rem;
  width: auto
}

.navbar-dark .navbar-brand {
  filter: brightness(0) invert(1)
}

@media (max-width: 991.98px) {
  .navbar-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 1.0625rem;
    padding: .8125rem 1.25rem;
    width: 100%
  }
}

.navbar-nav .nav-link {
  font-weight: 600
}

.navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
  outline: none
}

.navbar-dark.fixed-top > .container {
  position: relative
}

.navbar-dark.fixed-top > .container:after {
  border-top: 1px solid hsla(0, 0%, 100%, .2);
  bottom: -1.25rem;
  content: "";
  left: 0;
  position: absolute;
  right: 0
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    background-color: #fff;
    border-radius: .375rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15);
    height: auto;
    left: 1rem;
    max-height: calc(100% - 2rem) !important;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 1rem;
    width: calc(100% - 2rem)
  }
  .navbar-collapse.collapsing, .navbar-collapse.show {
    transform-origin: top right;
    transition: all .2s ease-in-out;
    transition-property: opacity, transform
  }
  .navbar-collapse.show {
    opacity: 1;
    transform: scale(1)
  }
  .navbar-collapse.collapsing {
    opacity: 0;
    transform: scale(.9)
  }
}

.navbar .navbar-toggler {
  margin-left: auto
}

.navbar-collapse .navbar-toggler {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1
}

@media (min-width: 992px) {
  .navbar-collapse .navbar-toggler {
    display: none
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .nav-item {
    padding: 1.5rem
  }
  .navbar-collapse .navbar-nav .nav-item + .nav-item {
    border-top: 1px solid #f1f4f8
  }
  .navbar-collapse .navbar-nav .nav-link {
    padding-bottom: 0;
    padding-top: 0
  }
}

.navbar-nav .dropdown-menu {
  box-shadow: none
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .15)
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-menu {
    min-width: 0;
    padding: 0
  }
  .navbar-collapse .navbar-nav .dropdown > .dropdown-menu {
    display: block !important
  }
  .navbar-collapse .navbar-nav .dropend > .dropdown-menu {
    padding-bottom: 1rem;
    padding-left: .5rem;
    padding-top: 1rem
  }
}

@media (min-width: 992px) {
  .navbar-collapse .navbar-nav .dropend > .dropdown-menu {
    left: 100%;
    right: auto
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown-header, .navbar-collapse .navbar-nav .dropdown-item {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0
  }
}

.navbar-nav .dropdown > .dropdown-toggle:after {
  display: none
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropdown > .dropdown-toggle {
    margin-bottom: 1.5rem;
    pointer-events: none
  }
}

.navbar-nav .dropend > .dropdown-toggle {
  display: flex
}

.navbar-nav .dropend > .dropdown-toggle:after {
  margin-left: auto
}

@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav .dropend > .dropdown-toggle:after {
    content: "\e92e"
  }
  .navbar-collapse .navbar-nav .dropend > .dropdown-toggle[aria-expanded=true]:after {
    transform: rotate(180deg);
    transform-origin: center center
  }
}

.navbar-nav .dropdown-img-start {
  align-items: center;
  background: no-repeat 50%/cover;
  border-bottom-left-radius: .375rem;
  border-top-left-radius: .375rem;
  display: none;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1.625rem 1.75rem
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-img-start {
    display: flex
  }
}

.navbar-nav .dropdown-img-start:before {
  background-color: rgba(20, 40, 64, .8);
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.navbar-nav .dropdown-img-start * {
  position: relative
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu-lg .dropdown-img-start, .navbar-nav .dropdown-menu-xl .dropdown-img-start {
    padding: 2.375rem 2.5rem
  }
  .navbar-nav .dropdown-body {
    padding: 1.625rem 1.75rem
  }
  .navbar-nav .dropdown-menu-lg .dropdown-body, .navbar-nav .dropdown-menu-xl .dropdown-body {
    padding: 2.375rem 2.5rem
  }
}

.navbar-nav .dropdown-menu .list-group-item {
  align-items: center;
  color: inherit;
  display: flex;
  padding-bottom: 1rem;
  padding-top: 1rem
}

.navbar-nav .dropdown-menu .list-group-item:hover {
  text-decoration: none
}

@media (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transition: all .2s ease-in-out;
    transition-property: opacity, transform
  }
  .navbar-nav .dropend > .dropdown-menu {
    transform: translateY(10px)
  }
  .navbar-nav .dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 10px)
  }
  .navbar-nav .dropdown-menu.showing {
    display: block
  }
  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1
  }
  .navbar-nav .dropend > .dropdown-menu.show {
    transform: translateY(0)
  }
  .navbar-nav .dropdown > .dropdown-menu.show {
    transform: translate(-50%)
  }
}

.card {
  position: relative;
  width: 100%
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f4f8
}

.card-body {
  display: block;
  flex-grow: 0
}

.card-footer {
  background-color: unset
}

.card-body, .card-footer, .card-img, .card-img-end, .card-img-start, .card-img-top, .card-meta {
  min-height: 1px;
  position: relative
}

a.card, a.card-body, a.card-footer, a.card-meta {
  color: inherit
}

a.card-body:hover, a.card-footer:hover, a.card-meta:hover, a.card:hover {
  text-decoration: none
}

.card-flush {
  background-color: unset
}

.card-flush > :not(.card-btn) {
  padding-left: 0;
  padding-right: 0
}

.card-border {
  position: relative
}

.card-border:after {
  border-bottom: calc(.375rem - 2px) solid transparent;
  border-top-color: inherit;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
  border-top-style: solid;
  border-top-width: 2px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.card-border-lg:after {
  border-bottom-width: calc(.375rem - 3px);
  border-top-width: 3px
}

.card-border-xl:after {
  border-bottom-width: calc(.375rem - 3px);
  border-top-width: 4px
}

@media (min-width: 768px) {
  .card-row .card-body {
    padding: 4rem 2.5rem
  }
  .card-row .card-meta {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }
}

.card-img-end {
  border-radius: .375rem .375rem 0 0
}

@media (min-width: 768px) {
  .card-img-end {
    border-radius: 0 .375rem .375rem 0
  }
}

.card-img-start {
  border-radius: .375rem .375rem 0 0
}

@media (min-width: 768px) {
  .card-img-start {
    border-radius: .375rem 0 0 .375rem
  }
  .card-img-slider {
    height: 100%;
    width: 100%
  }
  .card-img-slider * {
    height: inherit !important;
    width: inherit !important
  }
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  padding: 2rem
}

.card-img-overlay .card-body {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
  margin: auto -2rem -2rem
}

.card-img-overlay-hover {
  overflow: hidden
}

.card-img-overlay-hover .card-body {
  transform: translateY(110%);
  transition: all .3s ease
}

.card-img-overlay-hover:hover .card-body {
  transform: translateY(0)
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-group {
    flex-direction: column !important
  }
  .card-group > .card {
    flex: auto
  }
}

.card-meta {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 1.5rem
}

.card-meta-divider {
  margin: 0 0 1.5rem;
  width: 100%
}

.card-btn:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.card-zoom {
  border-radius: inherit;
  overflow: hidden !important
}

.card-zoom > [class*=card-img] {
  transform-origin: center center;
  transition: all .3s ease
}

.card:hover > .card-zoom > [class*=card-img] {
  transform: scale(1.1)
}

.card-list .list-link {
  position: relative
}

.card-list .list-link:before {
  border-right: 2px solid #142840;
  bottom: 0;
  content: "";
  display: none;
  left: -2rem;
  position: absolute;
  top: 0
}

.card-list .active .list-link, .card-list .list-link:hover {
  color: #161c2d !important
}

.card-list .active .list-link:before {
  display: block
}

@media (max-width: 767.98px) {
  .card-bleed {
    border-radius: 0;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    min-width: 100%;
    width: auto
  }
  .card-bleed .card-body, .card-bleed .card-footer, .card-bleed .card-header {
    border-radius: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem
  }
  .card-bleed .card-list .list-link:before {
    left: -1.25rem
  }
}

.accordion-button:not(.collapsed) {
  box-shadow: none
}

.accordion-button:not([data-bs-toggle=collapse]):after {
  display: none
}

.accordion-button:after {
  margin-left: 1rem
}

.accordion-body {
  padding-top: 0
}

.breadcrumb-item {
  font-size: .9375rem
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "\e930";
  font-family: Feather
}

.breadcrumb-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto
}

.breadcrumb-scroll .breadcrumb-item {
  white-space: nowrap
}

.breadcrumb-scroll::-webkit-scrollbar {
  display: none
}

.pagination-sm .page-link {
  font-size: 1.0625rem;
  line-height: 1.6
}

.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: .375rem;
  border-top-left-radius: .375rem
}

.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: .375rem;
  border-top-right-radius: .375rem
}

.pagination-lg .page-link {
  font-size: 1.0625rem;
  line-height: 1.6
}

.pagination-lg .page-item:first-child .page-link {
  border-bottom-left-radius: .375rem;
  border-top-left-radius: .375rem
}

.pagination-lg .page-item:last-child .page-link {
  border-bottom-right-radius: .375rem;
  border-top-right-radius: .375rem
}

.badge[href]:hover {
  text-decoration: none
}

.badge-lg {
  padding: .7em 1.15em
}

.badge-rounded-circle {
  border-radius: 50rem;
  height: calc(1em + .5rem);
  padding-left: .25rem;
  padding-right: .25rem
}

.badge-rounded-circle > * {
  display: block;
  width: 1em
}

.badge-rounded-circle.badge-lg {
  height: 2.4em;
  padding-left: .7em;
  padding-right: .7em
}

.badge-float {
  position: absolute;
  z-index: 1000
}

.badge-float-inside {
  right: 1rem;
  top: 1rem
}

.badge-float-outside {
  right: -.5rem;
  top: -.5rem
}

.badge.bg-white-soft.active {
  background-color: #fff !important;
  color: #142840 !important
}

.badge.bg-white-soft.active:focus, .badge.bg-white-soft.active:hover {
  background-color: #f2f2f2;
  color: #142840
}

.badge.bg-secondary-soft.active {
  background-color: #142840 !important;
  color: #fff !important
}

.badge.bg-secondary-soft.active:focus, .badge.bg-secondary-soft.active:hover {
  background-color: #0e1c2d;
  color: #fff
}

.badge.bg-primary-soft {
  background-color: rgba(20, 40, 64, .1);
  color: #142840
}

.badge.bg-primary-soft[href]:focus, .badge.bg-primary-soft[href]:hover {
  background-color: rgba(20, 40, 64, .2);
  color: #142840
}

.badge.bg-secondary-soft {
  background-color: rgba(80, 102, 144, .1);
  color: #506690
}

.badge.bg-secondary-soft[href]:focus, .badge.bg-secondary-soft[href]:hover {
  background-color: rgba(80, 102, 144, .2);
  color: #506690
}

.badge.bg-success-soft {
  background-color: rgba(66, 186, 150, .1);
  color: #42ba96
}

.badge.bg-success-soft[href]:focus, .badge.bg-success-soft[href]:hover {
  background-color: rgba(66, 186, 150, .2);
  color: #42ba96
}

.badge.bg-info-soft {
  background-color: rgba(4, 149, 169, .1);
  color: #0495a9
}

.badge.bg-info-soft[href]:focus, .badge.bg-info-soft[href]:hover {
  background-color: rgba(4, 149, 169, .2);
  color: #0495a9
}

.badge.bg-warning-soft {
  background-color: rgba(250, 215, 118, .1);
  color: #fad776
}

.badge.bg-warning-soft[href]:focus, .badge.bg-warning-soft[href]:hover {
  background-color: rgba(250, 215, 118, .2);
  color: #fad776
}

.badge.bg-danger-soft {
  background-color: rgba(223, 71, 89, .1);
  color: #df4759
}

.badge.bg-danger-soft[href]:focus, .badge.bg-danger-soft[href]:hover {
  background-color: rgba(223, 71, 89, .2);
  color: #df4759
}

.badge.bg-light-soft {
  background-color: rgba(249, 251, 253, .1);
  color: #f9fbfd
}

.badge.bg-light-soft[href]:focus, .badge.bg-light-soft[href]:hover {
  background-color: rgba(249, 251, 253, .2);
  color: #f9fbfd
}

.badge.bg-dark-soft {
  background-color: rgba(27, 42, 78, .1);
  color: #1b2a4e
}

.badge.bg-dark-soft[href]:focus, .badge.bg-dark-soft[href]:hover {
  background-color: rgba(27, 42, 78, .2);
  color: #1b2a4e
}

.badge.bg-primary-desat-soft {
  background-color: rgba(108, 138, 236, .1);
  color: #6c8aec
}

.badge.bg-primary-desat-soft[href]:focus, .badge.bg-primary-desat-soft[href]:hover {
  background-color: rgba(108, 138, 236, .2);
  color: #6c8aec
}

.badge.bg-black-soft {
  background-color: rgba(22, 28, 45, .1);
  color: #161c2d
}

.badge.bg-black-soft[href]:focus, .badge.bg-black-soft[href]:hover {
  background-color: rgba(22, 28, 45, .2);
  color: #161c2d
}

.badge.bg-white-soft {
  background-color: hsla(0, 0%, 100%, .1);
  color: #fff
}

.badge.bg-white-soft[href]:focus, .badge.bg-white-soft[href]:hover {
  background-color: hsla(0, 0%, 100%, .2);
  color: #fff
}

.badge.bg-gray-700-soft {
  background-color: rgba(80, 102, 144, .1);
  color: #506690
}

.badge.bg-gray-700-soft[href]:focus, .badge.bg-gray-700-soft[href]:hover {
  background-color: rgba(80, 102, 144, .2);
  color: #506690
}

.badge.bg-gray-600 {
  background-color: #869ab8;
  color: #fff
}

.badge.bg-info, .badge.bg-primary, .badge.bg-secondary, .badge.bg-success {
  color: #fff
}

.badge.bg-warning {
  color: #1b2a4e
}

.badge.bg-danger {
  color: #fff
}

.badge.bg-light {
  color: #1b2a4e
}

.badge.bg-black, .badge.bg-dark, .badge.bg-primary-desat {
  color: #fff
}

.badge.bg-white {
  color: #1b2a4e
}

.alert-primary {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.alert-primary .alert-link {
  color: #ccc
}

.alert-primary .alert-link, .alert-primary .btn-close {
  color: #fff
}

.alert-primary hr {
  background-color: #0e1c2d
}

.alert-secondary {
  background-color: #506690;
  border-color: #506690;
  color: #fff
}

.alert-secondary .alert-link {
  color: #ccc
}

.alert-secondary .alert-link, .alert-secondary .btn-close {
  color: #fff
}

.alert-secondary hr {
  background-color: #475a80
}

.alert-success {
  background-color: #42ba96;
  border-color: #42ba96;
  color: #fff
}

.alert-success .alert-link {
  color: #ccc
}

.alert-success .alert-link, .alert-success .btn-close {
  color: #fff
}

.alert-success hr {
  background-color: #3ba787
}

.alert-info {
  background-color: #0495a9;
  border-color: #0495a9;
  color: #fff
}

.alert-info .alert-link {
  color: #ccc
}

.alert-info .alert-link, .alert-info .btn-close {
  color: #fff
}

.alert-info hr {
  background-color: #037f90
}

.alert-warning {
  background-color: #fad776;
  border-color: #fad776;
  color: #1b2a4e
}

.alert-warning .alert-link {
  color: #16223e
}

.alert-warning .alert-link, .alert-warning .btn-close {
  color: #1b2a4e
}

.alert-warning hr {
  background-color: #f9d05d
}

.alert-danger {
  background-color: #df4759;
  border-color: #df4759;
  color: #fff
}

.alert-danger .alert-link {
  color: #ccc
}

.alert-danger .alert-link, .alert-danger .btn-close {
  color: #fff
}

.alert-danger hr {
  background-color: #db3145
}

.alert-light {
  background-color: #f9fbfd;
  border-color: #f9fbfd;
  color: #1b2a4e
}

.alert-light .alert-link {
  color: #16223e
}

.alert-light .alert-link, .alert-light .btn-close {
  color: #1b2a4e
}

.alert-light hr {
  background-color: #e6eef7
}

.alert-dark {
  background-color: #1b2a4e;
  border-color: #1b2a4e;
  color: #fff
}

.alert-dark .alert-link {
  color: #ccc
}

.alert-dark .alert-link, .alert-dark .btn-close {
  color: #fff
}

.alert-dark hr {
  background-color: #14203b
}

.alert-primary-desat {
  background-color: #6c8aec;
  border-color: #6c8aec;
  color: #fff
}

.alert-primary-desat .alert-link {
  color: #ccc
}

.alert-primary-desat .alert-link, .alert-primary-desat .btn-close {
  color: #fff
}

.alert-primary-desat hr {
  background-color: #5578e9
}

.alert-black {
  background-color: #161c2d;
  border-color: #161c2d;
  color: #fff
}

.alert-black .alert-link {
  color: #ccc
}

.alert-black .alert-link, .alert-black .btn-close {
  color: #fff
}

.alert-black hr {
  background-color: #0e111c
}

.alert-white {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.alert-white .alert-link {
  color: #16223e
}

.alert-white .alert-link, .alert-white .btn-close {
  color: #1b2a4e
}

.alert-white hr {
  background-color: #f2f2f2
}

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0
}

.list-group-flush .list-group-item:first-child {
  border-top: 0;
  padding-top: 0 !important
}

.list-group-flush .list-group-item:last-child {
  border-bottom: 0;
  padding-bottom: 0 !important
}

.btn-close:focus, .btn-close:hover {
  outline: none
}

.modal-open .navbar.fixed-top {
  padding-right: inherit
}

.modal .btn-close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 1000
}

.popover-header {
  color: #142840;
  font-size: .75rem;
  margin-bottom: .5rem;
  padding-bottom: 0
}

.popover-header:not(:empty) ~ .popover-body {
  padding-top: 0
}

.avatar {
  display: inline-block;
  font-size: .625rem;
  height: 1.875rem;
  position: relative;
  width: 1.875rem
}

.avatar:after {
  background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg);
  content: "";
  height: 0;
  position: absolute;
  width: 0
}

.avatar-img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.avatar-title {
  align-items: center;
  background-color: #abbcd5;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%
}

.avatar-offline:before, .avatar-online:before {
  border-radius: 50%;
  bottom: 5%;
  content: "";
  height: 20%;
  position: absolute;
  right: 5%;
  width: 20%
}

.avatar-offline .avatar-img, .avatar-online .avatar-img {
  -webkit-mask-image: url(../img/masks/avatar-status.svg);
  mask-image: url(../img/masks/avatar-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-online:before {
  background-color: #42ba96
}

.avatar-offline:before {
  background-color: #abbcd5
}

.avatar-xs {
  font-size: .33333rem;
  height: 1rem;
  width: 1rem
}

.avatar-sm {
  font-size: .5rem;
  height: 1.5rem;
  width: 1.5rem
}

.avatar-lg, .avatar-xl {
  font-size: .75rem;
  height: 2.25rem;
  width: 2.25rem
}

@media (min-width: 992px) {
  .avatar-xl {
    font-size: 1.33333rem;
    height: 4rem;
    width: 4rem
  }
}

.avatar-xxl {
  font-size: 1.33333rem;
  height: 4rem;
  width: 4rem
}

@media (min-width: 992px) {
  .avatar-xxl {
    font-size: 1.66667rem;
    height: 5rem;
    width: 5rem
  }
}

.avatar.avatar-4by3 {
  width: 2.5rem
}

.avatar-xs.avatar-4by3 {
  width: 1.33333rem
}

.avatar-sm.avatar-4by3 {
  width: 2rem
}

.avatar-lg.avatar-4by3 {
  width: 3rem
}

.avatar-xl.avatar-4by3 {
  width: 5.33333rem
}

.avatar-xxl.avatar-4by3 {
  width: 6.66667rem
}

.avatar-group {
  display: inline-flex
}

.avatar-group .avatar + .avatar {
  margin-left: -.46875rem
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.25rem
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -.375rem
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -.5625rem
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1rem
}

.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -1.25rem
}

.avatar-group .avatar:not(:last-child) {
  -webkit-mask-image: url(../img/masks/avatar-group.svg);
  mask-image: url(../img/masks/avatar-group.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-group .avatar:hover {
  -webkit-mask-image: none;
  mask-image: none;
  z-index: 1
}

.avatar-group .avatar:hover + .avatar {
  -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
  mask-image: url(../img/masks/avatar-group-hover.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-group .avatar:hover + .avatar:last-child {
  -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
  mask-image: url(../img/masks/avatar-group-hover-last.svg)
}

.device, .device > .img-fluid {
  position: relative
}

.device:before {
  background-color: #f9fbfd;
  content: ""
}

.device-screen, .device:before {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute
}

.device-iphonex:before, .device-iphonex > .device-screen {
  height: 80.838323353%;
  left: 16.4021164%;
  top: 7.784431138%;
  width: 66.137566137%
}

.device-macbook:before, .device-macbook > .device-screen {
  height: 73.076923076%;
  left: 13.38709677%;
  top: 11.53846154%;
  width: 73.548387096%
}

.device-combo {
  position: relative
}

.device-combo > .device {
  position: absolute
}

.device-combo-iphonex-iphonex {
  padding-bottom: 130.250482%
}

.device-combo-iphonex-iphonex > .device-iphonex:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1
}

.device-combo-iphonex-iphonex > .device-iphonex:last-child {
  right: 0;
  top: 0;
  width: 72.8323699%
}

.device-combo-iphonex-macbook, .device-combo-macbook-iphonex {
  padding-bottom: 62.4260355%
}

.device-combo-iphonex-macbook > .device-macbook, .device-combo-macbook-iphonex > .device-macbook {
  width: 91.7159763%
}

.device-combo-iphonex-macbook > .device-iphonex, .device-combo-macbook-iphonex > .device-iphonex {
  width: 27.9585799%;
  z-index: 1
}

.device-combo-macbook-iphonex > .device-macbook {
  left: 0;
  top: 0
}

.device-combo-iphonex-macbook > .device-macbook {
  right: 0;
  top: 0
}

.device-combo-macbook-iphonex > .device-iphonex {
  bottom: 0;
  right: 0
}

.device-combo-iphonex-macbook > .device-iphonex {
  bottom: 0;
  left: 0
}

.bg-dark .footer-brand {
  filter: brightness(0) invert(1)
}

.icon > svg {
  height: 3rem;
  width: 3rem
}

.icon[class*=text-] > svg [fill]:not([fill=none]) {
  fill: currentColor !important
}

.icon-xs > svg {
  height: 1.5rem;
  width: 1.5rem
}

.icon-sm > svg {
  height: 2.25rem;
  width: 2.25rem
}

.icon-lg > svg {
  height: 4rem;
  width: 4rem
}

.icon-xl > svg {
  height: 5rem;
  width: 5rem
}

.icon-circle {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 4.1875rem;
  justify-content: center;
  width: 4.1875rem
}

.icon-circle > .fe {
  font-size: 1.875rem
}

.list {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0
}

.list-item + .list-item {
  margin-top: .35rem
}

.list-link {
  align-items: center;
  color: #506690;
  display: flex;
  flex-wrap: nowrap;
  font-size: .9375rem
}

.list-link:focus, .list-link:hover {
  color: #142840;
  text-decoration: none
}

.screenshot {
  border-radius: .625rem;
  box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, .1), 16px 40px 75px -40px rgba(0, 0, 0, .2)
}

.section-border {
  position: relative
}

.section-border:before {
  border-color: inherit;
  border-style: solid;
  border-width: 2px 0 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.shape {
  pointer-events: none;
  position: absolute
}

.shape > * {
  display: block
}

.shape:not([class*=shape-blur]) {
  overflow: hidden
}

.shape:not([class*=shape-blur]) > * {
  transform: scale(2)
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .shape > svg {
    display: none
  }
}

.shape-top {
  left: 0;
  right: 0;
  top: 0
}

.shape-top > * {
  transform-origin: bottom center
}

.shape-end {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.shape-end > * {
  bottom: inherit;
  position: inherit;
  right: inherit;
  top: inherit;
  transform-origin: center left
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0
}

.shape-bottom > * {
  transform-origin: top center
}

.shape-bottom-100 {
  bottom: 100%;
  left: 0;
  right: 0
}

.shape-bottom-100 > * {
  transform-origin: top center
}

.shape-start {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.shape-start > * {
  bottom: inherit;
  left: inherit;
  position: inherit;
  top: inherit;
  transform-origin: center right
}

.shape-fluid-x > * {
  height: auto;
  width: 100%
}

.shape-fluid-y > * {
  height: 100%;
  width: auto
}

.shape-flip-x {
  transform: scaleX(-1)
}

.shape-blur-1 {
  left: 0;
  right: 0;
  top: 0
}

@media (min-width: 992px) {
  .shape-blur-1 {
    bottom: 0;
    left: 50%;
    right: auto
  }
}

.shape-blur-1 > * {
  height: auto;
  width: 100%
}

@media (min-width: 992px) {
  .shape-blur-1 > * {
    height: 100%;
    width: auto
  }
}

.shape-blur-2 {
  left: 0;
  right: 0;
  top: 0
}

@media (min-width: 992px) {
  .shape-blur-2 {
    bottom: 0;
    left: auto;
    right: 50%
  }
}

.shape-blur-2 > * {
  height: auto;
  width: 100%
}

@media (min-width: 992px) {
  .shape-blur-2 > * {
    float: right;
    height: 100%;
    width: auto
  }
}

.shape-blur-3 {
  left: 0;
  right: 0;
  top: 0
}

.shape-blur-3 > * {
  height: auto;
  width: 100%
}

.shape-blur-4 {
  bottom: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%) scale(1.3);
  width: 100%
}

.shape-blur-4 > * {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: auto
}

@media (min-width: 992px) {
  .sidenav {
    height: calc(100vh - 84px);
    overflow: auto;
    position: fixed;
    top: 84px
  }
  .sidenav-start {
    left: 0
  }
  .sidenav-end {
    right: 0
  }
}

[data-aos=img-skewed-item-end], [data-aos=img-skewed-item-start] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transition-property: opacity, transform;
  will-change: opacity, transform, -webkit-transform
}

[data-aos=img-skewed-item-end].aos-animate, [data-aos=img-skewed-item-start].aos-animate {
  opacity: 1
}

[data-aos=img-skewed-item-start] {
  transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-start].aos-animate {
  transform: rotateY(-35deg) rotateX(15deg) translateZ(0)
}

[data-aos=img-skewed-item-end] {
  transform: rotateY(20deg) rotateX(5deg) translate3d(0, 100px, 0)
}

[data-aos=img-skewed-item-end].aos-animate {
  transform: rotateY(35deg) rotateX(15deg) translateZ(0)
}

#bp_container {
  background: hsla(0, 0%, 100%, .873) !important
}

#bp_container > * {
  box-shadow: none !important
}

.bp-x {
  display: none
}

.choices {
  cursor: pointer;
  position: relative
}

.choices:focus {
  outline: none
}

.choices:after {
  display: none
}

.choices > .dropdown-menu {
  width: 100%
}

.choices > .dropdown-menu [class^=form-control] {
  margin-bottom: 1rem;
  width: 100%
}

.choices > .dropdown-menu > .dropdown-item.is-highlighted {
  color: #142840
}

.choices > .form-control-xs + .dropdown-menu, .choices > .form-select-xs + .dropdown-menu {
  min-width: 100%;
  padding: 1rem 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: auto
}

.choices > .form-control-xs + .dropdown-menu > .dropdown-item, .choices > .form-select-xs + .dropdown-menu > .dropdown-item {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: -1.25rem
}

.choices > .form-control-lg + .dropdown-menu, .choices > .form-select-lg + .dropdown-menu {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.choices > .form-control-lg + .dropdown-menu > .dropdown-item, .choices > .form-select-lg + .dropdown-menu > .dropdown-item {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding-left: -2.5rem
}

.choices__placeholder {
  color: #869ab8;
  opacity: 1
}

.choices.is-open > .form-control, .choices.is-open > .form-select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.choices.is-open > .form-control + .dropdown-menu, .choices.is-open > .form-select + .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0
}

.choices__list--multiple + .form-control {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  display: inline-block;
  font-size: 1.0625rem;
  min-height: 0;
  padding: 0;
  width: auto
}

.form-control-lg + .choices__list--multiple + .form-control, .form-control-sm + .choices__list--multiple + .form-control, .form-select-lg + .choices__list--multiple + .form-control, .form-select-sm + .choices__list--multiple + .form-control {
  font-size: 1.0625rem
}

.choices__list--multiple {
  display: inline
}

.choices__list--multiple > .choices__item {
  background-color: #f9fbfd;
  border-radius: .25rem;
  display: inline-block;
  font-size: .9375rem;
  margin-right: .2rem;
  padding-left: .375rem;
  padding-right: .375rem
}

.choices__list--multiple > .choices__item > .choices__button {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23161C2D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 6 6 18M6 6l12 12'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-width: 0;
  margin-left: .5rem;
  opacity: .5;
  outline: none;
  padding: 0;
  text-indent: -9999px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: .5rem
}

.choices__list--multiple > .choices__item:hover > .choices__button {
  opacity: 1
}

.dropzone {
  display: flex;
  flex-direction: column;
  position: relative
}

.dz-message {
  background-color: #fff;
  border: 1px dashed #c6d3e6;
  border-radius: .375rem;
  color: #869ab8;
  cursor: pointer;
  order: -1;
  padding: 5rem 1rem;
  text-align: center;
  transition: all .2s ease-in-out;
  z-index: 999
}

.dz-message:hover {
  border-color: #869ab8;
  color: #161c2d
}

.dz-button {
  background: none;
  border: 0;
  color: inherit;
  font-size: inherit
}

.dz-drag-hover .dz-message {
  border-color: #142840;
  color: #142840
}

.dropzone-multiple .dz-message {
  padding-bottom: 2rem;
  padding-top: 2rem
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(22, 28, 45, .9);
  color: #fff;
  opacity: 0
}

.dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 1
}

.dz-preview-cover, .dz-preview-single {
  border-radius: .375rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.dz-preview-img {
  border-radius: .375rem;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.dz-preview-multiple .list-group-item:last-child {
  border-bottom: 0;
  padding-bottom: 0
}

.fe, [data-dz-size] strong {
  font-weight: 400
}

.fe {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Feather !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none
}

.fe-activity:before {
  content: "\e900"
}

.fe-airplay:before {
  content: "\e901"
}

.fe-alert-circle:before {
  content: "\e902"
}

.fe-alert-octagon:before {
  content: "\e903"
}

.fe-alert-triangle:before {
  content: "\e904"
}

.fe-align-center:before {
  content: "\e905"
}

.fe-align-justify:before {
  content: "\e906"
}

.fe-align-left:before {
  content: "\e907"
}

.fe-align-right:before {
  content: "\e908"
}

.fe-anchor:before {
  content: "\e909"
}

.fe-aperture:before {
  content: "\e90a"
}

.fe-archive:before {
  content: "\e90b"
}

.fe-arrow-down:before {
  content: "\e90c"
}

.fe-arrow-down-circle:before {
  content: "\e90d"
}

.fe-arrow-down-left:before {
  content: "\e90e"
}

.fe-arrow-down-right:before {
  content: "\e90f"
}

.fe-arrow-left:before {
  content: "\e910"
}

.fe-arrow-left-circle:before {
  content: "\e911"
}

.fe-arrow-right:before {
  content: "\e912"
}

.fe-arrow-right-circle:before {
  content: "\e913"
}

.fe-arrow-up:before {
  content: "\e914"
}

.fe-arrow-up-circle:before {
  content: "\e915"
}

.fe-arrow-up-left:before {
  content: "\e916"
}

.fe-arrow-up-right:before {
  content: "\e917"
}

.fe-at-sign:before {
  content: "\e918"
}

.fe-award:before {
  content: "\e919"
}

.fe-bar-chart:before {
  content: "\e91a"
}

.fe-bar-chart-2:before {
  content: "\e91b"
}

.fe-battery:before {
  content: "\e91c"
}

.fe-battery-charging:before {
  content: "\e91d"
}

.fe-bell:before {
  content: "\e91e"
}

.fe-bell-off:before {
  content: "\e91f"
}

.fe-bluetooth:before {
  content: "\e920"
}

.fe-bold:before {
  content: "\e921"
}

.fe-book:before {
  content: "\e922"
}

.fe-book-open:before {
  content: "\e923"
}

.fe-bookmark:before {
  content: "\e924"
}

.fe-box:before {
  content: "\e925"
}

.fe-briefcase:before {
  content: "\e926"
}

.fe-calendar:before {
  content: "\e927"
}

.fe-camera:before {
  content: "\e928"
}

.fe-camera-off:before {
  content: "\e929"
}

.fe-cast:before {
  content: "\e92a"
}

.fe-check:before {
  content: "\e92b"
}

.fe-check-circle:before {
  content: "\e92c"
}

.fe-check-square:before {
  content: "\e92d"
}

.fe-chevron-down:before {
  content: "\e92e"
}

.fe-chevron-left:before {
  content: "\e92f"
}

.fe-chevron-right:before {
  content: "\e930"
}

.fe-chevron-up:before {
  content: "\e931"
}

.fe-chevrons-down:before {
  content: "\e932"
}

.fe-chevrons-left:before {
  content: "\e933"
}

.fe-chevrons-right:before {
  content: "\e934"
}

.fe-chevrons-up:before {
  content: "\e935"
}

.fe-chrome:before {
  content: "\e936"
}

.fe-circle:before {
  content: "\e937"
}

.fe-clipboard:before {
  content: "\e938"
}

.fe-clock:before {
  content: "\e939"
}

.fe-cloud:before {
  content: "\e93a"
}

.fe-cloud-drizzle:before {
  content: "\e93b"
}

.fe-cloud-lightning:before {
  content: "\e93c"
}

.fe-cloud-off:before {
  content: "\e93d"
}

.fe-cloud-rain:before {
  content: "\e93e"
}

.fe-cloud-snow:before {
  content: "\e93f"
}

.fe-code:before {
  content: "\e940"
}

.fe-codepen:before {
  content: "\e941"
}

.fe-command:before {
  content: "\e942"
}

.fe-compass:before {
  content: "\e943"
}

.fe-copy:before {
  content: "\e944"
}

.fe-corner-down-left:before {
  content: "\e945"
}

.fe-corner-down-right:before {
  content: "\e946"
}

.fe-corner-left-down:before {
  content: "\e947"
}

.fe-corner-left-up:before {
  content: "\e948"
}

.fe-corner-right-down:before {
  content: "\e949"
}

.fe-corner-right-up:before {
  content: "\e94a"
}

.fe-corner-up-left:before {
  content: "\e94b"
}

.fe-corner-up-right:before {
  content: "\e94c"
}

.fe-cpu:before {
  content: "\e94d"
}

.fe-credit-card:before {
  content: "\e94e"
}

.fe-crop:before {
  content: "\e94f"
}

.fe-crosshair:before {
  content: "\e950"
}

.fe-database:before {
  content: "\e951"
}

.fe-delete:before {
  content: "\e952"
}

.fe-disc:before {
  content: "\e953"
}

.fe-dollar-sign:before {
  content: "\e954"
}

.fe-download:before {
  content: "\e955"
}

.fe-download-cloud:before {
  content: "\e956"
}

.fe-droplet:before {
  content: "\e957"
}

.fe-edit:before {
  content: "\e958"
}

.fe-edit-2:before {
  content: "\e959"
}

.fe-edit-3:before {
  content: "\e95a"
}

.fe-external-link:before {
  content: "\e95b"
}

.fe-eye:before {
  content: "\e95c"
}

.fe-eye-off:before {
  content: "\e95d"
}

.fe-facebook:before {
  content: "\e95e"
}

.fe-fast-forward:before {
  content: "\e95f"
}

.fe-feather:before {
  content: "\e960"
}

.fe-file:before {
  content: "\e961"
}

.fe-file-minus:before {
  content: "\e962"
}

.fe-file-plus:before {
  content: "\e963"
}

.fe-file-text:before {
  content: "\e964"
}

.fe-film:before {
  content: "\e965"
}

.fe-filter:before {
  content: "\e966"
}

.fe-flag:before {
  content: "\e967"
}

.fe-folder:before {
  content: "\e968"
}

.fe-folder-minus:before {
  content: "\e969"
}

.fe-folder-plus:before {
  content: "\e96a"
}

.fe-gift:before {
  content: "\e96b"
}

.fe-git-branch:before {
  content: "\e96c"
}

.fe-git-commit:before {
  content: "\e96d"
}

.fe-git-merge:before {
  content: "\e96e"
}

.fe-git-pull-request:before {
  content: "\e96f"
}

.fe-github:before {
  content: "\e970"
}

.fe-gitlab:before {
  content: "\e971"
}

.fe-globe:before {
  content: "\e972"
}

.fe-grid:before {
  content: "\e973"
}

.fe-hard-drive:before {
  content: "\e974"
}

.fe-hash:before {
  content: "\e975"
}

.fe-headphones:before {
  content: "\e976"
}

.fe-heart:before {
  content: "\e977"
}

.fe-help-circle:before {
  content: "\e978"
}

.fe-home:before {
  content: "\e979"
}

.fe-image:before {
  content: "\e97a"
}

.fe-inbox:before {
  content: "\e97b"
}

.fe-info:before {
  content: "\e97c"
}

.fe-instagram:before {
  content: "\e97d"
}

.fe-italic:before {
  content: "\e97e"
}

.fe-layers:before {
  content: "\e97f"
}

.fe-layout:before {
  content: "\e980"
}

.fe-life-buoy:before {
  content: "\e981"
}

.fe-link:before {
  content: "\e982"
}

.fe-link-2:before {
  content: "\e983"
}

.fe-linkedin:before {
  content: "\e984"
}

.fe-list:before {
  content: "\e985"
}

.fe-loader:before {
  content: "\e986"
}

.fe-lock:before {
  content: "\e987"
}

.fe-log-in:before {
  content: "\e988"
}

.fe-log-out:before {
  content: "\e989"
}

.fe-mail:before {
  content: "\e98a"
}

.fe-map:before {
  content: "\e98b"
}

.fe-map-pin:before {
  content: "\e98c"
}

.fe-maximize:before {
  content: "\e98d"
}

.fe-maximize-2:before {
  content: "\e98e"
}

.fe-menu:before {
  content: "\e98f"
}

.fe-message-circle:before {
  content: "\e990"
}

.fe-message-square:before {
  content: "\e991"
}

.fe-mic:before {
  content: "\e992"
}

.fe-mic-off:before {
  content: "\e993"
}

.fe-minimize:before {
  content: "\e994"
}

.fe-minimize-2:before {
  content: "\e995"
}

.fe-minus:before {
  content: "\e996"
}

.fe-minus-circle:before {
  content: "\e997"
}

.fe-minus-square:before {
  content: "\e998"
}

.fe-monitor:before {
  content: "\e999"
}

.fe-moon:before {
  content: "\e99a"
}

.fe-more-horizontal:before {
  content: "\e99b"
}

.fe-more-vertical:before {
  content: "\e99c"
}

.fe-move:before {
  content: "\e99d"
}

.fe-music:before {
  content: "\e99e"
}

.fe-navigation:before {
  content: "\e99f"
}

.fe-navigation-2:before {
  content: "\e9a0"
}

.fe-octagon:before {
  content: "\e9a1"
}

.fe-package:before {
  content: "\e9a2"
}

.fe-paperclip:before {
  content: "\e9a3"
}

.fe-pause:before {
  content: "\e9a4"
}

.fe-pause-circle:before {
  content: "\e9a5"
}

.fe-percent:before {
  content: "\e9a6"
}

.fe-phone:before {
  content: "\e9a7"
}

.fe-phone-call:before {
  content: "\e9a8"
}

.fe-phone-forwarded:before {
  content: "\e9a9"
}

.fe-phone-incoming:before {
  content: "\e9aa"
}

.fe-phone-missed:before {
  content: "\e9ab"
}

.fe-phone-off:before {
  content: "\e9ac"
}

.fe-phone-outgoing:before {
  content: "\e9ad"
}

.fe-pie-chart:before {
  content: "\e9ae"
}

.fe-play:before {
  content: "\e9af"
}

.fe-play-circle:before {
  content: "\e9b0"
}

.fe-plus:before {
  content: "\e9b1"
}

.fe-plus-circle:before {
  content: "\e9b2"
}

.fe-plus-square:before {
  content: "\e9b3"
}

.fe-pocket:before {
  content: "\e9b4"
}

.fe-power:before {
  content: "\e9b5"
}

.fe-printer:before {
  content: "\e9b6"
}

.fe-radio:before {
  content: "\e9b7"
}

.fe-refresh-ccw:before {
  content: "\e9b8"
}

.fe-refresh-cw:before {
  content: "\e9b9"
}

.fe-repeat:before {
  content: "\e9ba"
}

.fe-rewind:before {
  content: "\e9bb"
}

.fe-rotate-ccw:before {
  content: "\e9bc"
}

.fe-rotate-cw:before {
  content: "\e9bd"
}

.fe-rss:before {
  content: "\e9be"
}

.fe-save:before {
  content: "\e9bf"
}

.fe-scissors:before {
  content: "\e9c0"
}

.fe-search:before {
  content: "\e9c1"
}

.fe-send:before {
  content: "\e9c2"
}

.fe-server:before {
  content: "\e9c3"
}

.fe-settings:before {
  content: "\e9c4"
}

.fe-share:before {
  content: "\e9c5"
}

.fe-share-2:before {
  content: "\e9c6"
}

.fe-shield:before {
  content: "\e9c7"
}

.fe-shield-off:before {
  content: "\e9c8"
}

.fe-shopping-bag:before {
  content: "\e9c9"
}

.fe-shopping-cart:before {
  content: "\e9ca"
}

.fe-shuffle:before {
  content: "\e9cb"
}

.fe-sidebar:before {
  content: "\e9cc"
}

.fe-skip-back:before {
  content: "\e9cd"
}

.fe-skip-forward:before {
  content: "\e9ce"
}

.fe-slack:before {
  content: "\e9cf"
}

.fe-slash:before {
  content: "\e9d0"
}

.fe-sliders:before {
  content: "\e9d1"
}

.fe-smartphone:before {
  content: "\e9d2"
}

.fe-speaker:before {
  content: "\e9d3"
}

.fe-square:before {
  content: "\e9d4"
}

.fe-star:before {
  content: "\e9d5"
}

.fe-stop-circle:before {
  content: "\e9d6"
}

.fe-sun:before {
  content: "\e9d7"
}

.fe-sunrise:before {
  content: "\e9d8"
}

.fe-sunset:before {
  content: "\e9d9"
}

.fe-tablet:before {
  content: "\e9da"
}

.fe-tag:before {
  content: "\e9db"
}

.fe-target:before {
  content: "\e9dc"
}

.fe-terminal:before {
  content: "\e9dd"
}

.fe-thermometer:before {
  content: "\e9de"
}

.fe-thumbs-down:before {
  content: "\e9df"
}

.fe-thumbs-up:before {
  content: "\e9e0"
}

.fe-toggle-left:before {
  content: "\e9e1"
}

.fe-toggle-right:before {
  content: "\e9e2"
}

.fe-trash:before {
  content: "\e9e3"
}

.fe-trash-2:before {
  content: "\e9e4"
}

.fe-trending-down:before {
  content: "\e9e5"
}

.fe-trending-up:before {
  content: "\e9e6"
}

.fe-triangle:before {
  content: "\e9e7"
}

.fe-truck:before {
  content: "\e9e8"
}

.fe-tv:before {
  content: "\e9e9"
}

.fe-twitter:before {
  content: "\e9ea"
}

.fe-type:before {
  content: "\e9eb"
}

.fe-umbrella:before {
  content: "\e9ec"
}

.fe-underline:before {
  content: "\e9ed"
}

.fe-unlock:before {
  content: "\e9ee"
}

.fe-upload:before {
  content: "\e9ef"
}

.fe-upload-cloud:before {
  content: "\e9f0"
}

.fe-user:before {
  content: "\e9f1"
}

.fe-user-check:before {
  content: "\e9f2"
}

.fe-user-minus:before {
  content: "\e9f3"
}

.fe-user-plus:before {
  content: "\e9f4"
}

.fe-user-x:before {
  content: "\e9f5"
}

.fe-users:before {
  content: "\e9f6"
}

.fe-video:before {
  content: "\e9f7"
}

.fe-video-off:before {
  content: "\e9f8"
}

.fe-voicemail:before {
  content: "\e9f9"
}

.fe-volume:before {
  content: "\e9fa"
}

.fe-volume-1:before {
  content: "\e9fb"
}

.fe-volume-2:before {
  content: "\e9fc"
}

.fe-volume-x:before {
  content: "\e9fd"
}

.fe-watch:before {
  content: "\e9fe"
}

.fe-wifi:before {
  content: "\e9ff"
}

.fe-wifi-off:before {
  content: "\ea00"
}

.fe-wind:before {
  content: "\ea01"
}

.fe-x:before {
  content: "\ea02"
}

.fe-x-circle:before {
  content: "\ea03"
}

.fe-x-square:before {
  content: "\ea04"
}

.fe-youtube:before {
  content: "\ea05"
}

.fe-zap:before {
  content: "\ea06"
}

.fe-zap-off:before {
  content: "\ea07"
}

.fe-zoom-in:before {
  content: "\ea08"
}

.fe-zoom-out:before {
  content: "\ea09"
}

.fe-lg {
  font-size: 1.3125rem
}

@media (min-width: 992px) {
  .fe-lg {
    font-size: 1.125rem
  }
}

.flickity-prev-next-button {
  align-items: center;
  background-color: #142840;
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .05);
  color: #fff;
  display: flex;
  height: calc(1.6em + 1.625rem + 2px);
  justify-content: center;
  width: calc(1.6em + 1.625rem + 2px)
}

.flickity-prev-next-button:after, .flickity-prev-next-button:before {
  font-family: Feather
}

.flickity-prev-next-button:focus, .flickity-prev-next-button:hover {
  background-color: #142840;
  box-shadow: 0 .5rem 1.5rem rgba(22, 28, 45, .1)
}

.flickity-prev-next-button.previous {
  left: 0;
  transform: translate(calc(-50% + .625rem), -50%)
}

@media (min-width: 992px) {
  .flickity-prev-next-button.previous {
    transform: translate(-50%, -50%)
  }
}

.flickity-prev-next-button.previous:before {
  content: "\e910"
}

.flickity-prev-next-button.next {
  right: 0;
  transform: translate(calc(50% - .625rem), -50%)
}

@media (min-width: 992px) {
  .flickity-prev-next-button.next {
    transform: translate(50%, -50%)
  }
}

.flickity-prev-next-button.next:before {
  content: "\e912"
}

.flickity-button-icon {
  display: none
}

.flickity-button-white .flickity-prev-next-button {
  background-color: #fff;
  color: #142840
}

.flickity-button-bottom .flickity-prev-next-button {
  bottom: 1.5rem;
  top: auto;
  transform: none
}

.flickity-button-bottom .flickity-prev-next-button.previous {
  left: auto;
  right: 6.375rem
}

.flickity-button-bottom .flickity-prev-next-button.next {
  left: auto;
  right: 2.5rem
}

.flickity-button-inset .flickity-prev-next-button {
  transform: translateY(-50%)
}

.flickity-button-inset .flickity-prev-next-button.previous {
  left: 1.5rem
}

.flickity-button-inset .flickity-prev-next-button.next {
  right: 1.5rem
}

.flickity-viewport-visible .flickity-viewport {
  overflow: visible
}

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
  transition: all .5s ease
}

.flickity-slider > [class^=col] {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.hljs {
  background-color: transparent
}

.ql-container {
  font-family: HKGroteskPro, serif
}

.ql-toolbar {
  background-color: #fff;
  border: 1px solid #f1f4f8;
  border-radius: .375rem .375rem 0 0;
  color: #161c2d;
  padding: .8125rem 1.25rem;
  position: relative
}

.ql-toolbar + .ql-container {
  margin-top: -1px
}

.ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.ql-editor {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #f1f4f8;
  border-radius: .375rem;
  box-shadow: none;
  color: #161c2d;
  display: block;
  font-size: 1.0625rem;
  line-height: 1.6;
  min-height: 6.8rem;
  padding: .8125rem 1.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%
}

@media (prefers-reduced-motion: reduce) {
  .ql-editor {
    transition: none
  }
}

.ql-editor::-ms-expand {
  background-color: transparent;
  border: 0
}

.ql-editor:focus {
  border-color: #142840
}

.ql-hidden {
  position: absolute;
  transform: scale(0)
}

.ql-editor.ql-blank:before {
  color: #869ab8;
  font-style: normal;
  left: 1.25rem;
  top: .8125rem
}

.ql-editor:focus:before {
  display: none
}

.ql-formats {
  padding-left: .5rem;
  padding-right: .5rem
}

.ql-formats:first-child {
  padding-left: 0
}

.ql-formats:last-child {
  padding-right: 0
}

.ql-toolbar button {
  background: none;
  border: none;
  color: #161c2d;
  cursor: pointer;
  padding: 0 .25rem;
  transition: all .2s ease-in-out
}

.ql-toolbar button:hover {
  color: #142840
}

.ql-toolbar button:first-child {
  margin-left: -.25rem
}

.ql-toolbar .ql-active {
  color: #142840
}

.ql-toolbar button svg {
  height: 1.1875rem;
  width: 1.1875rem
}

.ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none
}

.ql-toolbar .ql-thin {
  stroke-width: 1
}

.ql-toolbar .ql-fill {
  fill: currentColor
}

.ql-toolbar input.ql-image {
  position: absolute;
  transform: scale(0)
}

.ql-tooltip {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: .375rem;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, .1);
  display: flex;
  flex-wrap: nowrap;
  margin-top: .6rem;
  padding: .8125rem 1.25rem;
  position: absolute;
  width: 18.5rem
}

.ql-tooltip:after, .ql-tooltip:before {
  bottom: 100%;
  content: "";
  left: 50%;
  position: absolute;
  transform: translateX(-50%)
}

.ql-tooltip:before {
  border-bottom: .6rem solid transparent;
  border-left: .6rem solid transparent;
  border-right: .6rem solid transparent
}

.ql-tooltip:after {
  border-bottom: .5rem solid #fff;
  border-left: .5rem solid transparent;
  border-right: .5rem solid transparent
}

.ql-container .ql-tooltip:hover {
  display: flex !important
}

.ql-tooltip .ql-preview {
  border-radius: .375rem;
  font-size: .9375rem;
  padding: .5625rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%
}

@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-preview {
    transition: none
  }
}

.ql-tooltip.ql-editing .ql-preview {
  display: none
}

.ql-tooltip input {
  background-color: transparent;
  border: none;
  color: #161c2d;
  display: none;
  font-size: .9375rem;
  line-height: 1.6;
  padding-bottom: calc(.5625rem + 1px);
  padding-top: calc(.5625rem + 1px);
  width: 100%
}

.ql-tooltip input:focus {
  outline: none
}

.ql-tooltip.ql-editing input {
  display: block
}

.ql-tooltip .ql-action, .ql-tooltip .ql-remove {
  margin-left: .25rem
}

.ql-tooltip .ql-action:before, .ql-tooltip .ql-remove:before {
  border: 1px solid transparent;
  border-radius: .375rem;
  cursor: pointer;
  display: inline-block;
  font-size: .9375rem;
  font-weight: 600;
  padding: .5625rem 1rem;
  text-align: center;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap
}

@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-action:before, .ql-tooltip .ql-remove:before {
    transition: none
  }
}

.ql-tooltip .ql-action:before:focus, .ql-tooltip .ql-action:before:hover, .ql-tooltip .ql-remove:before:focus, .ql-tooltip .ql-remove:before:hover {
  text-decoration: none
}

.ql-tooltip .ql-action:before.focus, .ql-tooltip .ql-action:before:focus, .ql-tooltip .ql-remove:before.focus, .ql-tooltip .ql-remove:before:focus {
  box-shadow: none;
  outline: 0
}

.ql-tooltip .ql-action:before, .ql-tooltip.ql-editing .ql-action:before {
  background-color: #142840;
  border-color: #142840;
  box-shadow: none;
  color: #fff
}

.ql-tooltip .ql-action:before:hover, .ql-tooltip.ql-editing .ql-action:before:hover {
  background-color: #112236;
  border-color: #102033;
  color: #fff
}

.btn-check:focus + .ql-tooltip .ql-action:before, .btn-check:focus + .ql-tooltip.ql-editing .ql-action:before, .ql-tooltip .ql-action:before:focus, .ql-tooltip.ql-editing .ql-action:before:focus {
  background-color: #112236;
  border-color: #102033;
  box-shadow: none, 0 0 0 0 rgba(55, 72, 93, .5);
  color: #fff
}

.btn-check:active + .ql-tooltip .ql-action:before, .btn-check:active + .ql-tooltip.ql-editing .ql-action:before, .btn-check:checked + .ql-tooltip .ql-action:before, .btn-check:checked + .ql-tooltip.ql-editing .ql-action:before, .ql-tooltip .ql-action:before.active, .ql-tooltip .ql-action:before:active, .ql-tooltip.ql-editing .ql-action:before.active, .ql-tooltip.ql-editing .ql-action:before:active, .show > .ql-tooltip .ql-action:before.dropdown-toggle, .show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle {
  background-color: #102033;
  border-color: #0f1e30;
  color: #fff
}

.btn-check:active + .ql-tooltip .ql-action:before:focus, .btn-check:active + .ql-tooltip.ql-editing .ql-action:before:focus, .btn-check:checked + .ql-tooltip .ql-action:before:focus, .btn-check:checked + .ql-tooltip.ql-editing .ql-action:before:focus, .ql-tooltip .ql-action:before.active:focus, .ql-tooltip .ql-action:before:active:focus, .ql-tooltip.ql-editing .ql-action:before.active:focus, .ql-tooltip.ql-editing .ql-action:before:active:focus, .show > .ql-tooltip .ql-action:before.dropdown-toggle:focus, .show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(55, 72, 93, .5)
}

.ql-tooltip .ql-action:before.disabled, .ql-tooltip .ql-action:before:disabled, .ql-tooltip.ql-editing .ql-action:before.disabled, .ql-tooltip.ql-editing .ql-action:before:disabled {
  background-color: #142840;
  border-color: #142840;
  color: #fff
}

.ql-tooltip .ql-action:before {
  content: "Edit"
}

.ql-tooltip.ql-editing .ql-action:before {
  content: "Save"
}

.ql-tooltip .ql-remove:before {
  background-color: #fff;
  border-color: #d9e2ef;
  box-shadow: none;
  color: #1b2a4e;
  content: "Remove"
}

.ql-tooltip .ql-remove:before:hover {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:focus + .ql-tooltip .ql-remove:before, .ql-tooltip .ql-remove:before:focus {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none, 0 0 0 0 rgba(221, 223, 228, .5);
  color: #1b2a4e
}

.btn-check:active + .ql-tooltip .ql-remove:before, .btn-check:checked + .ql-tooltip .ql-remove:before, .ql-tooltip .ql-remove:before.active, .ql-tooltip .ql-remove:before:active, .show > .ql-tooltip .ql-remove:before.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.btn-check:active + .ql-tooltip .ql-remove:before:focus, .btn-check:checked + .ql-tooltip .ql-remove:before:focus, .ql-tooltip .ql-remove:before.active:focus, .ql-tooltip .ql-remove:before:active:focus, .show > .ql-tooltip .ql-remove:before.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(221, 223, 228, .5)
}

.ql-tooltip .ql-remove:before.disabled, .ql-tooltip .ql-remove:before:disabled {
  background-color: #fff;
  border-color: #fff;
  color: #1b2a4e
}

.ql-tooltip.ql-editing .ql-remove:before {
  display: none
}

.ql-editor blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem
}

.ql-editor img {
  height: auto;
  max-width: 100%
}

/*# sourceMappingURL=theme.bundle.css.map*/