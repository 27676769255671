/*.row-section{float:left; width:100%; background: #42275a;  !* fallback for old browsers *!*/
/*}*/
/*.row-section h2{float:left; width:100%; color:#fff; margin-bottom:30px; font-size: 14px;}*/
/*.row-section h2 span{font-family: 'Libre Baskerville', serif; display:block; font-size:45px; text-transform:none; margin-bottom:20px; margin-top:30px;font-weight:700;}*/
/*.row-section h2 a{color:#d2abce;}*/
/*.row-section .row-block{background:#fff; padding:20px; margin-bottom:50px;}*/
/*.row-section .row-block ul{margin:0; padding:0;}*/
/*.row-section .row-block ul li{list-style:none; margin-bottom:20px;}*/
/*.row-section .row-block ul li:last-child{margin-bottom:0;}*/
/*.row-section .row-block ul li:hover{cursor:grabbing;}*/
/*.row-section .row-block .media{border:1px solid #d5dbdd; padding:5px 20px; border-radius: 5px; box-shadow:0px 2px 1px rgba(0,0,0,0.04); background:#fff;}*/
/*.row-section .media .media-left img{width:75px;}*/
/*.row-section .media .media-body p{padding: 0 15px; font-size:14px;}*/
/*.row-section .media .media-body h4 {color: #6b456a; font-size: 18px; font-weight: 600; margin-bottom: 0; padding-left: 14px; margin-top:12px;}*/
/*.btn-default{background:#6B456A; color:#fff; border-radius:30px; border:none; font-size:16px;}*/
/*.fa-spin {*/
/*    margin: 0 auto;}*/
/*body {*/
/*    background-color: lightgray;*/
/*}*/

body {
    padding-right: 0px !important;
}

.detail-btn-container > .btn {
    margin-left: 0.5rem;
}

.min-vh-80 {
    height: 80vh !important;
}

.contact-now-btn {
    position: absolute;
    bottom: 0;
}

@media (min-width: 1px) and (max-width: 767px) {
    .contact-now-btn {
        position: unset;
        bottom: 0;
    }
}

.basic-typeahead-single .dropdown-menu {
    width: max-content !important;
}

.w-fit {
    width: fit-content !important;
}

.table td.fit,
.table th.fit {
    white-space: nowrap;
    width: 1%;
}

#icon-add > svg {
    position:relative;
    top: calc(50% - 10px); /* 50% - 3/4 of icon height */
}

#commercial-area-search.rbt-menu {
    width: unset !important;
}